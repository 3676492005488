import { useEffect, useState } from 'react';

import { Grid, Typography } from '@mui/material';
import { HorizontalBlock, VerticalBlock } from '@zipptrip/zipptrip-canvas';
import { UserType } from '@zipptrip/zipptrip-commons';
import { useNavigate } from 'react-router-dom';

import DashboardCard from '../components/dashboard/DashboardCard';
import LoadingSpinner from '../components/loader/LoadingSpinner';
import NavBarToolbar from '../components/navbar/NavBarToolbar';
import { auth } from '../config/firebase';
import { PlannerSummary } from '../types/planner';
import { getOverallSummary } from '../utils/planner';

const HomePage = () => {
  const [summaryData, setSummaryData] = useState<PlannerSummary | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userType, setUserType] = useState<UserType | undefined>();

  const navigate = useNavigate();
  useEffect(() => {
    setIsLoading(true);
    getOverallSummary()
      .then((data) => {
        setIsLoading(false);
        setSummaryData(data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
      });

    return () => undefined;
  }, [navigate]);

  useEffect(() => {
    // Get user type if its creator or agency
    auth.currentUser?.getIdTokenResult().then((token) => {
      setUserType(token.claims.userType || UserType.Creator);
    });
  }, [auth.currentUser]);

  if (isLoading || !userType) return <LoadingSpinner />;

  return (
    <>
      <NavBarToolbar>
        <HorizontalBlock height="100%">
          <Typography variant="T20M">Dashboard</Typography>
        </HorizontalBlock>
      </NavBarToolbar>

      <VerticalBlock height="100%" margin={2}>
        {userType !== UserType.Agency && (
          <Grid container spacing={4} marginTop={2}>
            <Grid item xs={12} sm={6} md={4}>
              <DashboardCard label="Total Plans" number={summaryData?.total || 0} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <DashboardCard label="Total Viewed" number={summaryData?.viewedTotal || 0} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <DashboardCard label="Total Sold" number={summaryData?.soldTotal || 0} />
            </Grid>
          </Grid>
        )}
        <Grid container spacing={4} marginTop={2}>
          <Grid item xs={12} sm={6} md={4}>
            <DashboardCard
              label="Total Earned Amount"
              isAmount
              number={summaryData?.totalEarned || 0}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DashboardCard
              label="Total Withdrawed Amount"
              isAmount
              number={summaryData?.totalWithdraw || 0}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DashboardCard
              label="Remaining Balance Amount"
              isAmount
              number={summaryData?.balance || 0}
            />
          </Grid>
        </Grid>
      </VerticalBlock>
    </>
  );
};

export default HomePage;
