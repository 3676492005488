import { SyntheticEvent, useEffect, useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { FormAutocomplete, FormTextField } from '@zipptrip/zipptrip-canvas';
import {
  COUNTRIES,
  City,
  ContentCategory,
  IPlanner,
  Language,
  Sex,
  TravelInterest,
} from '@zipptrip/zipptrip-commons';
import { useFormikContext } from 'formik';

import { getCountryInfo } from '../../utils/planner';

type CountryOption = { name: string; code: string };

export default function ProfileBasicInfo() {
  const [cities, setCities] = useState<City[]>([]);
  const { initialValues, setFieldValue, values } = useFormikContext<IPlanner>();

  useEffect(() => {
    if (!initialValues.country) return;
    getCountryInfo(initialValues.country).then((res) => {
      setCities(res?.cities || []);
    });
  }, [initialValues.country]);

  return (
    <Box>
      <Typography variant="T16M">Basic information</Typography>

      <Grid container>
        <Grid item xs={12}>
          <FormTextField disabled field="email" label="Email" margin="normal" />
        </Grid>
      </Grid>

      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={6}>
          <FormTextField field="name" label="Name" margin="normal" required />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormAutocomplete
            field="sex"
            options={Object.values(Sex)}
            textfieldProps={{ label: 'Sex', margin: 'normal' }}
          />
        </Grid>
      </Grid>

      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={6}>
          <FormAutocomplete
            defaultValue={[Language.English]}
            field="languages"
            multiple
            options={Object.values(Language)}
            textfieldProps={{ label: 'Languages *', margin: 'normal' }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            field="dob"
            InputLabelProps={{ shrink: true }}
            label="Date of Birth"
            margin="normal"
            type="date"
          />
        </Grid>
      </Grid>

      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={6}>
          <FormAutocomplete
            field="country"
            getOptionLabel={(option: CountryOption) => option?.name || ''}
            isOptionEqualToValue={(option: CountryOption, value: CountryOption) =>
              option?.code === value?.code
            }
            onChange={(event: SyntheticEvent, value: CountryOption) => {
              setFieldValue('country', value?.code || '');
              setFieldValue('city', '');
              getCountryInfo(value?.code || '').then((res) => setCities(res?.cities || []));
            }}
            options={COUNTRIES}
            textfieldProps={{
              label: 'Country *',
              margin: 'normal',
              placeholder: values.country ? '' : 'Select your country',
            }}
            value={
              COUNTRIES.find((country) => country.code === values.country) || { name: '', code: '' }
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormAutocomplete
            disabled={!cities.length}
            field="city"
            filterOptions={(options: City[], state: any) =>
              options.filter((option) =>
                option.name.toLowerCase().includes(state.inputValue.toLowerCase()),
              )
            }
            filterSelectedOptions
            getOptionLabel={(option: City) =>
              `${option.name} ${option.state ? ` (${option.state})` : ''}`
            }
            isOptionEqualToValue={(option: City, value: City) => option?.name === value?.name}
            onChange={(event: SyntheticEvent, value: City) =>
              setFieldValue('city', value?.name || '')
            }
            options={cities}
            sx={{ marginTop: 2, marginBottom: 2 }}
            textfieldProps={{
              label: 'City',
              placeholder: values.city ? '' : 'Select the cities of the trip plan',
            }}
            value={cities.find((c) => c.name === values.city) || { name: '', state: '' }}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <FormTextField
            defaultValue=" "
            field="tagLine"
            label="Tag Line"
            margin="normal"
            required
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <FormTextField
            defaultValue=" "
            field="bio"
            label="Short Bio"
            margin="normal"
            multiline
            required
            rows={4}
          />
        </Grid>
      </Grid>

      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={6}>
          <FormAutocomplete
            field="contentCategories"
            multiple
            options={Object.values(ContentCategory)}
            textfieldProps={{ label: 'Content Categories *', margin: 'normal' }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormAutocomplete
            field="travelInterests"
            multiple
            options={Object.values(TravelInterest)}
            textfieldProps={{ label: 'Travel Interests *', margin: 'normal' }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
