import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';

import { useNotifications } from '@zipptrip/zipptrip-canvas';
import { IPlan } from '@zipptrip/zipptrip-commons';
import { Formik } from 'formik';
import { StringParam, useQueryParam } from 'use-query-params';

import { auth } from '../../config/firebase';
import { createOrUpdatePlan } from '../../utils/plan';
import { fillPhotoList, getPlanValidationSchema } from '../planForm/utils';

interface Props {
  children: ReactNode;
  plan?: IPlan;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  setPlan: Dispatch<SetStateAction<IPlan | undefined>>;
  setShouldGoTo: Dispatch<SetStateAction<number>>;
  shouldGoTo: number;
}

export default function PlanEditFormik({
  children,
  plan,
  setCurrentPage,
  setPlan,
  setShouldGoTo,
  shouldGoTo,
}: Props) {
  const plannerName: string = auth.currentUser?.displayName || '';
  const plannerId: string = auth.currentUser?.uid || '';
  const [, setSelectedPlanId] = useQueryParam('create-edit-plan', StringParam);
  const [prefilledCountry] = useQueryParam('country', StringParam);
  const [prefilledCity] = useQueryParam('city', StringParam);

  const { showError, showSuccess } = useNotifications();
  const [initialValues, setInitialValues] = useState<IPlan | null>(null);

  useEffect(() => {
    setInitialValues({
      _id: plan?._id,
      plannerId,
      plannerName,
      title: plan?.title || '',
      duration: plan?.duration as number,
      description: plan?.description || '',
      countries: plan?.countries || (!!prefilledCountry ? [prefilledCountry] : []),
      cities: plan?.cities || (!!prefilledCity ? [prefilledCity] : []),
      photos:
        (plan?.photos?.length || 0) < 4 ? fillPhotoList(plan?.photos || []) : plan?.photos || [],
      travellersTypes: plan?.travellersTypes || [],
      foodPreferences: plan?.foodPreferences || [],
      includesActivities: plan?.includesActivities || false,
      tags: plan?.tags || [],
      bestTimeToVisit: plan?.bestTimeToVisit || [],
      tripTypes: plan?.tripTypes || [],
      price: plan?.price || 0,
      inappropriateForChildren: plan?.inappropriateForChildren || false,
      thingsToBuy: plan?.thingsToBuy || [],
      thingsToPrepare: plan?.thingsToPrepare || [],
    } as IPlan);
  }, [JSON.stringify(plan), plannerId, plannerName]);

  if (!initialValues) return <></>;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        values.thingsToBuy = values.thingsToBuy?.filter((v) => !!v?.name) || [];
        values.thingsToPrepare = values.thingsToPrepare?.filter((v) => !!v?.name) || [];

        createOrUpdatePlan(values)
          .then((res) => {
            showSuccess('Plan saved successfully');
            if (res?._id) {
              setSelectedPlanId(res._id as string);
              setPlan(res);
              setSubmitting(false);
            }
            if (shouldGoTo !== -1) {
              setTimeout(() => {
                setCurrentPage(shouldGoTo);
                setShouldGoTo(-1);
              }, 400);
            }
          })
          .catch((error) => {
            console.error(error);
            setShouldGoTo(-1);
            setSubmitting(false);
            showError('Error saving plan');
          });
      }}
      validationSchema={getPlanValidationSchema()}
    >
      <>{children}</>
    </Formik>
  );
}
