import { Box, Button, Divider, InputAdornment, Typography } from '@mui/material';
import {
  FormTextField,
  LoaderCircle,
  VerticalBlock,
  useInputShrink,
  useNotifications,
} from '@zipptrip/zipptrip-canvas';
import { IPlannerBase, UserType, IPlannerSocialMedia } from '@zipptrip/zipptrip-commons';
import { Form, Formik } from 'formik';
import { StepWizardChildProps } from 'react-step-wizard';

import AlreadyRegistered from './AlreadyRegistered';
import Stepper from './Stepper';
import { getPlannerBaseStep2ValidationSchema } from './utils';
import { getReferrer } from '../../utils/planner';
import { registerPlanner } from '../../utils/signup';
import Logo from '../logo/Logo';

type StepProps = {
  previousData?: any;
  isAgency?: boolean;
} & Partial<StepWizardChildProps>;

const SignupSocialMedia = ({ previousData, isAgency, goToStep }: StepProps) => {
  const { showError, showSuccess } = useNotifications();

  return (
    <VerticalBlock sx={{ gap: '24px', '& > form': { width: { xs: '100%', sm: '77%' } } }}>
      <Logo size="small" />

      <Typography variant="T28B" sx={{ letterSpacing: '1px', textAlign: 'center' }}>
        Welcome to the community!
      </Typography>

      {isAgency ? (
        <Typography variant="T16R" sx={{ textAlign: 'center' }}>
          Almost done 🎉
          <br />
          Please put the link of your website.
        </Typography>
      ) : (
        <Typography variant="T16R" sx={{ textAlign: 'center' }}>
          Almost done 🎉
          <br />
          Please put the links of your social networks with the most followers (at least 1 link).
        </Typography>
      )}

      <Stepper total={2} currentId={2} />

      <Formik
        initialValues={{}}
        validationSchema={getPlannerBaseStep2ValidationSchema(isAgency)}
        validate={(values) => {
          if (!isAgency) {
            const errors = {} as any;

            const {
              youtubeLink,
              facebookLink,
              twitterLink,
              instagramLink,
              pinterestLink,
              tiktokLink,
              mediaKitLink,
            } = values as IPlannerSocialMedia;

            if (
              !youtubeLink &&
              !facebookLink &&
              !twitterLink &&
              !instagramLink &&
              !tiktokLink &&
              !pinterestLink &&
              !mediaKitLink
            ) {
              errors['_error'] = 'You must provide at least one social media link';
            }

            return errors;
          }
        }}
        onSubmit={async (values, { setSubmitting }) => {
          const referrer = await getReferrer();
          const payload: IPlannerBase = {
            ...previousData,
            socialMedia: values,
            userType: isAgency ? UserType.Agency : UserType.Creator,
            ...(!!referrer ? { referrer } : {}),
          };
          try {
            await registerPlanner(payload);
          } catch (e: any) {
            showError(e?.response?.data?.message || 'Error');
            return;
          }
          //TODO: check response
          goToStep?.(3);
          showSuccess('Registration success.');
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, handleSubmit, values, errors }) => {
          const { _error } = errors as any;
          const { textFieldProps } = useInputShrink(values);
          return (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <VerticalBlock sx={{ gap: '16px' }}>
                {(isAgency
                  ? [
                      {
                        icon: <></>,
                        label: 'Website',
                        field: 'websiteLink',
                      },
                    ]
                  : [
                      {
                        icon: <img src="/social_media_icon/youtube.png" />,
                        label: 'Youtube channel link',
                        field: 'youtubeLink',
                      },
                      {
                        icon: <img src="/social_media_icon/facebook.png" />,
                        label: 'Facebook page link',
                        field: 'facebookLink',
                      },
                      {
                        icon: <img src="/social_media_icon/instagram.png" />,
                        label: 'Instagram profile link',
                        field: 'instagramLink',
                      },
                      {
                        icon: <img src="/social_media_icon/tiktok.svg" />,
                        label: 'Tiktok profile link',
                        field: 'tiktokLink',
                      },
                      {
                        icon: <img src="/social_media_icon/X.png" />,
                        label: 'Twitter profile link',
                        field: 'twitterLink',
                      },
                      {
                        icon: <img src="/social_media_icon/pinterest.png" />,
                        label: 'Pinterest profile link',
                        field: 'pinterestLink',
                      },
                      {
                        icon: <img src="/social_media_icon/g-drive.png" />,
                        label: 'Drive URL if media set is available',
                        field: 'mediaKitLink',
                      },
                    ]
                ).map(({ icon, label, field }) => (
                  <Box key={field} sx={{ width: '100%' }}>
                    <FormTextField
                      shrinkProps={textFieldProps}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{icon}</InputAdornment>,
                      }}
                      autoFocus
                      label={label}
                      placeholder={label}
                      field={field}
                    />
                  </Box>
                ))}

                <Typography variant="T16R" sx={{ margin: '5px 0 0', color: '#7D8E8F' }}>
                  We will review your information and respond within 24 hours. Check your email so
                  as not to miss our notifications.
                </Typography>

                {_error && (
                  <Typography variant="T16R" sx={{ textAlign: 'center', color: 'red' }}>
                    {_error}
                  </Typography>
                )}
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  startIcon={isSubmitting && <LoaderCircle />}
                  sx={{ marginTop: '24px' }}
                  type="submit"
                  variant="contained"
                >
                  Send request
                </Button>
              </VerticalBlock>
            </Form>
          );
        }}
      </Formik>
      <Divider flexItem />
      <AlreadyRegistered />
    </VerticalBlock>
  );
};

export default SignupSocialMedia;
