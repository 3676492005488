import { useState } from 'react';

import {
  Box,
  Button,
  ButtonProps,
  Portal,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { HorizontalBlock, LoaderCircle, VerticalBlock, textStyle } from '@zipptrip/zipptrip-canvas';
import { IPlan, IThingsToBuy, PlanStatusType } from '@zipptrip/zipptrip-commons';
import { useFormikContext } from 'formik';
import { Add, InfoCircle, TickSquare } from 'iconsax-react';

import PlanPublishButton from '../plan/PlanPublishButton';

interface Props {
  goToPage: (page: number) => void;
  plan: IPlan;
  refreshPlans: () => void;
}

export default function OthersForm({ goToPage, plan, refreshPlans }: Props) {
  const largerThanSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const portalId = largerThanSm ? 'plan-modal-toolbar' : 'plan-modal-bottom';
  const canBePublished = [PlanStatusType.Draft, PlanStatusType.Hidden].includes(
    plan.status as PlanStatusType,
  );

  const { errors, isSubmitting, isValid, submitForm, values } = useFormikContext<IPlan>();
  const [length1, setLength1] = useState(Math.max(plan.thingsToPrepare?.length || 0, 3));
  const [length2, setLength2] = useState(Math.max(plan.thingsToBuy?.length || 0, 3));

  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'column',
        gap: '32px',
        margin: 'auto',
        padding: '32px',
        maxWidth: '784px',
      }}
    >
      <Portal container={document.getElementById(portalId)}>
        <Tooltip title={Object.values(errors)?.[0]}>
          <span>
            <Button
              disabled={isSubmitting || !isValid}
              onClick={submitForm}
              translate="no"
              size="small"
              startIcon={isSubmitting && <LoaderCircle />}
              variant="text"
            >
              <span translate="yes">Save changes</span>
            </Button>
          </span>
        </Tooltip>

        <Button onClick={() => goToPage(1)} size="small" variant="outlined">
          Back to Itinerary
        </Button>

        {canBePublished && (
          <PlanPublishButton plan={plan} refreshPlans={refreshPlans} variant="contained" />
        )}
      </Portal>

      <HorizontalBlock
        sx={{
          bgcolor: 'primary.selectedBackground',
          borderRadius: '24px',
          gap: '12px',
          padding: '16px',
          '& > svg': { flexShrink: 0 },
        }}
      >
        <InfoCircle />
        <Typography variant="T16R">
          This includes important things to do before and while traveling. This will help tourists
          appreciate the plan and have a full experience.
        </Typography>
      </HorizontalBlock>

      <Box sx={{ display: 'flex', flexFlow: 'column', gap: '24px' }}>
        <Typography variant="T20B">Things to prepare</Typography>
        {Array.from({ length: length1 }).map((_, idx) => (
          <CheckListField key={idx} field="thingsToPrepare" index={idx} />
        ))}
        <AddCheckListButton
          disabled={
            values.thingsToPrepare?.some((v) => !v?.name) ||
            (values.thingsToPrepare?.length || 0) < 3
          }
          onClick={() => setLength1((l) => l + 1)}
        />
      </Box>

      <Box sx={{ display: 'flex', flexFlow: 'column', gap: '24px' }}>
        <Typography variant="T20B">Popular things to buy</Typography>
        {Array.from({ length: length2 }).map((_, idx) => (
          <CheckListField key={idx} field="thingsToBuy" index={idx} />
        ))}
        <AddCheckListButton
          disabled={
            values.thingsToBuy?.some((v) => !v?.name) || (values.thingsToBuy?.length || 0) < 3
          }
          onClick={() => setLength2((l) => l + 1)}
        />
      </Box>
    </Box>
  );
}

const CheckListField = ({
  field,
  index,
}: {
  field: 'thingsToBuy' | 'thingsToPrepare';
  index: number;
}) => {
  const { setFieldValue, values } = useFormikContext<IPlan>();
  const name = values[field]?.[index]?.name || '';
  const description = values[field]?.[index]?.description || '';

  const handleOnChange = (item: Partial<IThingsToBuy>) => {
    const itemList: IThingsToBuy[] = JSON.parse(JSON.stringify(values[field] || []));
    const emptyItem = { name: '', description: '' };
    itemList[index] = { ...emptyItem, ...(itemList[index] || {}), ...item };
    setFieldValue(field, itemList);
  };

  return (
    <Box sx={{ display: 'flex', gap: '8px' }}>
      <Box
        sx={{
          '& > svg': {
            color: !!name ? 'var(--neutral-10)' : 'var(--neutral-3)',
            '& > path:last-of-type': { display: 'none' },
          },
        }}
      >
        <TickSquare />
      </Box>

      <VerticalBlock sx={{ alignItems: 'flex-start', gap: '4px', width: '100%' }}>
        <TextField
          fullWidth
          InputProps={{
            sx: {
              border: 'none',
              minHeight: '24px',
              padding: '0 !important',
              '& input': textStyle(14, 'S'),
              '& input::placeholder': { color: 'var(--neutral-5)', ...textStyle(14, 'S') },
            },
          }}
          onChange={(e) => handleOnChange({ name: e.target.value })}
          placeholder="Enter a title"
          value={name}
        />

        <TextField
          fullWidth
          InputProps={{
            sx: {
              border: 'none',
              minHeight: '24px',
              padding: '0 !important',
              '& input': textStyle(14, 'R'),
              '& input::placeholder': { color: 'var(--neutral-5)', ...textStyle(14, 'R') },
            },
          }}
          onChange={(e) => handleOnChange({ description: e.target.value })}
          placeholder="Enter a description"
          value={description}
        />
      </VerticalBlock>
    </Box>
  );
};

const AddCheckListButton = (props: ButtonProps) => (
  <Button
    disableRipple
    startIcon={<Add />}
    sx={{ padding: '2px', width: 'fit-content' }}
    variant="text"
    {...props}
  >
    Add checklist
  </Button>
);
