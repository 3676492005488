import React from 'react';

import { Box, Typography } from '@mui/material';
import { Center } from '@zipptrip/zipptrip-canvas';

const TermsAndConditionsPage: React.FC = () => {
  return (
    <Box margin={10}>
      <Center height={2}>
        <Typography variant="h4" sx={{ mb: 1 }}>
          PRIVACY POLICY
        </Typography>
      </Center>
      <br />
      <br />
      <Typography>
        Effective Date: 07/01/2023 This Privacy Policy ("Policy") explains how Zipp Trip Limited
        ("Zipp Trip," "we," "us," or "our") collects, uses, discloses, and protects your personal
        information when you access or use the Zipp Trip website, applications, and services
        (collectively, the "Services"). By using the Services, you consent to the collection, use,
        and processing of your personal information as described in this Policy.
      </Typography>

      <br />
      <Typography variant="h5" sx={{ mb: 1 }}>
        Information We Collect
      </Typography>
      <Typography variant="body1" sx={{ mb: 1 }}>
        When you create a Zipp Trip Account or use our Services, we collect certain information from
        you, including: Contact Information: such as your name and email address. Account
        Information: such as your username, password, and profile information. Payment Information:
        such as your credit card or other payment details for processing purchases. Communication
        Information: such as any correspondence you send to us, including emails, messages, or
        feedback. When you access or use our Services, we automatically collect certain information
        about your device, browsing actions, and patterns, including: Log Information: such as your
        IP address, browser type, operating system, and referring/exit pages. Usage Information:
        such as the pages you view, links you click, and features you access on the Services. Device
        Information: such as your device type, unique device identifiers, and mobile network
        information. Location Information: such as your general location based on your IP address or
        precise location if you provide your consent. We may receive information about you from
        third parties, such as hosts or service providers, when necessary to provide the Services or
        as permitted by applicable law.
      </Typography>

      <br />
      <Typography variant="h5" sx={{ mb: 1 }}>
        Use of Information
      </Typography>
      <Typography variant="body1" sx={{ mb: 1 }}>
        We use the information we collect for the following purposes:
        <br />
        <br /> <u>Providing and improving our Services:</u>
        <br /> To deliver the Services you request, personalize your experience, and enhance the
        functionality and usability of our platform.
        <br /> <u>Communication:</u>
        <br /> To communicate with you about your account, transactions, customer support inquiries,
        and updates related to the Services.
        <br /> <u>Transaction Processing:</u>
        <br /> To process payments, verify transactions, and provide receipts for purchases made
        through the Services.
        <br /> <u>Analytics and Research:</u>
        <br /> To analyze usage patterns, gather demographic information, and conduct research and
        analysis to improve our Services.
        <br /> <u>Compliance and Protection:</u>
        <br /> To comply with legal obligations, enforce our Terms of Service, protect our rights
        and interests, and prevent fraud, abuse, or unauthorized access.
        <br /> <u>Marketing and Promotions:</u>
        <br /> With your consent, to provide you with information, offers, and promotional materials
        about our Services or those of our partners.
        <br />
        <br />
      </Typography>

      <Typography variant="h5" sx={{ mb: 1 }}>
        Data Sharing and Disclosure
      </Typography>
      <Typography variant="body1" sx={{ mb: 1 }}>
        We may share your information in the following circumstances:
        <br />
        <br />
        <u>With User:</u>
        <br /> When users purchase an itinerary, we may share specific information with them for the
        purpose of facilitating the travel services.
        <br />
        <u>Service Providers:</u>
        <br /> We may engage third-party service providers to perform functions and process data on
        our behalf. These service providers have access to your information only as necessary to
        perform their services and are obligated to maintain the confidentiality and security of
        your information.
        <br />
        <u>Legal Requirements:</u>
        <br /> We may disclose your information if required to do so by law, or in response to a
        valid request by a governmental authority or law enforcement agency.
        <br />
        <u>Business Transfers:</u>
        <br /> In the event of a merger, acquisition, or sale of all or a portion of our assets,
        your information may be transferred as part of the transaction.
        <br />
        <br />
      </Typography>
      <Typography variant="h5" sx={{ mb: 1 }}>
        Data Retention
      </Typography>
      <Typography variant="body1" sx={{ mb: 1 }}>
        We retain your personal information for as long as necessary to fulfill the purposes
        outlined in this Policy, unless a longer retention period is required or permitted by law.
        We will delete or anonymize your information when it is no longer needed or upon your
        request, subject to our legal obligations.
      </Typography>
      <br />
      <Typography variant="h5" sx={{ mb: 1 }}>
        Data Security
      </Typography>
      <Typography variant="body1" sx={{ mb: 1 }}>
        We implement reasonable security measures to protect your personal information from
        unauthorized access, disclosure, alteration, or destruction. However, please note that no
        method of transmission over the Internet or electronic storage is 100% secure. While we
        strive to use commercially acceptable means to protect your information, we cannot guarantee
        its absolute security.
      </Typography>

      <br />
      <Typography variant="h5" sx={{ mb: 1 }}>
        Your Rights and Choices
      </Typography>
      <Typography variant="body1" sx={{ mb: 1 }}>
        You have certain rights and choices regarding the personal information we collect and
        process about you, subject to applicable laws. These rights include the right to access,
        correct, delete, or restrict your personal information, as well as the right to object to
        our processing of your personal information.
      </Typography>

      <br />
      <Typography variant="h5" sx={{ mb: 1 }}>
        Changes to this Policy
      </Typography>
      <Typography variant="body1" sx={{ mb: 1 }}>
        We may update this Policy from time to time. If we make material changes, we will provide
        notice through the Services or by other means. Your continued use of the Services after the
        effective date of the revised Policy constitutes your acceptance of the changes.
      </Typography>

      <br />

      <Typography variant="body1" sx={{ mb: 1 }}>
        By using the Services, you acknowledge that you have read, understood, and agree to the
        terms of this Privacy Policy.
      </Typography>

      <br />
      <br />
      <br />
    </Box>
  );
};
export default TermsAndConditionsPage;
