import { Check } from '@mui/icons-material';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Step,
  StepButton,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
  useTheme,
} from '@mui/material';
import { TagUser } from 'iconsax-react';

import { navItems } from './navItems';
import { HUBSPOT_TICKET } from '../../constants/commons';

type Props = { currentPage: number; isEditing: boolean; goToPage: (page: number) => void };

export default function PlanSteps({ currentPage, isEditing, goToPage }: Props) {
  const { breakpoints } = useTheme();

  return (
    <Box sx={{ marginTop: '32px', [breakpoints.down('sm')]: { marginTop: '-38px' } }}>
      <Typography sx={{ marginBottom: '58px' }} variant="T28B">
        {isEditing ? 'Edit plan' : 'Create plan'}
      </Typography>

      <Stepper
        activeStep={currentPage}
        connector={
          <StepConnector
            sx={{
              '& > span': {
                borderColor: 'text.secondary',
                borderLeftStyle: 'dashed',
                margin: '8px 0 8px 16px',
                minHeight: '36px',
              },
            }}
          />
        }
        nonLinear
        orientation="vertical"
      >
        {navItems.map(({ name, Icon }, idx) => (
          <Step key={name}>
            <StepButton
              onClick={() => goToPage(idx)}
              sx={{
                bgcolor: idx === currentPage ? 'primary.selectedBackground' : undefined,
                borderRadius: '40px',
                margin: '0 -8px',
                padding: '12px 16px',
              }}
            >
              <StepLabel
                StepIconComponent={({ active, completed }) => (
                  <Box
                    sx={{
                      bgcolor: active ? 'text.primary' : null,
                      border: '1px solid var(--neutral-3)',
                      borderRadius: '50%',
                      display: 'flex',
                      padding: '9px',
                    }}
                  >
                    {completed ? (
                      <Check color="primary" sx={{ fontSize: 20 }} />
                    ) : (
                      <Icon color={active ? 'var(--neutral-1)' : 'var(--neutral-10)'} size={20} />
                    )}
                  </Box>
                )}
                sx={{
                  gap: '8px',
                  padding: 0,
                  '& .MuiStepLabel-label': {
                    '& > p': { color: 'text.secondary' },
                    '&.Mui-active > p': { color: 'text.primary' },
                  },
                }}
              >
                <Typography variant="T16M">{name}</Typography>
              </StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>

      <List sx={{ bottom: 0, padding: 0, position: 'absolute', width: 'calc(100% - 64px)' }}>
        <Divider />
        <ListItem>
          <ListItemButton
            sx={{ margin: '0 -8px' }}
            onClick={() => window.open(HUBSPOT_TICKET, '_blank')}
          >
            <ListItemIcon sx={{ color: 'text.secondary' }}>
              <TagUser size={28} />
            </ListItemIcon>
            <Typography color="textSecondary" variant="T14R">
              Contact Support
            </Typography>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
}
