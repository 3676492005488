import AuthBackground from '../components/auth/AuthBackground';
import ResetPasswordContainer from '../components/auth/ResetPassword';

const ResetPasswordPage = () => {
  return (
    <AuthBackground>
      <ResetPasswordContainer />
    </AuthBackground>
  );
};

export default ResetPasswordPage;
