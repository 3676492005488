import { ReactNode, useEffect, useState } from 'react';

import Portal from '@mui/material/Portal';

export default function NavBarToolbar({ children }: { children: ReactNode }) {
  const [container, setContainer] = useState<HTMLElement | null>(null);

  useEffect(() => setContainer(document.getElementById('navbar-toolbar')), []);

  if (!container) return <></>;
  return <Portal container={container}>{children}</Portal>;
}
