import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import { HorizontalBlock, useNotifications } from '@zipptrip/zipptrip-canvas';
import { DocumentCopy, ExportSquare, MoneyRecive, Profile2User } from 'iconsax-react';

import { auth, sendGAEvent } from '../../config/firebase';
import { ZIPPTRIP_URL_ROOT } from '../../constants/commons';
import { ReferralSummary } from '../../types/planner';
import { numberWithCommas } from '../../utils/commons';

const info = [
  `Get your unique referral link.`,
  `Share it with other creators.`,
  `When they sign up and start selling plans, you'll earn 3% commission on each sale.`,
];

interface Props {
  summaryData: ReferralSummary[];
}

export default function ReferralLink({ summaryData }: Props) {
  const { showSuccess } = useNotifications();
  const copy = async (text: string) => {
    navigator?.clipboard?.writeText(text);
    showSuccess('Link copied!');
  };
  const link = `${window.location.href}?ref=${auth.currentUser?.uid}`;

  return (
    <>
      <Typography variant="T28S">Referral Program</Typography>

      <HorizontalBlock gap="32px" width="fit-content">
        <Box>
          <HorizontalBlock sx={{ gap: '8px', marginBottom: '8px' }}>
            <Profile2User size={20} />
            <Typography color="textSecondary" variant="T14R">
              Referred Users
            </Typography>
          </HorizontalBlock>
          <Typography variant="T28M">{summaryData.length}</Typography>
        </Box>

        <Divider flexItem orientation="vertical" />

        <Box>
          <HorizontalBlock sx={{ gap: '8px', marginBottom: '8px' }}>
            <MoneyRecive size={20} />
            <Typography color="textSecondary" variant="T14R">
              Earnings
            </Typography>
          </HorizontalBlock>
          <Typography variant="T28M">
            ${numberWithCommas(summaryData.reduce((acc, cur) => acc + cur.totalCommission, 0))}
          </Typography>
        </Box>
      </HorizontalBlock>

      <Box
        sx={{
          background: '#F6F6F6',
          borderRadius: '24px',
          display: 'flex',
          flexFlow: 'column',
          gap: '24px',
          padding: '24px',
        }}
      >
        <Box>
          <Typography sx={{ marginBottom: '12px' }} variant="T16S">
            How to join the ZippTrip Referral Program
          </Typography>

          <Box sx={{ display: 'flex', flexFlow: 'column', gap: '8px' }}>
            {info.map((item, idx) => (
              <Typography key={idx} variant="T14R">
                {idx + 1}. {item}
              </Typography>
            ))}
          </Box>
          <Typography sx={{ marginTop: '16px' }} variant="T14R">
            That's it! The more they sell, the more you earn.
          </Typography>
        </Box>

        <TextField
          fullWidth
          InputProps={{
            endAdornment: (
              <Button
                onClick={() => {
                  copy(link);
                  sendGAEvent('event:referral-link-copied');
                }}
                sx={{ flexShrink: 0, marginLeft: '8px' }}
                startIcon={<DocumentCopy />}
                variant="contained"
              >
                <Box
                  sx={{
                    '& > span:first-of-type': { display: { md: 'none' } },
                    '& > span:last-of-type': { display: { xs: 'none', md: 'block' } },
                  }}
                >
                  <span>Copy</span>
                  <span>Copy Referral Link</span>
                </Box>
              </Button>
            ),
            readOnly: true,
            sx: { background: '#FFFFFF' },
          }}
          value={link}
        />

        <Button
          onClick={() => window.open(`${ZIPPTRIP_URL_ROOT}/referrals`, '_blank')}
          startIcon={<ExportSquare />}
          sx={{ width: 'fit-content' }}
          variant="outlined"
        >
          Learn more
        </Button>
      </Box>
    </>
  );
}
