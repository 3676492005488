import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Theme,
  useMediaQuery,
} from '@mui/material';
import Portal from '@mui/material/Portal';
import { useNotifications } from '@zipptrip/zipptrip-canvas';
import { IEvent, IPlan, ItineraryEventType } from '@zipptrip/zipptrip-commons';
import { Add, SearchNormal1 } from 'iconsax-react';

import EventForm from './EventForm';
import EventLanding from './EventLanding';
import EventNotFound from './EventNotFound';
import ItinerarySummary from './ItinerarySummary';
import { a11yProps } from '../../utils/commons';
import { getEventsOfAPlan, getEventsOfAPlanDay } from '../../utils/plan';
import EventCard from '../eventCard/EventCard';

interface Props {
  goToPage: (page: number) => void;
  plan: IPlan;
  setPlan: Dispatch<SetStateAction<IPlan | undefined>>;
}

const ItineraryForm = ({ goToPage, plan, setPlan }: Props) => {
  const [day, setDay] = useState<number>(1);
  const [selectedEventIndex, setSelectedEventIndex] = useState<number>(-1);
  const [searchedType, setSearchedType] = useState('');
  const [searchedValue, setSearchedValue] = useState('');
  const isSearching = !!searchedType || !!searchedValue;

  const largerThanSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const portalId = largerThanSm ? 'plan-modal-toolbar' : 'plan-modal-bottom';

  const isMobile = window.innerWidth < 600;
  const { showWarning } = useNotifications();

  useEffect(() => {
    if (isMobile) {
      showWarning(
        'This form is not optimized for mobile. Please use desktop for better experience.',
      );
    }
  }, [isMobile, showWarning]);

  const allPlanEvents = getEventsOfAPlan(plan);
  const events = useMemo(() => {
    if (!isSearching) return getEventsOfAPlanDay(plan, day);

    // Search for events:
    return allPlanEvents.filter(
      ({ eventType, title }) =>
        (!searchedType || eventType === searchedType) &&
        (!searchedValue || title.toLocaleLowerCase().includes(searchedValue.toLocaleLowerCase())),
    );
  }, [day, plan, searchedType, searchedValue]);

  const tabs = useMemo(() => {
    const tabElements: JSX.Element[] = [];
    for (let index = 0; index < (plan?.duration || 1); index++) {
      tabElements.push(
        <Tab
          key={index}
          label={`Day ${index + 1}`}
          onClick={() => setDay(index + 1)}
          sx={({ palette: { text } }) => ({
            color: `${day === index + 1 ? 'inherit' : text.secondary} !important`,
          })}
          value={index + 1}
          {...a11yProps(index)}
        />,
      );
    }
    return tabElements;
  }, [plan?.duration]);

  // Clear searched values when switching days:
  useEffect(() => {
    if (day >= 1) {
      setSearchedType('');
      setSearchedValue('');
    }
  }, [day]);

  // Clear selected day when searching:
  useEffect(() => {
    if (isSearching) setDay(-1);
    else setDay(1);
  }, [isSearching]);

  return (
    <>
      <Portal container={document.getElementById(portalId)}>
        <Button onClick={() => goToPage(0)} size="small" variant="outlined">
          Back to Public information
        </Button>

        <Button onClick={() => goToPage(2)} size="small" variant="contained">
          Go to Others
        </Button>
      </Portal>

      <Grid container sx={{ minHeight: 'calc(100vh - 70px)' }}>
        <Grid
          item
          xl={9}
          lg={8}
          xs={12}
          sx={{ display: 'flex', flexFlow: 'column', padding: '32px', rowGap: '24px' }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ color: 'text.primary' }}>
                      <SearchNormal1 size={20} />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setSearchedValue(e.target.value)}
                placeholder="Search Event"
                size="small"
                value={searchedValue}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <Autocomplete
                  getOptionLabel={(option) => option}
                  onChange={(e: any, newValue) => {
                    setSearchedType(newValue || '');
                  }}
                  options={Object.values(ItineraryEventType)}
                  renderInput={(params) => <TextField {...params} label="Event type" />}
                  value={searchedType || null}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Box>
            <Tabs
              allowScrollButtonsMobile
              aria-label="days-tab"
              scrollButtons="auto"
              sx={{ overflowX: 'auto' }}
              TabIndicatorProps={{
                children: <span />,
                sx: {
                  backgroundColor: 'transparent',
                  display: 'flex',
                  justifyContent: 'center',
                  '& > span': { bgcolor: 'text.primary', maxWidth: '16px', width: '100%' },
                },
              }}
              value={day}
              variant="scrollable"
            >
              <Tab sx={{ minWidth: 0, padding: 0 }} value={-1} />
              {tabs.map((tab) => tab)}
            </Tabs>
            <Divider flexItem />
          </Box>

          {events.map((event: IEvent, index) => {
            return (
              <EventCard
                key={event._id as string}
                event={event}
                onEventEdit={() => setSelectedEventIndex(index)}
                plan={plan}
                setPlan={setPlan}
              />
            );
          })}
          {events.length === 0 && (isSearching ? <EventNotFound /> : <EventLanding />)}

          {!isSearching && (
            <Button
              onClick={() => setSelectedEventIndex(events.length)}
              startIcon={<Add />}
              sx={{ margin: events.length === 0 ? '0 auto' : null, width: 'fit-content' }}
              variant="contained"
            >
              Add Event
            </Button>
          )}
        </Grid>

        <Grid
          item
          xl={3}
          lg={4}
          xs={12}
          sx={(theme) => ({
            background: '#F6F6F6',
            padding: '32px',
            [theme.breakpoints.down('lg')]: { display: 'none' },
          })}
        >
          <ItinerarySummary events={events} />
        </Grid>
      </Grid>

      <EventForm
        event={events[selectedEventIndex]}
        handleClose={() => setSelectedEventIndex(-1)}
        newEventDay={day}
        open={selectedEventIndex > -1}
        plan={plan}
        setPlan={setPlan}
      />
    </>
  );
};

export default ItineraryForm;
