import { LANDING_ROUTE, LOGO_PATH } from '../../constants/routes';

function Logo({ size = 'medium', width }: { size?: 'small' | 'medium'; width?: number }) {
  return (
    <img
      src={LOGO_PATH}
      style={{ cursor: 'pointer' }}
      alt="logo"
      width={width ?? size === 'medium' ? 123 : 103}
      onClick={() => (window.location.href = LANDING_ROUTE)}
    />
  );
}
export default Logo;
