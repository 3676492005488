import {
  PAYOUT_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  LOGIN_ROUTE,
  PLANS_ROUTE,
  PRIVACY_POLICY_ROUTE,
  PROFILE_ROUTE,
  TERMS_AND_CONDITIONS_ROUTE,
  GUIDELINE_ROUTE,
  LANDING_ROUTE,
  HOME_ROUTE,
  REFERRAL_ROUTE,
  REVENUE_AGREEMENT_ROUTE,
  CREATOR_LEVEL_ROUTE,
} from '../constants/routes';
import UnknownRoute from '../pages/404';
import CreatorLevelPage from '../pages/CreatorLevel';
import GuidelinePage from '../pages/Guidelines';
import HomePage from '../pages/Home';
import LandingPage from '../pages/LandingPage';
import LoginPage from '../pages/Login';
import PayoutPage from '../pages/Payout';
import PlansPage from '../pages/Plans';
import PrivacyPolicyPage from '../pages/PrivacyPolicy';
import ProfilePage from '../pages/Profile';
import ReferralPage from '../pages/Referrals';
import ResetPasswordPage from '../pages/ResetPassword';
import RevenueSharingAgreementPage from '../pages/RevenueSharingAgreement';
import TermsAndConditionsPage from '../pages/TermsAndConditions';

interface RouteType {
  path: string;
  component: React.FC;
  name: string;
  protected: boolean;
}

const routes: RouteType[] = [
  {
    path: LANDING_ROUTE,
    component: LandingPage,
    name: 'Landing Page',
    protected: false,
  },
  {
    path: HOME_ROUTE,
    component: HomePage,
    name: 'Home Screen',
    protected: true,
  },
  {
    path: LOGIN_ROUTE,
    component: LoginPage,
    name: 'Login Screen',
    protected: false,
  },
  {
    path: TERMS_AND_CONDITIONS_ROUTE,
    component: TermsAndConditionsPage,
    name: 'T&C Screen',
    protected: false,
  },
  {
    path: REVENUE_AGREEMENT_ROUTE,
    component: RevenueSharingAgreementPage,
    name: 'Revenue Agreement Screen',
    protected: false,
  },
  {
    path: PRIVACY_POLICY_ROUTE,
    component: PrivacyPolicyPage,
    name: 'Privacy Policy Screen',
    protected: false,
  },
  {
    path: PLANS_ROUTE,
    component: PlansPage,
    name: 'Plan Screen',
    protected: true,
  },
  {
    path: PROFILE_ROUTE,
    component: ProfilePage,
    name: 'Profile Screen',
    protected: true,
  },
  {
    path: FORGOT_PASSWORD_ROUTE,
    component: ResetPasswordPage,
    name: 'Reset Password Screen',
    protected: false,
  },
  {
    path: PAYOUT_ROUTE,
    component: PayoutPage,
    name: 'Payout Screen',
    protected: true,
  },
  {
    path: REFERRAL_ROUTE,
    component: ReferralPage,
    name: 'Referral Screen',
    protected: true,
  },
  {
    path: CREATOR_LEVEL_ROUTE,
    component: CreatorLevelPage,
    name: 'Creator Level Screen',
    protected: true,
  },
  {
    path: GUIDELINE_ROUTE,
    component: GuidelinePage,
    name: 'Guideline Screen',
    protected: true,
  },
  {
    path: '*',
    component: UnknownRoute,
    name: '404',
    protected: false,
  },
];

export default routes;
