import { getEnv } from '../utils/env';

export const DEFAULT_IMG_SRC = 'image-placeholder.png';
export const DEFAULT_COVER_IMG_SRC = 'cover-image-placeholder.png';
export const IMG_BASE_URL = 'https://imagedelivery.net/RE9LxZWBOqnhrMF7Tifl-g';
export const GOOGLE_MAP_API_KEY = getEnv('GoogleMapApiKey');
export const HUBSPOT_TICKET = `https://share-eu1.hsforms.com/1yFP9PfbdTtGStO2AWiIpBA2b3aui`;
export const ZIPPTRIP_URL_ROOT =
  window.location.host === 'creator.zipptrip.com'
    ? 'https://zipptrip.com'
    : 'https://stage.zipptrip.com';
