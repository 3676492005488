import { useEffect, useState } from 'react';

import { Center, CentralModal, CentralModalProps } from '@zipptrip/zipptrip-canvas';
import { IPlannerBase, UserType } from '@zipptrip/zipptrip-commons';
import { useNavigate } from 'react-router-dom';
import StepWizard from 'react-step-wizard';

import SignupName from './SignupName';
import SignupSocialMedia from './SignupSocialMedia';
import SignupSuccess from './Success';
import { auth, sendGAEvent } from '../../config/firebase';
import { HOME_ROUTE } from '../../constants/routes';

export type SignupModalProps = CentralModalProps;

const SignupModal = (props: SignupModalProps) => {
  const navigate = useNavigate();
  const { onClose: closeModal, open } = props;

  const [form1Values, setForm1Values] = useState<IPlannerBase>({
    name: '',
    email: '',
    termsAccepted: false,
    userType: UserType.Creator,
  });
  const [isAgency, setIsAgency] = useState(false);

  // if logged in, return to home
  useEffect(() => {
    if (auth.currentUser) navigate(HOME_ROUTE);

    const searchParams = new URLSearchParams(window.location.search);
    const join = searchParams.get('join') || '';
    if (join == 'agency') {
      setIsAgency(true);
    }
  }, []);

  useEffect(() => {
    if (open) sendGAEvent('open:signup-modal');
  }, [open]);

  return (
    <CentralModal
      {...props}
      sx={{ padding: { xs: '48px 16px', sm: '48px 32px' }, width: 'min(85vw, 624px)' }}
    >
      <Center height="auto" sx={{ width: 'fit-content' }}>
        <StepWizard isLazyMount>
          <SignupName
            formValues={form1Values}
            setFormValues={setForm1Values}
            isAgency={isAgency}
            setIsAgency={setIsAgency}
          />
          <SignupSocialMedia previousData={form1Values} isAgency={isAgency} />
          <SignupSuccess onSubmit={() => (closeModal ? closeModal({}, 'backdropClick') : null)} />
        </StepWizard>
      </Center>
    </CentralModal>
  );
};

export default SignupModal;
