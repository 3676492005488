import { Theme } from '@mui/material';

const styles = (theme: Theme) => ({
  formField: {
    marginTop: theme.spacing(1),
    marginBottom: 0,
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    overflow: 'hidden' as const,
  },
  error: {
    color: theme.palette.error.main,
    fontSize: 12,
    margin: '2px 24px',
  },
});

export default styles;
