export const faqContent = [
  {
    title: 'How can I become a plan creator on your platform?',
    answers:
      "To become a plan creator with ZippTrip, complete the registration process. Once approved, you'll receive a confirmation via email. As a plan creator with ZippTrip, you gain access to a dedicated audience passionate about unique travel experiences, making it a win-win partnership.",
  },
  {
    title: 'How do I start creating a travel plan?',
    answers:
      "Once your profile is complete, navigate to the 'plans' section and select 'Create new plan'. Here, you'll be prompted to add public information and a detailed itinerary for your trip plan, enriching the experience for potential buyers.",
  },
  {
    title: 'How and when do I receive payments for my plan sales?',
    answers:
      "Simply connect your bank account for hassle-free transactions. We've integrated with Stripe for a secure payment gateway. After you're all set, you can initiate withdrawal requests, which we commit to processing within 5 business days.",
  },
];
