import { ReactNode } from 'react';

import { Box } from '@mui/material';
import { Center } from '@zipptrip/zipptrip-canvas';

export default function AuthBackground({ children }: { children: ReactNode }) {
  return (
    <Center
      sx={{
        backgroundImage: `linear-gradient(rgba(0, 15, 14, 0.18) 0%, rgba(0, 15, 14, 0.82) 88.78%), url('/BG.png')`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'space',
      }}
    >
      <Box
        boxShadow={2}
        display="flex"
        sx={(theme) => ({
          bgcolor: 'text.background',
          borderRadius: theme.spacing(5),
          overflowX: 'hidden',
          padding: { xs: '48px 16px', sm: '48px 32px' },
          width: 'min(85vw, 624px)',
          '& > div': { width: '100%' },
        })}
      >
        {children}
      </Box>
    </Center>
  );
}
