import { SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { debounce } from '@mui/material/utils';
import { FormAutocomplete } from '@zipptrip/zipptrip-canvas';
import { PlaceType } from '@zipptrip/zipptrip-commons';
import { useFormikContext } from 'formik';

import { GOOGLE_MAP_API_KEY } from '../../constants/commons';
import { _get } from '../form/utils';

const parse = require('autosuggest-highlight/parse');

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) return;

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

interface Props {
  country: string;
  field: string;
  label: string;
}

const PlaceAutoComplete = ({ country, field, label }: Props) => {
  const { setFieldValue, values } = useFormikContext();
  const value = _get(values, field);

  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<readonly PlaceType[]>([]);
  const loaded = useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  const fetch = useMemo(
    () =>
      debounce((request: { input: string }, callback: (results?: readonly PlaceType[]) => void) => {
        (autocompleteService.current as any).getPlacePredictions(request, callback);
      }, 400),
    [],
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }

    if (!autocompleteService.current) return undefined;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }
    if (inputValue !== value?.description) setFieldValue(field, null);

    // Set the country restriction
    const options = {
      input: inputValue,
      componentRestrictions: { country },
    };

    fetch(options, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) newOptions = [value];

        if (results) newOptions = [...newOptions, ...results];

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [JSON.stringify(value), inputValue, fetch, country]);

  return (
    <FormAutocomplete
      autoComplete
      defaultValue={null}
      field={field}
      filterOptions={(x: PlaceType[]) => x}
      filterSelectedOptions
      getOptionLabel={(option: PlaceType | string) =>
        typeof option === 'string' ? option : option?.description
      }
      includeInputInList
      isOptionEqualToValue={(options: PlaceType, value: PlaceType) =>
        options?.place_id === value?.place_id
      }
      noOptionsText="No locations"
      onChange={(event: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        if (newValue) setFieldValue(field, newValue);
      }}
      onInputChange={(event: SyntheticEvent, newInputValue: string) => setInputValue(newInputValue)}
      options={options}
      renderOption={(props: object, option: PlaceType) => {
        const matches = option?.structured_formatting?.main_text_matched_substrings || [];

        const parts = parse(
          option?.structured_formatting?.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length]),
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <LocationOnIcon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                {parts.map((part: any, index: number) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting?.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
      textfieldProps={{ label }}
    />
  );
};
export default PlaceAutoComplete;
