import { useEffect, useState } from 'react';

import { IPlanner } from '@zipptrip/zipptrip-commons';
import { driver } from 'driver.js';

import { createOrUpdatePlanner } from '../../utils/planner';

import 'driver.js/dist/driver.css';

const tourDriverGenerator = (updateStepsCompleted: (step: number) => void) =>
  driver({
    showProgress: true,
    steps: [
      {
        element: '#navItem-Plans',
        popover: {
          title: 'Plans',
          description:
            'View all your unfinished, in-review, and published plans in one place. You can change or remove plans as needed.',
          align: 'center',
          onPopoverRender: () => updateStepsCompleted(1),
        },
      },
      {
        element: '#navItem-Profile',
        popover: {
          title: 'Profile',
          description:
            'Improve Your Profile: Your profile is your identity. Make it stand out by adding more information, an interesting bio, and links to your social media. Let others see the best version of yourself!',
          align: 'center',
          onPopoverRender: () => updateStepsCompleted(2),
        },
      },
      {
        element: '#navItem-Payout',
        popover: {
          title: 'Payout',
          description:
            "Easily connect your bank account to securely receive the revenue you've earned. You can create withdrawal requests and see the transaction history.",
          align: 'center',
          onPopoverRender: () => updateStepsCompleted(3),
        },
      },
      {
        element: '#navItem-Referrals',
        popover: {
          title: 'Referrals',
          description:
            'Invite other creators to join ZippTrip, and start earning with every sale they make.',
          align: 'center',
          onPopoverRender: () => updateStepsCompleted(4),
        },
      },
      {
        element: '#navItem-CreatePlanButton',
        popover: {
          title: 'Create Plan',
          description:
            'Create a New Travel Plan: Add Itinerary and events, Choose to Draft or Publish Them.',
          align: 'center',
          onPopoverRender: () => updateStepsCompleted(5),
        },
      },
    ],
  });

export default function useTourDriver(planner: IPlanner | undefined) {
  const { uid, tourStepsCompleted } = planner || {};
  const [stepsCompleted, setStepsCompleted] = useState(0);

  useEffect(() => {
    if (!!tourStepsCompleted) setStepsCompleted(tourStepsCompleted);
  }, [tourStepsCompleted]);

  const updateStepsCompleted = (step: number) => {
    if (step <= stepsCompleted) return;
    setStepsCompleted(step);
    createOrUpdatePlanner(uid as string, { tourStepsCompleted: step });
  };

  return tourDriverGenerator(updateStepsCompleted);
}
