/* eslint-disable prettier/prettier */
// env object
const envObject: { [k: string]: string } = {
  FirebaseApiKey: "AIzaSyBTYJ2lq7x-Eiw0h2ilhBcrWQpgeFu1SBI" || '',
  FirebaseAuthDomain: 'prod-zipptrip.firebaseapp.com' || '',
  FirebaseProjectId: 'prod-zipptrip' || '',
  FirebaseStorageBucket: 'prod-zipptrip.appspot.com' || '',
  FirebaseMessagingSenderId: "496544261198" || '',
  FirebaseAppId: "1:496544261198:web:ca4952443b219319a60f1f" || '',
  FirebaseMeasurementId: "G-D3EQZ34983" || '',
  GoogleMapApiKey: "AIzaSyBTYJ2lq7x-Eiw0h2ilhBcrWQpgeFu1SBI" || '',
  BaseUrl: 'https://prod-zipptrip.lm.r.appspot.com/api' || '',
  StripePK: "pk_live_51Mi0G1Eo9bhYbAJFOFAwTwKL3AP1E4gqiVpDfaroNw82p1IODtkwpQTTxsAuKpqlcyuly7xTbsHbCkqveZUhhD1000DbBL5kEJ" || '',
  StripeOAuthId: "ca_NSwF9xyvvDAp92OJ09596FpdCmYNQfBN" || '',
  CfToken: process.env.REACT_APP_CloudFlareToken || '',
};

export const getEnv = (key: string) => {
  return envObject[key];
};
