import { Dispatch, SetStateAction } from 'react';

import { Box } from '@mui/material';
import { Center } from '@zipptrip/zipptrip-canvas';
import { User } from 'iconsax-react';

import { ImageVariant } from '../../types/image';
import { getImageUrl } from '../../utils/images';
import ImageUploader from '../imageUploader/ImageUploader';

interface Props {
  coverImageSrc: string;
  imageSrc: string;
  setCoverImageSrc: Dispatch<SetStateAction<string>>;
  setImageSrc: Dispatch<SetStateAction<string>>;
}

export default function ProfilePictureInfo({
  coverImageSrc,
  imageSrc,
  setCoverImageSrc,
  setImageSrc,
}: Props) {
  return (
    <Box sx={{ position: 'relative' }}>
      <ImageUploader
        imageSrc={getImageUrl(coverImageSrc, ImageVariant.ORIGINAL)}
        index={0}
        setImageSrc={(img) => setCoverImageSrc(img)}
        ratio={3.5}
      />

      <Box
        sx={{
          bottom: '-32px',
          pointerEvents: 'none',
          position: 'absolute',
          width: '100%',
          '& > *': { pointerEvents: 'auto' },
        }}
      >
        <ImageUploader
          imageSrc={getImageUrl(imageSrc)}
          index={0}
          placeholder={
            <Center
              height="100%"
              sx={{ bgcolor: 'text.divider', color: 'text.secondary', width: '100%' }}
            >
              <User size={56} />
            </Center>
          }
          showButton={false}
          setImageSrc={(img) => setImageSrc(img)}
          sx={{
            border: '3px solid #FFFFFF',
            borderRadius: '50%',
            height: 'min(24vw, 120px)',
            margin: 'auto',
            width: 'min(24vw, 120px)',
          }}
        />
      </Box>
    </Box>
  );
}
