import { useEffect, useState } from 'react';

import { ConfirmModal, useNotifications } from '@zipptrip/zipptrip-canvas';
import { IPlanner } from '@zipptrip/zipptrip-commons';
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

import useTourDriver from './useTourDriver';
import UnverifiedEmailDialog from './VerifyEmail';
import { auth } from '../../config/firebase';
import { LANDING_ROUTE } from '../../constants/routes';
import { createOrUpdatePlanner, getPlannerById } from '../../utils/planner';
import LoadingSpinner from '../loader/LoadingSpinner';

interface Props {
  children: React.ReactNode;
}

const AuthChecker = ({ children }: Props) => {
  const navigate = useNavigate();
  const { showError } = useNotifications();
  const [planner, setPlanner] = useState<IPlanner | undefined>();
  const [tourModalOpen, setTourModalOpen] = useState(false);
  const tourDriver = useTourDriver(planner);

  useEffect(() => {
    if (!auth.currentUser) navigate(LANDING_ROUTE);
  }, [auth.currentUser]);

  useEffect(() => {
    if (!auth.currentUser?.uid) return;
    getPlannerById(auth.currentUser?.uid as string).then(async (user: IPlanner | undefined) => {
      if (!user) {
        await signOut(auth);
        navigate(LANDING_ROUTE);
        return;
      }
      if (user?.isBlocked) {
        showError('Your account is not active. Please contact the administrator.');
        await signOut(auth);
        navigate(LANDING_ROUTE);
        return;
      }
      setPlanner(user);
    });
  }, [navigate, showError]);

  const { uid, hideTour = false, tourStepsCompleted = 0 } = planner || {};

  useEffect(() => {
    if (!uid) return;

    // Tour modal:
    if (!hideTour && tourStepsCompleted < 5 && window.innerWidth >= 600) {
      setTourModalOpen(true);
    }
  }, [uid, hideTour, tourStepsCompleted]);

  if (!auth?.currentUser || !planner) return <LoadingSpinner />;
  if (!auth.currentUser.emailVerified) return <UnverifiedEmailDialog />;

  return (
    <>
      <ConfirmModal
        cancelText="Don’t show it again"
        confirmText={!!tourStepsCompleted ? 'Continue tour' : 'Take a tour'}
        onCancel={() => {
          setTourModalOpen(false);
          createOrUpdatePlanner(uid as string, { hideTour: true });
        }}
        onClose={() => setTourModalOpen(false)}
        onConfirm={() => {
          setTourModalOpen(false);
          tourDriver.drive(Math.max(tourStepsCompleted - 1, 0));
        }}
        open={tourModalOpen}
        title="Take a moment to get comfortable"
      >
        We’d like to show you around. It will be quick, promise.
        <br />
        You’ll see where beautiful features are, so you get off to a flying start.
      </ConfirmModal>

      {children}
    </>
  );
};

export default AuthChecker;
