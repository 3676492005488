import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { useNotifications } from '@zipptrip/zipptrip-canvas';
import { sendEmailVerification, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

import { auth } from '../../config/firebase';
import { LOGIN_ROUTE } from '../../constants/routes';

// component to show dialog and Please verify your email first
const UnverifiedEmailDialog = () => {
  const navigate = useNavigate();
  const { showSuccess, showError } = useNotifications();
  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        navigate(LOGIN_ROUTE);
      })
      .catch((error) => {
        showError('Unexpected Error!! Please try again');
        console.error(error);
      });
  };

  const handleResendVerification = () => {
    if (!auth.currentUser) return;
    sendEmailVerification(auth.currentUser, {
      url: window.location.href,
    })
      .then(() => {
        showSuccess('Verification email sent successfully');
      })
      .catch(() => {
        showError('Unexpected Error!! Please try again');
      });
  };

  return (
    <Dialog
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Verify your email address'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Please verify your email first. If you did not receive the verification email, please
          check your spam folder. If you still did not receive the email, please click the button
          below to send a new verification email.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSignOut} color="error">
          Sign Out
        </Button>
        <Button onClick={handleResendVerification} color="success">
          Resend Verification Email
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnverifiedEmailDialog;
