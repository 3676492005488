import { IEvent, IPlan, ItineraryEventType, PlanStatusType } from '@zipptrip/zipptrip-commons';
import axios from 'axios';

import { getRequestHeader } from './commons';
import { getEnv } from './env';
import { GOOGLE_MAP_API_KEY } from '../constants/commons';

export const getPlansByPlannerId = async (): Promise<IPlan[]> => {
  const url = getEnv('BaseUrl') + '/creator-plan';
  const header = await getRequestHeader();
  const res = (await axios.get(url, header)).data;
  return res.data as IPlan[];
};

export const getPlan = async (id: string): Promise<IPlan> => {
  const url = getEnv('BaseUrl') + '/creator-plan/' + id;
  const header = await getRequestHeader();
  const res = (await axios.get(url, header)).data;
  return res.data as IPlan;
};

export const createOrUpdatePlan = async (values: IPlan) => {
  const url = getEnv('BaseUrl') + '/creator-plan';
  const header = await getRequestHeader();
  let res;
  if (values._id) {
    res = (await axios.put(url, values, header)).data;
  } else {
    res = (await axios.post(url, values, header)).data;
  }
  return res.data as IPlan;
};

export const createOrUpdateEvent = async (values: IEvent, planId: IPlan['_id'], day: number) => {
  let getCodeString = '';
  if (values.eventType === ItineraryEventType.Commute && values.fromLocation && values.toLocation) {
    const fromLocationApiUrl = `https://maps.googleapis.com/maps/api/geocode/json?place_id=${values.fromLocation.place_id}&key=${GOOGLE_MAP_API_KEY}`;
    const toLocationApiUrl = `https://maps.googleapis.com/maps/api/geocode/json?place_id=${values.toLocation.place_id}&key=${GOOGLE_MAP_API_KEY}`;
    const fromLocationRes = (await axios.get(fromLocationApiUrl)).data;
    const toLocationRes = (await axios.get(toLocationApiUrl)).data;
    const fromLocationGeocode = fromLocationRes?.results?.[0]?.geometry?.location;
    const toLocationGeocode = toLocationRes?.results?.[0]?.geometry?.location;
    values.fromLocation.geoLocation = fromLocationGeocode;
    values.toLocation.geoLocation = toLocationGeocode;
    getCodeString = `${fromLocationGeocode.lat},${fromLocationGeocode.lng}`;
  } else if (values.location) {
    const locationApiUrl = `https://maps.googleapis.com/maps/api/geocode/json?place_id=${values.location.place_id}&key=${GOOGLE_MAP_API_KEY}`;
    const locationRes = (await axios.get(locationApiUrl)).data;
    const locationGeocode = locationRes?.results?.[0]?.geometry?.location;
    values.location.geoLocation = locationGeocode;
    getCodeString = `${locationGeocode.lat},${locationGeocode.lng}`;
  }
  const timezoneApiUrl = `https://maps.googleapis.com/maps/api/timezone/json?timestamp=${new Date().getSeconds()}&location=${getCodeString}&key=${GOOGLE_MAP_API_KEY}`;
  const timezoneRes = (await axios.get(timezoneApiUrl)).data;
  values.timezone = timezoneRes?.timeZoneId || '';
  const url = `${getEnv('BaseUrl')}/creator-plan/${planId}/day/${day}/event`;
  const header = await getRequestHeader();
  let res;
  if (values._id) {
    res = (await axios.put(url, values, header)).data;
  } else {
    res = (await axios.post(url, values, header)).data;
  }
  return res.data as IPlan;
};

export const deleteEvent = async (eventId: IEvent['_id'], planId: IPlan['_id'], day: number) => {
  const url = `${getEnv('BaseUrl')}/creator-plan/${planId}/day/${day}/event/${eventId}`;
  const header = await getRequestHeader();
  await axios.delete(url, header);
};

export const duplicatePlan = async (planId: string) => {
  const url = `${getEnv('BaseUrl')}/creator-plan/duplicate/${planId}`;
  const header = await getRequestHeader();
  const res = (await axios.get(url, header)).data;
  return res.data as IPlan;
};

export const deletePlan = async (planId: string) => {
  const url = `${getEnv('BaseUrl')}/creator-plan/${planId}`;
  const header = await getRequestHeader();
  await axios.delete(url, header);
};

export const updateStatus = async (planId: string, status: PlanStatusType) => {
  const url = `${getEnv('BaseUrl')}/creator-plan`;
  const header = await getRequestHeader();
  const values = { status, _id: planId };
  return axios.patch(url, values, header);
};

export const getEventsOfAPlan = (plan: IPlan) => plan?.days?.flatMap(({ events }) => events) || [];

export const getEventsOfAPlanDay = (plan: IPlan, day: number) =>
  plan?.days?.find(({ number }) => Number(number) === day)?.events || [];

export const getDayofAPlanEvent = (plan: IPlan, event: IEvent) =>
  plan?.days?.find(({ events }) => events.some(({ _id }) => _id === event?._id))?.number ?? -1;
