import React from 'react';

import { Box, Typography } from '@mui/material';
import { Center } from '@zipptrip/zipptrip-canvas';

const TermsAndConditionsPage: React.FC = () => {
  return (
    <Box margin={10}>
      <Center height={2}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          TERMS OF SERVICE
        </Typography>
      </Center>
      <br />
      <br />
      <Typography>
        Effective Date: 7/1/2023 These Terms of Service ("Terms") govern the access to and use of
        the Zipp Trip website, applications, and services (collectively, the "Services") provided by
        Zipp Trip Limited ("Zipp Trip"). By accessing or using the Services, you acknowledge that
        you have read, understood, and agree to be bound by these Terms, including any additional
        terms and conditions incorporated by reference.
      </Typography>
      <br />
      <br />
      <Typography variant="h5" sx={{ mb: 2 }}>
        Content Ownership
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        By submitting travel plans to ZippTrip, the creator acknowledges that they own all the given
        content and have the legal right to distribute and sell it.
      </Typography>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Accuracy of Information
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        The creator is responsible for ensuring that all information in the travel plan is accurate
        and up-to-date. They must provide a detailed itinerary that accurately reflects the
        experiences, accommodations, commutes, activities, and other details that will be included
        in the plan.
      </Typography>
      <Typography variant="h5" sx={{ mb: 2 }}>
        License to Use
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        The creator grants ZippTrip an exclusive, royalty-based, worldwide license to use, display,
        and distribute the content they submit to ZippTrip. Royalties are based on the amount of
        purchased plans. The public information of a plan will be open for all. But the private
        information can only be accessed upon purchase.
      </Typography>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Intellectual Property Rights
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        The creator must ensure that they have the legal right to use any third-party intellectual
        property (such as logos, photos, or videos) included in the travel plan. They are
        responsible for obtaining any necessary permissions or licenses.
      </Typography>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Liability
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        The creators are responsible for ensuring that their plans do not contain any harmful
        indications or misinformation. We ask that you carefully review your plans, ensuring that
        all information provided is correct, up-to-date, and does not contain any misleading or
        false information.
      </Typography>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Payment
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        The creator will receive a percentage of the revenue generated from sales from their travel
        plan on ZippTrip. The exact percentage will be determined by ZippTrip and can vary depending
        on the exclusivity and promotions of the plan.
      </Typography>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Changes and Updates
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        The creator is responsible for updating their travel plan as needed to ensure that all
        information is accurate and up-to-date. They must notify ZippTrip of any changes and ensure
        that customers are informed of any updates.
      </Typography>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Termination
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        ZippTrip reserves the right to terminate the agreement with the creator at any time for any
        reason. The creator may also terminate the agreement by notifying ZippTrip in writing.
      </Typography>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Governing Law
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        The agreement between the creator and ZippTrip will be governed by the laws of the
        jurisdiction in which ZippTrip is registered.
      </Typography>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Dispute Resolution
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Any disputes arising from the agreement will be resolved through arbitration in accordance
        with the rules of the jurisdiction in which ZippTrip is registered.
      </Typography>
    </Box>
  );
};
export default TermsAndConditionsPage;
