import React from 'react';

import { Box, Typography } from '@mui/material';
import { Center } from '@zipptrip/zipptrip-canvas';

const RevenueSharingAgreementPage: React.FC = () => {
  return (
    <Box margin={10}>
      <Center height={2}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          REVENUE SHARE AGREEMENT
        </Typography>
      </Center>
      <br />
      <br />
      <Typography>
        This Revenue Share Agreement (the "Agreement") is entered into as of 07/01/2023 (the
        "Effective Date") by and between Zipp Trip Limited ("Zipp Trip") and ("Creator").
      </Typography>
      <br />
      <br />
      <Typography variant="h5" sx={{ mb: 2 }}>
        1. Purpose
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        The purpose of this Agreement is to establish the terms and conditions for revenue sharing
        between Zipp Trip and Creator for the sale of Itineraries (as defined in the Zipp Trip Terms
        of Service).
      </Typography>
      <Typography variant="h5" sx={{ mb: 2 }}>
        2. Revenue Share
      </Typography>
      <Typography variant="h6" sx={{ mb: 2 }}>
        <u>Distribution</u>
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Zipp Trip and Creator agree to a revenue share ratio of 15% to Zipp Trip and 85% to Creator,
        respectively, based on the total purchase price of each Itinerary sold through the Services.
      </Typography>
      <Typography variant="h6" sx={{ mb: 2 }}>
        <u>Calculation</u>
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        The revenue share shall be calculated on a monthly basis and shall be based on the net
        revenue received by Zipp Trip from the sale of Itineraries, after deducting any applicable
        transaction fees, taxes, or refunds.
      </Typography>
      <Typography variant="h5" sx={{ mb: 2 }}>
        3. Payment and Payouts
      </Typography>
      <Typography variant="h6" sx={{ mb: 2 }}>
        <u>Payment Collection</u>
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Zipp Trip shall handle the collection of payments from users who purchase Itineraries
        through the Services.
      </Typography>
      <Typography variant="h6" sx={{ mb: 2 }}>
        <u> Payouts to Creator</u>
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Zipp Trip shall process the revenue share payout to Creator within 7-10 business days after
        the completion of the purchase, subject to any applicable transaction fees or deductions.
      </Typography>
      <Typography variant="h6" sx={{ mb: 2 }}>
        <u>Payment Method</u>
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Payouts shall be made to Creator via electronic funds transfer (EFT) to the bank account
        specified by Creator in their account settings. Reporting and Records Zipp Trip shall
        provide Creator with regular reports detailing the sales and revenue generated from the
        Itineraries. Creator shall have access to an online dashboard to monitor their earnings and
        view detailed transaction records. Confidentiality Creator agrees to keep all revenue share
        amounts and transactional details confidential. Creator shall not disclose or share any
        revenue share amounts or transactional details with other users or third parties.
      </Typography>
      <Typography variant="h5" sx={{ mb: 2 }}>
        4. Intellectual Property Rights
      </Typography>
      <Typography variant="h6" sx={{ mb: 2 }}>
        <u>Ownership</u>
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Creator retains all rights to their original content included in the Itineraries, subject to
        the necessary licenses granted to Zipp Trip for the provision of the Services.
      </Typography>
      <Typography variant="h6" sx={{ mb: 2 }}>
        <u>Prohibited Use</u>
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Creator shall not reproduce, modify, distribute, or create derivative works based on the
        paid content without the explicit permission of Zipp Trip.
      </Typography>
      <Typography variant="h6" sx={{ mb: 2 }}>
        5. Term and Termination
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        This Agreement shall remain in effect until terminated by either party. Either party may
        terminate this Agreement by providing 30 days' written notice to the other party. Upon
        termination, the rights and obligations of the parties regarding revenue sharing shall
        cease, but any remaining obligations under the Zipp Trip Terms of Service shall continue to
        apply.
      </Typography>
      <Typography variant="h6" sx={{ mb: 2 }}>
        6. Entire Agreement
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        This Agreement, along with the Zipp Trip Terms of Service and any other referenced
        documents, constitutes the entire agreement between Zipp Trip and Creator
      </Typography>
    </Box>
  );
};
export default RevenueSharingAgreementPage;
