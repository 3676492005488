import { useEffect, useState } from 'react';

import { VisibilityOutlined as VisibilityOutlinedIcon } from '@mui/icons-material';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { HorizontalBlock, useNotifications, VerticalBlock } from '@zipptrip/zipptrip-canvas';
import { signInWithPopup, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import StepWizard from 'react-step-wizard';

import ResetPasswordContainer from './ResetPassword';
import { auth, Providers, sendGAEvent } from '../../config/firebase';
import { HOME_ROUTE } from '../../constants/routes';
import { Event } from '../../types/commons';
import { getPlannerById } from '../../utils/planner';
import GoogleIcon from '../icons/google';
import Logo from '../logo/Logo';
import SignupModal from '../signup/SignupModal';

interface Props {
  setIsLoading: (isLoading: boolean) => void;
}
enum AccountProp {
  email = 'email',
  password = 'password',
}

const LoginContainer = ({ isActive, goToStep, setIsOpenSignupModal, setIsLoading }: any) => {
  const { showError } = useNotifications();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [account, setAccount] = useState({ email: '', password: '' });
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const onLogin = () => {
    setDisabled(false);
    navigate(HOME_ROUTE);
    sendGAEvent('event:login-success');
  };

  // if logged in, return to home
  useEffect(() => {
    if (auth.currentUser) navigate(HOME_ROUTE);
  }, []);

  const handelSignin = () => {
    if (!account.email || !account.password) return;
    setDisabled(true);
    signInWithEmailAndPassword(auth, account.email, account.password)
      .then(() => onLogin())
      .catch((error) => {
        showError(error.message as string);
        setDisabled(false);
      });
  };
  const handelAccountInfo = (property: AccountProp, event: Event) => {
    const accountObj = { ...account };
    accountObj[property] = event.target.value;
    setAccount(accountObj);
  };

  const handleSignInWithGoogle = () => {
    setDisabled(true);
    signInWithPopup(auth, Providers.google)
      .then(async () => {
        if (auth.currentUser?.uid) {
          setIsLoading(true);
          const user = await getPlannerById(auth.currentUser.uid);
          if (!user) {
            showError('Invalid email');
            signOut(auth).catch((error) => console.error(error));
          }
          setIsLoading(false);
        }
        onLogin();
      })
      .catch((error) => {
        setDisabled(false);
        setIsLoading(false);
        if (error.code !== 'auth/popup-closed-by-user') showError(error.message as string);
      });
  };
  return (
    <VerticalBlock
      sx={[
        { gap: '24px', '& > form': { width: { xs: '100%', sm: '77%' } } },
        !isActive ? { height: 0, overflow: 'hidden' } : {},
      ]}
    >
      <Logo />

      <Typography variant="T28B" sx={{ letterSpacing: '1px', textAlign: 'center' }}>
        Welcome to ZippTrip!
      </Typography>
      <form>
        <VerticalBlock sx={{ gap: '16px' }}>
          <TextField
            autoFocus
            fullWidth
            id="email"
            label="Your email"
            name="email"
            onChange={(event: Event) => handelAccountInfo(AccountProp.email, event)}
            type="email"
          />
          <TextField
            autoComplete="current-password"
            fullWidth
            id="password"
            InputProps={{
              endAdornment: (
                <IconButton
                  sx={{ padding: 0 }}
                  onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                >
                  <VisibilityOutlinedIcon />
                </IconButton>
              ),
            }}
            label="Password"
            name="password"
            onChange={(event: Event) => handelAccountInfo(AccountProp.password, event)}
            type={isPasswordVisible ? 'text' : 'password'}
          />

          <Box sx={{ textAlign: 'right', width: '100%' }}>
            <Button disabled={disabled} onClick={() => goToStep(2)}>
              Forgot password?
            </Button>
          </Box>
        </VerticalBlock>

        <VerticalBlock sx={{ marginTop: '24px', gap: '16px' }}>
          <Button
            color="primary"
            disabled={disabled}
            fullWidth
            onClick={handelSignin}
            size="large"
            type="submit"
            variant="contained"
          >
            Login
          </Button>

          <Button
            disabled={disabled}
            fullWidth
            onClick={handleSignInWithGoogle}
            size="large"
            startIcon={<GoogleIcon />}
            variant="outlined"
          >
            Or login with Google
          </Button>
        </VerticalBlock>
      </form>

      <Divider flexItem />

      <HorizontalBlock gap="4px">
        <Typography variant="T16R" sx={{ color: 'text.secondary' }}>
          Don't have an account?
        </Typography>

        <Button
          disableRipple
          onClick={() => setIsOpenSignupModal(true)}
          sx={{ fontSize: '16px', padding: 0 }}
        >
          Sign up now
        </Button>
      </HorizontalBlock>
    </VerticalBlock>
  );
};

const AuthContainer = ({ setIsLoading }: Props) => {
  const [isOpenSignupModal, setIsOpenSignupModal] = useState(false);

  return (
    <>
      <SignupModal
        open={isOpenSignupModal}
        closeButton={true}
        onClose={() => setIsOpenSignupModal(false)}
      />
      <StepWizard>
        <LoginContainer setIsOpenSignupModal={setIsOpenSignupModal} setIsLoading={setIsLoading} />
        <ResetPasswordContainer />
      </StepWizard>
    </>
  );
};

export default AuthContainer;
