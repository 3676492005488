import { MouseEvent, useEffect, useState } from 'react';

import { Avatar, Divider, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { HorizontalBlock } from '@zipptrip/zipptrip-canvas';
import {
  ACHIEVEMENT_LEVEL_SETTING,
  AchievementLevelId,
  IAchievementLevelConfig,
  IPlanner,
  UserType,
} from '@zipptrip/zipptrip-commons';
import { signOut } from 'firebase/auth';
import { Crown, Key, LogoutCurve, More, ProfileCircle, TagUser } from 'iconsax-react';
import { useNavigate } from 'react-router-dom';
import { useElementSize } from 'usehooks-ts';

import ChangePasswordModal from './ChangePasswordModal';
import { auth, sendGAEvent } from '../../config/firebase';
import { HUBSPOT_TICKET } from '../../constants/commons';
import { CREATOR_LEVEL_ROUTE, LANDING_ROUTE, PROFILE_ROUTE } from '../../constants/routes';
import { getImageUrl } from '../../utils/images';
import { getPlannerById } from '../../utils/planner';

const achievementLevelsReverse = Object.values(ACHIEVEMENT_LEVEL_SETTING).reverse();

const getLevel = (points: number) =>
  achievementLevelsReverse.find(({ minPoints }) => minPoints <= points);

const getLevelColors = (id: AchievementLevelId) => {
  if (id === AchievementLevelId.Explorer) {
    return { bgcolor: `var(--neutral-2)`, color: `var(--neutral-10)` };
  }
  if (id === AchievementLevelId.Adventurer) {
    return { bgcolor: `var(--gold-2)`, color: `var(--gold-6)` };
  }
  if (id === AchievementLevelId.Nomad) {
    return { bgcolor: `var(--green-2)`, color: `var(--green-6)` };
  }
  if (id === AchievementLevelId.Globetrotter) {
    return { bgcolor: `var(--primary-1)`, color: `var(--primary-4)` };
  }
  if (id === AchievementLevelId.Voyager) {
    return { bgcolor: `var(--blue-1)`, color: `var(--blue-6)` };
  }
};

export default function UserMenu() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const [planner, setPlanner] = useState<IPlanner | null>(null);
  const [ref, { width = 0 }] = useElementSize();

  const logout = () => {
    signOut(auth)
      .then(() => {
        navigate(LANDING_ROUTE);
        sendGAEvent('event:logout');
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getPlannerById(auth.currentUser?.uid || '')
      .then((user) => setPlanner(user || null))
      .catch((err) => console.error(err));
  }, []);

  if (!planner) return <></>;
  const { name, imageSrc, points = 0, userType = UserType.Creator } = planner;
  const { id: levelId, title: levelTitle } = getLevel(points) as IAchievementLevelConfig;
  const colors = getLevelColors(levelId);

  return (
    <>
      <HorizontalBlock
        onClick={({ currentTarget }: MouseEvent<HTMLElement>) => setAnchorEl(currentTarget)}
        ref={ref}
        sx={{
          borderRadius: '24px',
          cursor: 'pointer',
          gap: '16px',
          padding: '12px 16px',
          width: '100%',
          ...(!!anchorEl ? { bgcolor: 'primary.selectedBackground' } : {}),
        }}
      >
        <Avatar
          alt={name}
          sizes="28px"
          src={getImageUrl(imageSrc as string)}
          sx={{ height: '28px', width: '28px' }}
        />

        <HorizontalBlock sx={{ flex: 1 }}>
          <Typography
            sx={{ color: !!anchorEl ? 'text.primary' : 'text.secondary', flex: 1 }}
            variant="T14M"
          >
            {name}
          </Typography>

          <IconButton sx={{ color: !!anchorEl ? 'text.primary' : 'text.secondary' }}>
            <More />
          </IconButton>
        </HorizontalBlock>
      </HorizontalBlock>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        autoFocus={false}
        onClick={() => setAnchorEl(null)}
        onClose={() => setAnchorEl(null)}
        open={!!anchorEl}
        sx={{
          '& > .MuiPaper-root': {
            borderRadius: '24px',
            boxShadow: '0px 6px 20px 0px rgba(0, 0, 0, 0.12)',
            maxHeight: '100% !important',
          },
          '& ul': { padding: '4px' },
          '& li': { gap: '8px', padding: '12px', width },
        }}
        transformOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => navigate(PROFILE_ROUTE)}>
          <ProfileCircle />
          <Typography variant="T14M">Profile</Typography>
        </MenuItem>

        {userType === UserType.Creator && (
          <MenuItem onClick={() => navigate(CREATOR_LEVEL_ROUTE)}>
            <Crown />
            <Typography variant="T14M">Level</Typography>
            <Typography sx={{ ...colors, borderRadius: '40px', padding: '4px 8px' }} variant="T12M">
              {levelTitle}
            </Typography>
          </MenuItem>
        )}

        <MenuItem onClick={() => setChangePasswordOpen(true)}>
          <Key />
          <Typography variant="T14M">Change password</Typography>
        </MenuItem>

        <Divider />

        <MenuItem onClick={() => window.open(HUBSPOT_TICKET, '_blank')}>
          <TagUser />
          <Typography variant="T14M">Contact Support</Typography>
        </MenuItem>

        <MenuItem onClick={logout}>
          <LogoutCurve />
          <Typography variant="T14M">Logout</Typography>
        </MenuItem>
      </Menu>

      <ChangePasswordModal
        onCancel={() => setChangePasswordOpen(false)}
        open={changePasswordOpen}
      />
    </>
  );
}
