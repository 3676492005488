import { IPlannerBase } from '@zipptrip/zipptrip-commons';
import axios from 'axios';

import { getRequestHeader } from './commons';
import { getEnv } from './env';

export const registerPlanner = async (payload: IPlannerBase) => {
  const url = `${getEnv('BaseUrl')}/creator/registration`;
  const header = await getRequestHeader();
  const res = (await axios.post(url, payload, header)).data;
  return res.data;
};
