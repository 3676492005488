import { useEffect, useState } from 'react';

import { Button, ButtonProps, Tooltip } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { ConfirmModal, LoaderCircle, useNotifications } from '@zipptrip/zipptrip-canvas';
import { IPlan, PlanStatusType, isProfileComplete } from '@zipptrip/zipptrip-commons';

import { updateStatus } from '../../utils/plan';
import { getPlannerById } from '../../utils/planner';
import IncompleteProfileDialog from '../profile/IncompleteProfileDialog';

interface ActionButtonMap {
  label: string;
  message: string;
  info: string;
}

interface Props {
  plan: IPlan;
  refreshPlans: () => void;
  variant?: ButtonProps['variant'];
}

export default function PlanPublishButton({ plan, refreshPlans, variant = 'outlined' }: Props) {
  const [showIncompleteProfileDialog, setShowIncompleteProfileDialog] = useState<boolean>(false);
  const [{ label, message, info }, setActionButtonMap] = useState<ActionButtonMap>({
    label: '',
    message: '',
    info: '',
  });
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(!plan._id);
  const [showStatusUpdateConfirmation, setShowStatusUpdateConfirmation] = useState<boolean>(false);
  const { showError } = useNotifications();

  useEffect(() => {
    if (plan.status === PlanStatusType.Draft || !plan.status) {
      setActionButtonMap({
        label: 'Publish',
        message: 'Contratulations! Are you ready for the world to see your creation?',
        info: 'Publish this plan to the public. But we will do a quick review before it goes live.',
      });
    } else if (plan.status === PlanStatusType.Hidden) {
      setActionButtonMap({
        label: 'Publish Again',
        message: 'Do you want to publish this plan again?',
        info: 'This plan is currently hidden. You can publish it again to make it visible to the public.',
      });
    }
  }, [plan.status]);

  const { mutate: onStatusUpdate, isLoading } = useMutation(async () => {
    const planner = await getPlannerById(plan.plannerId as string);
    if (!planner) {
      showError(`Error in your profile. Please contact support.`);
      return;
    }
    const isProfileCompleted = isProfileComplete(planner);
    if (!isProfileCompleted) {
      showError(`Please complete your profile before publishing the plan.`);
      setShowIncompleteProfileDialog(true);
      return;
    }
    setButtonDisabled(true);
    await updateStatus(plan._id as string, PlanStatusType.InReview).catch((err) => {
      console.error(err);
      showError(`Failed to update status of the plan`);
    });
    refreshPlans();
    setButtonDisabled(false);
  });

  if (showIncompleteProfileDialog) {
    return (
      <IncompleteProfileDialog
        open={showIncompleteProfileDialog}
        onCancel={() => {
          setShowIncompleteProfileDialog(false);
          setShowStatusUpdateConfirmation(false);
        }}
      />
    );
  }
  return (
    <>
      <Tooltip title={info}>
        <span>
          <Button
            disabled={buttonDisabled}
            onClick={() => {
              if (plan.status === PlanStatusType.Published) return;
              if (plan.status === PlanStatusType.InReview) return;
              setShowStatusUpdateConfirmation(true);
            }}
            size="small"
            sx={
              variant === 'outlined'
                ? {
                    borderColor: 'primary.main',
                    borderRadius: '30px',
                    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                    fontWeight: 'var(--weight-R)',
                    padding: '6px 42px',
                  }
                : null
            }
            variant={variant}
          >
            {label}
          </Button>
        </span>
      </Tooltip>

      {/* show confirmation dialog for status update */}
      <ConfirmModal
        cancelText="Close"
        confirmProps={{ disabled: isLoading, startIcon: isLoading && <LoaderCircle /> }}
        confirmText="Confirm"
        onCancel={() => setShowStatusUpdateConfirmation(false)}
        onConfirm={onStatusUpdate}
        open={showStatusUpdateConfirmation}
        title="Confirmation!!"
      >
        {message}
      </ConfirmModal>
    </>
  );
}
