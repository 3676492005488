import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { Center, LoaderCircle } from '@zipptrip/zipptrip-canvas';
import PropTypes from 'prop-types';

import styles from './LoadingSpinner.styles';

const useStyles = makeStyles(styles as any);
export type Prop = {
  size?: string | number;
  space?: string | number;
  spaceBottom?: string | number;
  spaceTop?: string | number;
  text?: string;
};

const LoadingSpinner = ({ size, space, spaceBottom, text }: Prop) => {
  let spinnerSize = 50;
  let spaceTop = space;

  if (size === 'lg') {
    spinnerSize = 80;
    spaceTop = '8rem';
  } else if (size === 'md') {
    spinnerSize = 50;
    spaceTop = '6rem';
  } else if (size === 'sm') {
    spinnerSize = 30;
    spaceTop = '3rem';
  } else if (typeof size === 'number') {
    spinnerSize = size;
  }

  if (space !== undefined || space !== null || space !== '') {
    spaceTop = space;
  }

  const classes = useStyles({ size, spaceTop, spaceBottom });

  return (
    <Center height={spinnerSize || 10}>
      <Box className={classes.root}>
        <Box className={classes.wrapper}>
          <LoaderCircle sx={{ transform: `scale(${spinnerSize / 24})` }} />
        </Box>
        {text && <Typography variant="body1">{text}</Typography>}
      </Box>
    </Center>
  );
};

LoadingSpinner.defaultProps = {
  size: 'lg',
  space: 80,
};

LoadingSpinner.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  space: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  spaceBottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default LoadingSpinner;
