import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';

// component to show dialog for unapproved accounts
const UserBlockedDialog = ({ handleClose }: { handleClose?: () => void }) => {
  return (
    <Dialog
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Pending Approval'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Hang Tight! Your Account is Pending Approval.
          <br />
          <br />
          We've received your request to join our ZippTrip creator community, and we're currently
          reviewing your account. This process can take a bit of time, but we promise we're working
          on it.
          <br />
          <br />
          In the meantime, feel free to reach out to us if you have any questions or need
          assistance. You can contact our friendly administrator or drop us an email at{' '}
          <a href="mailto:hello@zipptrip.com">hello@zipptrip.com</a>. We're always here to help!
          <br />
          <br />
          Once your account is approved, you'll be all set to start creating and sharing your
          fantastic itineraries with the world. We're excited to see what you'll bring to our
          community!
        </DialogContentText>
      </DialogContent>
      {handleClose && (
        <DialogActions>
          <Button onClick={handleClose} color="error">
            Cancel
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default UserBlockedDialog;
