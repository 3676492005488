import { auth } from '../config/firebase';

export function a11yProps(index: number) {
  return {
    id: `${Math.random()}tab-${index}`,
    'aria-controls': `${Math.random()}tabpanel-${index}`,
  };
}

export function getTimeString(number: number) {
  let suffix = 'AM';
  let hour = parseInt((number / 60).toString());
  const minute = number - hour * 60;
  if (hour >= 12) {
    hour = hour - 12;
    suffix = 'PM';
  }
  if (hour === 0) {
    hour = 12;
  }
  const hourString = `${hour}`.length === 1 ? `0${hour}` : `${hour}`;
  const minString = `${minute}`.length === 1 ? `0${minute}` : `${minute}`;
  return `${hourString}:${minString} ${suffix}`;
}

export function getTimeValue(timeString: string) {
  const [time, suffix] = timeString.split(' ');
  const [hour, minute] = time.split(':');
  let hourValue = parseInt(hour);
  if (suffix === 'PM') {
    hourValue = hourValue + 12;
  }
  return hourValue * 60 + parseInt(minute);
}

export function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export async function getRequestHeader() {
  const token = await auth.currentUser?.getIdToken();
  return { headers: { Authorization: `Bearer ${token}` } };
}
