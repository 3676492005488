import { getEnv } from '../utils/env';

export const firebaseConfig = {
  apiKey: getEnv('FirebaseApiKey'),
  authDomain: getEnv('FirebaseAuthDomain'),
  projectId: getEnv('FirebaseProjectId'),
  storageBucket: getEnv('FirebaseStorageBucket'),
  messagingSenderId: getEnv('FirebaseMessagingSenderId'),
  appId: getEnv('FirebaseAppId'),
  measurementId: getEnv('FirebaseMeasurementId'),
};
