import { Facebook, Instagram, Link, Pinterest, Twitter, YouTube } from '@mui/icons-material';
import { Box, InputAdornment, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormTextField, useInputShrink } from '@zipptrip/zipptrip-canvas';
import { IPlanner } from '@zipptrip/zipptrip-commons';
import { useFormikContext } from 'formik';

import styles from './Profile.styles';
import TiktokIcon from '../icons/tiktok';

const useStyles = makeStyles(styles);

const socialMedia = [
  { key: 'youtubeIcon', Icon: YouTube, label: 'Youtube channel link', subField: 'youtubeLink' },
  { key: 'facebookIcon', Icon: Facebook, label: 'Facebook page link', subField: 'facebookLink' },
  {
    key: 'instagramIcon',
    Icon: Instagram,
    label: 'Instagram profile link',
    subField: 'instagramLink',
  },
  {
    key: 'tiktokLink',
    Icon: TiktokIcon,
    label: 'Tiktok profile link',
    subField: 'tiktokLink',
  },
  { key: 'twitterIcon', Icon: Twitter, label: 'Twitter profile link', subField: 'twitterLink' },
  {
    key: 'pinterestIcon',
    Icon: Pinterest,
    label: 'Pinterest profile link',
    subField: 'pinterestLink',
  },
  {
    key: 'mediaKitIcon',
    Icon: Link,
    label: 'Drive URL if media set is available',
    subField: 'mediaKitLink',
  },
] as const;

export default function ProfileSocialInfo() {
  const classes = useStyles() as any;
  const { errors, values } = useFormikContext<IPlanner>();
  const { textFieldProps } = useInputShrink(values);

  return (
    <Box>
      <Typography variant="T16M">Social Media Info</Typography>

      {socialMedia.map(({ key, Icon, label, subField }) => (
        <FormTextField
          key={key}
          error={!!(errors.socialMedia as any)?.[subField]}
          field={`socialMedia.${subField}`}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon className={`${classes.socialMediaIcon} ${classes[key]}`} />
              </InputAdornment>
            ),
          }}
          label={label}
          margin="normal"
          shrinkProps={textFieldProps}
        />
      ))}
    </Box>
  );
}
