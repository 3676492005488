import { useEffect, useState } from 'react';

import { Button, Checkbox, Divider, FormControlLabel, Grid, Typography } from '@mui/material';
import {
  FormTextField,
  LoaderCircle,
  VerticalBlock,
  useNotifications,
} from '@zipptrip/zipptrip-canvas';
import { IPlannerBase } from '@zipptrip/zipptrip-commons';
import { Form, Formik } from 'formik';
import { StepWizardChildProps } from 'react-step-wizard';

import AlreadyRegistered from './AlreadyRegistered';
import Stepper from './Stepper';
import { getPlannerBaseStep1ValidationSchema } from './utils';
import { REVENUE_AGREEMENT_ROUTE, TERMS_AND_CONDITIONS_ROUTE } from '../../constants/routes';
import { getPlannerByEmail } from '../../utils/planner';
import Logo from '../logo/Logo';

type StepProps = {
  formValues: Partial<IPlannerBase>;
  setFormValues: React.Dispatch<React.SetStateAction<IPlannerBase>>;
  isAgency: boolean;
  setIsAgency: React.Dispatch<React.SetStateAction<boolean>>;
} & Partial<StepWizardChildProps>;

const SignupName = ({ formValues, setFormValues, isAgency, setIsAgency, goToStep }: StepProps) => {
  const { showError } = useNotifications();
  const [joinCreatorCommunity, setJoinCreatorCommunity] = useState(false);
  const [joinReferralProgram, setJoinReferralProgram] = useState(isAgency);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setIsAgency(!!joinReferralProgram && !joinCreatorCommunity);
  }, [joinReferralProgram, joinCreatorCommunity]);

  return (
    <VerticalBlock sx={{ gap: '24px', '& > form': { width: { xs: '100%', sm: '77%' } } }}>
      <Logo />

      <Typography variant="T28B" sx={{ letterSpacing: '1px', textAlign: 'center' }}>
        Welcome to the community!
      </Typography>
      <Typography variant="T16R" sx={{ textAlign: 'center' }}>
        Just a little more! Let's get to know each other a little bit 👋
      </Typography>

      <Stepper total={2} currentId={1} />

      <Formik
        initialValues={formValues}
        validationSchema={getPlannerBaseStep1ValidationSchema()}
        validate={(values) => {
          const { email = '' } = values || {};
          if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            showError('Please enter a valid email');
            setDisabled(true);
          } else {
            setDisabled(false);
          }
        }}
        validateOnChange={false}
        onSubmit={async (values, { setSubmitting }) => {
          setFormValues(values as any);
          const { email = '' } = values;
          if (!values.termsAccepted) {
            showError('To register, you have to accept the terms and conditions');
            return;
          }
          //validate email
          let existingUser = null;
          try {
            existingUser = await getPlannerByEmail(email);
          } catch (error) {
            console.error(error, 'getPlannerByEmail');
            showError('Unexpected error. Please try again.');
            return null;
          }
          if (existingUser === null) {
            return;
          }
          if (existingUser) {
            showError('Email is already in use');
            return;
          }
          if (!disabled) {
            goToStep ? goToStep(2) : null;
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, handleSubmit, setFieldValue, values }) => {
          return (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <VerticalBlock sx={{ gap: '16px' }}>
                <FormTextField field="name" placeholder="Your name" />
                <FormTextField field="email" placeholder="Email" />

                <Grid container spacing={2} marginBottom={3}>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!joinCreatorCommunity}
                          onChange={(event) => setJoinCreatorCommunity(event.target.checked)}
                          name="inappropriateForChildren"
                          value={joinCreatorCommunity}
                          color="primary"
                        />
                      }
                      label="Become a Creator"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!joinReferralProgram || !!joinCreatorCommunity}
                          onChange={(event) => setJoinReferralProgram(event.target.checked)}
                          name="inappropriateForChildren"
                          value={joinReferralProgram}
                          color="primary"
                        />
                      }
                      label="Refer & Earn"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!values.termsAccepted}
                          onChange={(event) => setFieldValue('termsAccepted', event.target.checked)}
                          name="inappropriateForChildren"
                          value={values.termsAccepted}
                          color="primary"
                        />
                      }
                      label={
                        <>
                          By proceeding, you acknowledge and accept our{' '}
                          <a href={TERMS_AND_CONDITIONS_ROUTE} target="_blank">
                            Terms of Services
                          </a>{' '}
                          and{' '}
                          <a href={REVENUE_AGREEMENT_ROUTE} target="_blank">
                            Revenue Sharing Agreement
                          </a>
                          .
                        </>
                      }
                      sx={{ alignItems: 'flex-start' }}
                    />
                  </Grid>
                </Grid>

                <Typography sx={{ fontSize: '12px', color: '#7D8E8F', margin: '5px 0' }}>
                  Feedback will be sent to this email address. Please enter an email that you find
                  convenient
                </Typography>

                <Button
                  color="primary"
                  disabled={
                    isSubmitting ||
                    !values.termsAccepted ||
                    (!joinReferralProgram && !joinCreatorCommunity)
                  }
                  size="large"
                  startIcon={isSubmitting && <LoaderCircle />}
                  sx={{ width: '100%', margin: '8px' }}
                  type="submit"
                  variant="contained"
                >
                  Next
                </Button>
              </VerticalBlock>
            </Form>
          );
        }}
      </Formik>
      <Divider flexItem />
      <AlreadyRegistered />
    </VerticalBlock>
  );
};

export default SignupName;
