import { Card, Chart, Crown, InfoCircle, Note, Profile2User, ProfileCircle } from 'iconsax-react';

import {
  CREATOR_LEVEL_ROUTE,
  GUIDELINE_ROUTE,
  HOME_ROUTE,
  PAYOUT_ROUTE,
  PLANS_ROUTE,
  PROFILE_ROUTE,
  REFERRAL_ROUTE,
} from '../../constants/routes';

export const navItems = [
  {
    name: 'Dashboard',
    path: HOME_ROUTE,
    icon: Chart,
    hideForAgency: false,
  },
  {
    name: 'Plans',
    path: PLANS_ROUTE,
    icon: Note,
    hideForAgency: true,
  },
  {
    name: 'Profile',
    path: PROFILE_ROUTE,
    icon: ProfileCircle,
    hideForAgency: true,
  },
  {
    name: 'Payout',
    path: PAYOUT_ROUTE,
    icon: Card,
    hideForAgency: false,
  },
  {
    name: 'Referrals',
    path: REFERRAL_ROUTE,
    icon: Profile2User,
    hideForAgency: false,
  },
  {
    name: 'Creator level',
    path: CREATOR_LEVEL_ROUTE,
    icon: Crown,
    hideForAgency: true,
  },
  {
    name: 'Guidelines',
    path: GUIDELINE_ROUTE,
    icon: InfoCircle,
    hideForAgency: true,
  },
];
