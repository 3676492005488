import { useState } from 'react';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  SpeedDial,
  SpeedDialAction,
  Typography,
} from '@mui/material';
import { ConfirmModal, LoaderCircle } from '@zipptrip/zipptrip-canvas';
import { COUNTRIES, IPlan, PlanStatusType } from '@zipptrip/zipptrip-commons';
import { Back, DocumentCopy, Edit2, ExportSquare, More, Trash } from 'iconsax-react';
import moment from 'moment';
import { StringParam, useQueryParam } from 'use-query-params';

import { sendGAEvent } from '../../config/firebase';
import { DEFAULT_IMG_SRC, ZIPPTRIP_URL_ROOT } from '../../constants/commons';
import { getImageUrl } from '../../utils/images';
import { deletePlan, duplicatePlan, updateStatus } from '../../utils/plan';

type Prop = { plan: IPlan; refreshPlans: () => void };

const PlanCard = ({ plan, refreshPlans }: Prop) => {
  const [isLoading, setIsLoading] = useState(false);
  const [speedDialOpen, setSpeedDialOpen] = useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [showDuplicateDialog, setShowDuplicateDialog] = useState<boolean>(false);
  const [showReturnDialog, setShowReturnDialog] = useState<boolean>(false);
  const [selectedPlanId, setSelectedPlanId] = useQueryParam('create-edit-plan', StringParam);
  const isEditing = selectedPlanId === plan._id;

  const onPlanEdit = () => {
    setSelectedPlanId(plan._id as string);
    sendGAEvent('open:edit-plan-modal');
  };

  const onPlanReturnToDraft = async () => {
    setIsLoading(true);
    await updateStatus(plan._id as string, PlanStatusType.Draft);
    refreshPlans();
    setShowReturnDialog(false);
    setIsLoading(false);
  };

  const onPlanPreview = () => {
    const publicUrl = `${ZIPPTRIP_URL_ROOT}/${plan.title
      .toLocaleLowerCase()
      .replace(/ /g, '-')
      .replace('/', '-')}-${plan._id}`;
    window.open(publicUrl, '_blank');
  };

  const onPlanDuplicate = async () => {
    setIsLoading(true);
    await duplicatePlan(plan._id as string);
    refreshPlans();
    setShowDuplicateDialog(false);
    setIsLoading(false);
  };

  const onPlanDelete = async () => {
    setIsLoading(true);
    await deletePlan(plan._id as string);
    refreshPlans();
    setShowDeleteDialog(false);
    setIsLoading(false);
  };

  return (
    <>
      <Card
        sx={(theme) => ({
          borderRadius: '24px',
          boxShadow: '0px 6px 20px 0px rgba(0, 0, 0, 0.12)',
          display: 'flex',
          flexDirection: 'column' as const,
          position: 'relative' as const,
          [theme.breakpoints.down('sm')]: { height: 'auto' }, // 1:1
        })}
      >
        <CardMedia
          image={getImageUrl(plan.photos?.[0] || DEFAULT_IMG_SRC)}
          sx={(theme) => ({
            height: 0,
            paddingTop: '56.25%', // 4:3,
            [theme.breakpoints.down('xl')]: { paddingTop: '80%' }, // 5:4
            [theme.breakpoints.down('md')]: { paddingTop: '66.66%' }, // 3:2
            [theme.breakpoints.down('sm')]: { paddingTop: '50%' }, // 1:1
          })}
          title={plan.title}
        />
        <CardContent
          sx={{ display: 'flex', flexFlow: 'column', gap: '16px', padding: '16px !important' }}
        >
          <Box>
            <Typography
              variant="T16M"
              sx={{
                display: '-webkit-box',
                marginBottom: '4px',
                overflow: 'hidden',
                WebkitLineClamp: 1,
                WebkitBoxOrient: 'vertical',
              }}
            >
              {plan.title}
            </Typography>

            <Typography
              color="textSecondary"
              sx={{ '& > span': { margin: '0 8px' } }}
              variant="T14R"
            >
              {COUNTRIES.find((item) => item.code === plan.countries[0])?.name}
              <span>•</span>
              {plan.duration} {plan.duration === 1 ? 'day' : 'days'}
            </Typography>
          </Box>

          <Box>
            <Typography variant="T12R" color="textSecondary" sx={{ marginBottom: '4px' }}>
              <i>Created at {moment(plan.createdAt).format('DD MMM YYYY')}</i>
            </Typography>
            <Typography variant="T12R" color="textSecondary">
              <i>Updated at {moment(plan.updatedAt).format('DD MMM YYYY')}</i>
            </Typography>
          </Box>

          {plan.status === PlanStatusType.Draft && (
            <Button
              disabled={isEditing}
              onClick={onPlanEdit}
              startIcon={isEditing ? <LoaderCircle /> : <Edit2 size={20} />}
              variant="outlined"
            >
              Edit plan
            </Button>
          )}
          {plan.status === PlanStatusType.Published && (
            <Button
              onClick={onPlanPreview}
              startIcon={<ExportSquare size={20} />}
              variant="outlined"
            >
              Preview in public
            </Button>
          )}

          <Box sx={{ position: 'absolute', top: 12, right: 8 }}>
            <SpeedDial
              ariaLabel="Menu"
              direction="down"
              FabProps={{ color: 'default', size: 'small', variant: 'outlined' }}
              icon={<More size={22} />}
              onClick={() => setSpeedDialOpen((op) => !op)}
              open={speedDialOpen}
            >
              {plan.status === PlanStatusType.Draft ? (
                <SpeedDialAction
                  FabProps={{ color: 'default', size: 'small', variant: 'outlined' }}
                  icon={<Edit2 size={22} />}
                  onClick={onPlanEdit}
                  tooltipTitle="Edit this plan"
                />
              ) : (
                <SpeedDialAction
                  FabProps={{ color: 'default', size: 'small', variant: 'outlined' }}
                  icon={<Back size={22} />}
                  onClick={() => setShowReturnDialog(true)}
                  tooltipTitle="Return this plan to a draft"
                />
              )}
              <SpeedDialAction
                FabProps={{ color: 'default', size: 'small', variant: 'outlined' }}
                icon={<ExportSquare size={22} />}
                onClick={onPlanPreview}
                tooltipTitle="Preview in public mode"
              />
              <SpeedDialAction
                FabProps={{ color: 'default', size: 'small', variant: 'outlined' }}
                icon={<DocumentCopy size={22} />}
                onClick={() => setShowDuplicateDialog(true)}
                tooltipTitle="Duplicate this plan"
              />
              {/* <SpeedDialAction
                FabProps={{ color: 'default', size: 'small', variant: 'outlined' }}
                icon={<Chart1 size={22} />}
                onClick={(e) => e.stopPropagation()}
                tooltipTitle={
                  <Box sx={{ '& > *': { color: 'var(--neutral-1)' } }}>
                    <Typography variant="body1">{plan.viewCount || 0} Clicked</Typography>
                    <Typography variant="body1">{plan.soldCount || 0} Purchased</Typography>
                  </Box>
                }
              /> */}
              {plan.status === PlanStatusType.Draft && (
                <SpeedDialAction
                  FabProps={{ size: 'small', sx: { color: 'error.main' }, variant: 'outlined' }}
                  icon={<Trash size={22} />}
                  onClick={() => setShowDeleteDialog(true)}
                  tooltipTitle="Delete this plan"
                />
              )}
            </SpeedDial>
          </Box>
        </CardContent>
      </Card>

      {/* show confirmation dialog to return a plan to a draft */}
      <ConfirmModal
        cancelText="Cancel"
        confirmProps={{ disabled: isLoading, startIcon: isLoading && <LoaderCircle /> }}
        confirmText="Return"
        onCancel={() => setShowReturnDialog(false)}
        onConfirm={onPlanReturnToDraft}
        open={showReturnDialog}
        title="Return this plan to a draft?"
      >
        Are you sure you want to return <b>{plan.title}</b> to a draft?
      </ConfirmModal>

      {/* show confirmation dialog to duplicate a plan */}
      <ConfirmModal
        cancelText="Close"
        confirmProps={{ disabled: isLoading }}
        confirmText="Duplicate"
        onCancel={() => setShowDuplicateDialog(false)}
        onConfirm={onPlanDuplicate}
        open={showDuplicateDialog}
        title="Duplicate plan?"
      >
        Are you sure you want to duplicate <b>{plan.title}</b>?
      </ConfirmModal>

      {/* show confirmation dialog to delete a plan */}
      <ConfirmModal
        cancelText="Cancel"
        confirmProps={{ color: 'error', disabled: isLoading }}
        confirmText="Delete"
        onCancel={() => setShowDeleteDialog(false)}
        onConfirm={onPlanDelete}
        open={showDeleteDialog}
        title="Delete plan?"
      >
        Are you sure you want to delete <b>{plan.title}</b>?
      </ConfirmModal>
    </>
  );
};

export default PlanCard;
