const TiktokIcon = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 512 512"
      preserveAspectRatio="xMidYMid meet"
    >
      <metadata>Created by potrace 1.16, written by Peter Selinger 2001-2019</metadata>
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path d="M2787 4643 c-4 -3 -7 -720 -7 -1593 0 -1547 -1 -1589 -20 -1664 -46 -181 -177 -344 -340 -423 -105 -51 -172 -67 -275 -67 -102 0 -169 16 -275 66 -69 33 -100 56 -165 122 -81 81 -154 198 -161 255 -2 14 -10 55 -18 91 -94 399 247 805 653 777 47 -4 98 -9 114 -13 l27 -6 0 415 0 415 -31 6 c-47 9 -197 7 -203 -3 -3 -4 -42 -11 -88 -15 -102 -8 -251 -40 -343 -73 -811 -291 -1183 -1222 -796 -1992 23 -45 39 -81 37 -81 -3 0 2 -17 10 -38 40 -95 219 -295 358 -399 422 -314 986 -371 1465 -147 239 112 450 297 600 529 95 145 162 311 203 504 20 92 21 131 25 968 l4 871 90 -63 c104 -73 278 -162 393 -199 168 -55 396 -86 530 -71 l66 7 0 372 0 372 -127 13 c-267 27 -485 136 -673 338 -69 74 -162 208 -166 241 -1 9 -18 58 -38 107 -38 99 -76 267 -76 339 l0 46 -383 0 c-211 0 -387 -3 -390 -7z" />
      </g>
    </svg>
  );
};
export default TiktokIcon;
