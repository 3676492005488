import { useEffect, useState } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { HorizontalBlock } from '@zipptrip/zipptrip-canvas';
import { IPlanner, isProfileComplete } from '@zipptrip/zipptrip-commons';
import { useNavigate } from 'react-router-dom';

import { auth } from '../../config/firebase';
import { PROFILE_ROUTE } from '../../constants/routes';
import { getPlannerById } from '../../utils/planner';

export default function PlanWarning() {
  const navigate = useNavigate();
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    const handleShowWarning = async () => {
      const planner = await getPlannerById(auth.currentUser?.uid as string);
      setShowWarning(!isProfileComplete(planner as IPlanner));
    };
    handleShowWarning();
  }, []);

  if (!showWarning) return <></>;

  return (
    <HorizontalBlock
      sx={{
        bgcolor: 'primary.selectedBackground',
        borderRadius: '24px',
        gap: '24px',
        padding: '24px',
      }}
    >
      <Box>
        <Typography sx={{ marginBottom: '12px' }} variant="T16S">
          Update profile information
        </Typography>
        <Typography variant="T14R">
          Your profile will look outstanding and attract more tourists when you fully update your
          personal information. It doesn't take much time! Start creating your own style now.
        </Typography>
      </Box>

      <Button onClick={() => navigate(PROFILE_ROUTE)} sx={{ flexShrink: 0 }} variant="outlined">
        Update profile
      </Button>
    </HorizontalBlock>
  );
}
