import {
  AppBar,
  Box,
  Button,
  Portal,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { HorizontalBlock } from '@zipptrip/zipptrip-canvas';

import { ZIPPTRIP_URL_ROOT } from '../../constants/commons';
import Logo from '../logo/Logo';

const TopBar = ({ setIsOpenSignupModal, setIsOpenLoginModal }: any) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  return (
    <>
      <AppBar
        position="fixed"
        sx={(theme) => ({
          bgcolor: 'text.background',
          borderBottom: '1px solid',
          borderColor: 'text.border',
          boxShadow: 0,
          zIndex: theme.zIndex.drawer + 1,
          [theme.breakpoints.down('sm')]: { display: 'none' },
        })}
      >
        <Toolbar
          sx={(theme) => ({
            ...theme.mixins.toolbar,
            minHeight: '80px !important',
            padding: '0 80px !important',
          })}
        >
          <Logo />
          <Typography sx={{ ml: 2, flex: 1 }} />

          <Portal
            container={document.getElementById('landing-bottom-bar')}
            disablePortal={isDesktop}
          >
            <HorizontalBlock
              sx={{
                flexFlow: { xs: 'column-reverse', sm: 'row' },
                gap: '8px',
                width: { xs: '100%', sm: 'auto' },
                '& > button': { width: { xs: '100%', sm: 'auto' } },
              }}
            >
              <Button onClick={() => window.open(ZIPPTRIP_URL_ROOT, '_blank')} size="small">
                Go to ZippTrip
              </Button>
              <Button
                onClick={() => setIsOpenLoginModal(true)}
                sx={{
                  borderColor: 'var(--neutral-9) !important',
                  fontSize: '16px',
                  padding: '8px 16px',
                }}
                variant="outlined"
              >
                Log in
              </Button>
              <Button
                color="primary"
                onClick={() => setIsOpenSignupModal(true)}
                size="small"
                variant="contained"
              >
                Join as Creator
              </Button>
            </HorizontalBlock>
          </Portal>
        </Toolbar>
      </AppBar>

      <Box sx={{ display: { xs: 'none', sm: 'block' }, height: '80px' }} />
    </>
  );
};

export default TopBar;
