import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
  useTheme,
} from '@mui/material';
import { UserType } from '@zipptrip/zipptrip-commons';
import { Add, Global } from 'iconsax-react';
import { useNavigate } from 'react-router-dom';

import { navItems } from './navItems';
import UserMenu from './UserMenu';
import { auth, sendGAEvent } from '../../config/firebase';
import { ZIPPTRIP_URL_ROOT } from '../../constants/commons';
import { PLANS_ROUTE } from '../../constants/routes';
import { getCustomToken } from '../../utils/planner';
import Logo from '../logo/Logo';
import ResponsiveDrawer from '../utils/ResponsiveDrawer';

const NavBarInner = ({
  toggleDrawer,
  userType,
}: {
  toggleDrawer: () => void;
  userType: UserType;
}) => {
  const navigate = useNavigate();
  const { breakpoints, palette } = useTheme();
  const { text } = palette;

  const onSwitchToZipptrip = async () => {
    sendGAEvent('event:switch-to-zipptrip');
    const token = await getCustomToken();
    window.open(`${ZIPPTRIP_URL_ROOT}?token=${token}`, '_blank');
  };

  return (
    <Box
      sx={{ display: 'flex', flexFlow: 'column', height: '100%', gap: '32px', padding: '16px 0' }}
    >
      <Box display="flex">
        <Logo />
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <List sx={{ padding: 0 }}>
          {navItems
            .filter(({ hideForAgency }) => {
              if (userType === UserType.Agency && hideForAgency) return false;
              return true;
            })
            .map(({ name, path, icon: Icon }) => {
              const selected = path === location.pathname;
              const color = selected ? text.primary : text.secondary;

              return (
                <ListItem key={name} id={`navItem-${name}`}>
                  <ListItemButton
                    onClick={() => {
                      navigate(path);
                      if (breakpoints.down('sm')) toggleDrawer();
                    }}
                    selected={selected}
                    sx={({ palette: { primary } }) => ({
                      ...(selected ? { bgcolor: `${primary.selectedBackground} !important` } : {}),
                    })}
                  >
                    <ListItemIcon>
                      <Icon color={color} size={28} />
                    </ListItemIcon>
                    <Typography sx={{ color }} variant="T14M">
                      {name}
                    </Typography>
                  </ListItemButton>
                </ListItem>
              );
            })}
        </List>

        {userType === UserType.Creator && (
          <Button
            fullWidth
            id="navItem-CreatePlanButton"
            onClick={() => {
              navigate({ pathname: PLANS_ROUTE, search: '?create-edit-plan=1' });
              sendGAEvent('open:create-plan-modal');
            }}
            startIcon={<Add />}
            sx={{ marginTop: '32px' }}
            variant="contained"
          >
            Create plan
          </Button>
        )}
      </Box>

      <Box>
        <Divider />

        <List sx={{ padding: 0 }}>
          <ListItem>
            <ListItemButton onClick={onSwitchToZipptrip}>
              <ListItemIcon>
                <Global color={text.secondary} size={28} />
              </ListItemIcon>
              <Typography sx={{ color: text.secondary }} variant="T14M">
                Switch to Zipptrip
              </Typography>
            </ListItemButton>
          </ListItem>
        </List>
        <UserMenu />
      </Box>
    </Box>
  );
};

export default function NavBar({ userType }: { userType?: UserType }) {
  if (!auth.currentUser) return null;
  if (!userType) return null;
  return (
    <ResponsiveDrawer
      drawerInner={(toggleDrawer) => (
        <NavBarInner toggleDrawer={toggleDrawer} userType={userType} />
      )}
      toolbarChidren={
        <Box
          id="navbar-toolbar"
          sx={{ boxSizing: 'border-box', padding: '0 8px', width: '100%' }}
        />
      }
    />
  );
}
