export const LANDING_ROUTE = '/';
export const HOME_ROUTE = '/dashboard';
export const LOGIN_ROUTE = '/login';
export const TERMS_AND_CONDITIONS_ROUTE = '/terms-and-conditions';
export const REVENUE_AGREEMENT_ROUTE = '/revenue-sharing-agreement';
export const PRIVACY_POLICY_ROUTE = '/privacy-policy';
export const SIGNUP_ROUTE = '/signup';
export const FORGOT_PASSWORD_ROUTE = '/forgot-password';
export const PLANS_ROUTE = '/plans';
export const PROFILE_ROUTE = '/profile';
export const PAYOUT_ROUTE = '/payout';
export const REFERRAL_ROUTE = '/referrals';
export const CREATOR_LEVEL_ROUTE = '/creator-level';
export const GUIDELINE_ROUTE = '/guidelines';

export const LOGO_PATH = '/logos/zipptrip logo.png';
export const ABOUT_US_URL = 'https://zipptrip.com/about-us';
