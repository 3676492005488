import { useState } from 'react';

import { Autocomplete, Box, Button, Divider, Fab, TextField, Typography } from '@mui/material';
import { HorizontalBlock } from '@zipptrip/zipptrip-canvas';
import { COUNTRIES, City, IPlannerVisit } from '@zipptrip/zipptrip-commons';
import { useFormikContext } from 'formik';
import { Add, ArrowRight, CloseCircle, Location } from 'iconsax-react';

import { getCountryInfo } from '../../utils/planner';

type VisitBlockInfo = { field: string; title: string };

export default function ProfileVisitInfo() {
  const visitBlocks: VisitBlockInfo[] = [
    { field: 'alreadyVisited', title: 'Destinations already visited' },
    { field: 'planningToVisit', title: 'Destinations planning to visit' },
  ];

  return (
    <>
      {visitBlocks.map(({ field, title }) => (
        <VisitBlock key={title} field={field} title={title} />
      ))}
    </>
  );
}

type CountryOption = { name: string; code: string };

const VisitBlock = ({ field, title }: VisitBlockInfo) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newDestination, setNewDestination] = useState<{ country: string; city: string }>({
    country: '',
    city: '',
  });
  const [cities, setCities] = useState<City[]>([]);

  const { setFieldValue, values } = useFormikContext<any>();
  const destinations = (values[field] || []) as IPlannerVisit[];
  const handleSetNewDestination = () => {
    setFieldValue(field, [...destinations, newDestination]);
    setIsEditing(false);
    setNewDestination({ country: '', city: '' });
  };

  return (
    <Box sx={{ display: 'flex', flexFlow: 'column', gap: '24px' }}>
      <Typography variant="T16M">{title}</Typography>

      <HorizontalBlock sx={{ flexWrap: 'wrap', gap: '16px', justifyContent: 'flex-start' }}>
        {destinations?.map(({ country, city }, idx) => {
          const destination = `${city}, ${COUNTRIES.find(({ code }) => code === country)?.name}`;
          return (
            <VisitedDestination
              key={destination}
              destination={destination}
              field={field}
              idx={idx}
            />
          );
        })}

        {isEditing && (
          <HorizontalBlock
            sx={{
              border: '1px solid var(--neutral-2)',
              borderRadius: '40px',
              paddingLeft: '24px',
              paddingRight: '8px',
              position: 'relative',
              width: '100%',
            }}
          >
            <Box
              sx={{
                left: '16px',
                top: '16px',
                position: 'absolute',
                '& > svg': { color: 'var(--secondary-6)' },
              }}
            >
              <Location />
            </Box>

            <Autocomplete
              getOptionLabel={(option: CountryOption) => option?.name || ''}
              isOptionEqualToValue={(option: CountryOption, value: CountryOption) =>
                option?.code === value?.code
              }
              onChange={(event, value: CountryOption | null) => {
                setNewDestination({ country: value?.code || '', city: '' });
                getCountryInfo(value?.code || '').then((res) => setCities(res?.cities || []));
              }}
              options={COUNTRIES}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{ ...params.InputProps, sx: { border: 'none' } }}
                  label="Country"
                />
              )}
              sx={{ flexGrow: '1' }}
              value={COUNTRIES.find((country) => country.code === newDestination.country)}
            />

            <Divider flexItem orientation="vertical" sx={{ height: '24px', margin: 'auto' }} />

            <Autocomplete
              filterOptions={(options: City[], state: any) =>
                options.filter((option) =>
                  option.name.toLowerCase().includes(state.inputValue.toLowerCase()),
                )
              }
              filterSelectedOptions
              getOptionLabel={(option: City) =>
                `${option.name} ${option.state ? ` (${option.state})` : ''}`
              }
              isOptionEqualToValue={(option: City, value: City) => option?.name === value?.name}
              onChange={(event, value: City | null) => {
                setNewDestination((d) => ({ ...d, city: value?.name || '' }));
              }}
              options={cities}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{ ...params.InputProps, sx: { border: 'none' } }}
                  label="City"
                />
              )}
              sx={{ flexGrow: '1' }}
              value={cities.find((c) => c.name === newDestination.city)}
            />

            <Fab
              color="inherit"
              disabled={!newDestination?.city}
              onClick={handleSetNewDestination}
              size="small"
            >
              <ArrowRight />
            </Fab>
          </HorizontalBlock>
        )}
        <Button
          color={isEditing ? 'error' : 'inherit'}
          onClick={() => setIsEditing((e) => !e)}
          startIcon={!isEditing && <Add size={16} />}
          sx={{ padding: '8px 0' }}
        >
          {isEditing ? 'Cancel' : 'Add a destination'}
        </Button>
      </HorizontalBlock>
    </Box>
  );
};

const VisitedDestination = ({
  destination,
  field,
  idx,
}: {
  destination: string;
  field: string;
  idx: number;
}) => {
  const { setFieldValue, values } = useFormikContext<any>();
  const handleDelete = () => {
    const newValue = JSON.parse(JSON.stringify(values[field] || []));
    newValue.splice(idx, 1);
    setFieldValue(field, newValue);
  };

  return (
    <HorizontalBlock
      sx={{ bgcolor: 'primary.selectedBackground', borderRadius: '40px', padding: '16px 24px' }}
    >
      <Location />

      <Typography sx={{ margin: '0px 12px 0 8px' }} variant="T16R">
        {destination}
      </Typography>

      <Box onClick={handleDelete} sx={{ cursor: 'pointer', display: 'flex' }}>
        <CloseCircle />
      </Box>
    </HorizontalBlock>
  );
};
