import { Typography } from '@mui/material';
import { VerticalBlock } from '@zipptrip/zipptrip-canvas';

export default function EventNotFound() {
  return (
    <VerticalBlock gap="8px">
      <img src="/event_not_found.svg" />
      <Typography variant="T16M">No matching events found</Typography>
    </VerticalBlock>
  );
}
