import {
  AchievementType,
  Country,
  FirestoreCollection,
  IAchievementItem,
  IInternalAccount,
  IPlanner,
  IPlannerInfo,
  IPlannerPayoutAccount,
  IWithdraw,
} from '@zipptrip/zipptrip-commons';
import axios from 'axios';
import {
  collection,
  doc,
  DocumentReference,
  getDoc,
  getDocs,
  limit,
  Query,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';

import { getRequestHeader } from './commons';
import { getEnv } from './env';
import { Firestore } from '../config/firebase';
import { PlannerSummary, ReferralSummary } from '../types/planner';

const getPlannerRef = (uid: string) =>
  doc(Firestore, FirestoreCollection.Planner, uid) as DocumentReference<IPlanner>;

export const getPlannerById = async (uid: string) => {
  const plannerRef = getPlannerRef(uid);
  const document = await getDoc(plannerRef);
  return document?.data();
};

export const createOrUpdatePlanner = async (uid: string, data: Partial<IPlanner>) => {
  // trim all string values
  data = Object.fromEntries(
    Object.entries(data).map(([key, value]) => [
      key,
      typeof value === 'string' ? value.trim() : value,
    ]),
  );
  const plannerRef = getPlannerRef(uid);
  await setDoc(plannerRef, data, { merge: true });
};

export const uploadPlannerImage = async (
  src: string,
  plannerId?: string,
  existingImgId?: string,
) => {
  const url = `${getEnv('BaseUrl')}/creator/image`;
  const header = await getRequestHeader();
  const res = (await axios.post(url, { src, plannerId, existingImgId }, header)).data;
  return res.data as string;
};

export const getPlannerByEmail = async (email: string) => {
  const customQuery = query(
    collection(Firestore, FirestoreCollection.Planner),
    where('email', '==', email),
    limit(1),
  ) as Query<IPlanner>;
  const querySnapshot = await getDocs(customQuery);
  if (querySnapshot.empty) return undefined;
  const planner = querySnapshot.docs[0]?.data();
  return planner;
};

export const getOverallSummary = async () => {
  const url = `${getEnv('BaseUrl')}/creator/dashboard`;
  const header = await getRequestHeader();
  const res = (await axios.get(url, header)).data;
  return res.data as PlannerSummary;
};

export const getPayouts = async (): Promise<IWithdraw[]> => {
  const url = getEnv('BaseUrl') + '/withdraw';
  const header = await getRequestHeader();
  const res = (await axios.get(url, header)).data;
  return res.data as IWithdraw[];
};

export const submitPayout = async (data: IWithdraw) => {
  const url = getEnv('BaseUrl') + '/withdraw';
  const header = await getRequestHeader();
  await axios.post(url, data, header);
};

export const getCountryInfo = async (code: string): Promise<Country | undefined> => {
  if (!code) return undefined;
  const ref = doc(Firestore, FirestoreCollection.Cities, code) as DocumentReference<Country>;
  const document = await getDoc(ref);
  return document?.data();
};

// For referral program:
const plannerIdRegex = new RegExp('^[0-9a-zA-Z]{27}[0-9]$');

export const getReferrer = async () => {
  const ref = window.localStorage.getItem('ref') || '';
  if (plannerIdRegex.test(ref)) {
    const referrer = await getPlannerById(ref);
    if (!referrer) return undefined;

    const { name, uid } = referrer;
    return { name, uid } as IPlannerInfo;
  }
  return undefined;
};

export const setReferrer = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const ref = searchParams.get('ref') || '';
  if (plannerIdRegex.test(ref)) {
    window.localStorage.setItem('ref', ref);
  }
};

export const getReferrerSummary = async () => {
  const url = `${getEnv('BaseUrl')}/creator/referral`;
  const header = await getRequestHeader();
  const res = (await axios.get(url, header)).data;
  return res.data as ReferralSummary[];
};

export const updatePassword = async (password: string) => {
  const url = `${getEnv('BaseUrl')}/creator/update-password`;
  const header = await getRequestHeader();
  await axios.put(url, { password }, header);
};

export const getCustomToken = async () => {
  const url = `${getEnv('BaseUrl')}/creator/custom-token`;
  const header = await getRequestHeader();
  const res = (await axios.get(url, header)).data;
  return res.data as string;
};

export const createPayoutAccount = async (country: string) => {
  const url = `${getEnv('BaseUrl')}/creator/payout/account`;
  const header = await getRequestHeader();
  const res = (await axios.post(url, { country }, header)).data;
  return res.data as IPlannerPayoutAccount;
};

export type IPayoutAccountDetails = IPlannerPayoutAccount & {
  details: IInternalAccount | { country: string };
  isCompleted: boolean;
};

export const getPayoutAccountDetails = async () => {
  const url = `${getEnv('BaseUrl')}/creator/payout/account-details`;
  const header = await getRequestHeader();
  const res = (await axios.get(url, header)).data;
  return res.data as IPayoutAccountDetails;
};

export const updateInternalAccount = async (values: Partial<IInternalAccount>) => {
  const url = `${getEnv('BaseUrl')}/creator/payout/internal-account`;
  const header = await getRequestHeader();
  const res = (await axios.put(url, values, header)).data;
  return res.data as IInternalAccount;
};

export const getStripeAccountLink = async () => {
  const url = `${getEnv('BaseUrl')}/creator/payout/stripe-account-link`;
  const header = await getRequestHeader();
  const res = (await axios.get(url, header)).data;
  return res.data as { url: string };
};

export const getAchievementList = async () => {
  const url = `${getEnv('BaseUrl')}/creator/achievements`;
  const header = await getRequestHeader();
  const res = (await axios.get(url, header)).data;
  return res.data as IAchievementItem[];
};

export const collectAchievement = async (type: AchievementType) => {
  const url = `${getEnv('BaseUrl')}/creator/achievement`;
  const header = await getRequestHeader();
  await axios.post(url, { type }, header);
};

const LAST_ACTIVE_AT_KEY = 'plannerLastActiveAt';
export const updateLastActiveAt = async (uid: string) => {
  const lastActiveAt = Number(localStorage.getItem(LAST_ACTIVE_AT_KEY)) || 0;
  const nowTimestamp = +new Date();

  // 10 mins cooldown:
  if (nowTimestamp - lastActiveAt > 10 * 60 * 1000) {
    const plannerRef = getPlannerRef(uid);
    await updateDoc(plannerRef, { lastActiveAt: nowTimestamp });
    localStorage.setItem(LAST_ACTIVE_AT_KEY, String(nowTimestamp));
  }
};
