import React from 'react';

import { CampaignOutlined as CampaignOutlinedIcon } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { HorizontalBlock, VerticalBlock } from '@zipptrip/zipptrip-canvas';
import { Bank, Note, ProfileCircle } from 'iconsax-react';

type TourStepProps = { id: string; children: React.ReactNode };

const TourStep = ({ id, children }: TourStepProps) => {
  const iconList = [
    <ProfileCircle size={48} />,
    <Bank size={48} />,
    <Note size={48} />,
    <CampaignOutlinedIcon sx={{ fontSize: '48px' }} />,
  ];

  return (
    <VerticalBlock
      sx={{
        flexBasis: { sm: '100%', md: '45%', lg: '20%' },
        alignItems: { sm: 'center', md: 'flex-start' },
        gap: '10px',
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          aspectRatio: '1',
          bgcolor: 'secondary.main',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          padding: '24px',
        }}
      >
        {iconList[parseInt(id, undefined) - 1]}
      </Box>

      <HorizontalBlock sx={{ gap: '10px', alignItems: { sm: 'center', md: 'flex-start' } }}>
        <Typography sx={{ fontSize: { xs: '30px', md: '40px', lg: '56px' }, fontWeight: 600 }}>
          {id}.
        </Typography>

        <Typography sx={{ fontSize: { xs: '20px', md: '22px', lg: '24px' }, maxWidth: '206px' }}>
          {children}
        </Typography>
      </HorizontalBlock>
    </VerticalBlock>
  );
};

export default TourStep;
