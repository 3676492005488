import Compressor from 'compressorjs';

import { DEFAULT_COVER_IMG_SRC, DEFAULT_IMG_SRC, IMG_BASE_URL } from '../constants/commons';
import { ImageVariant } from '../types/image';

export const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export default async function getCroppedImg(
  imageSrc: string,
  pixelCrop: any,
): Promise<string | null> {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  // set canvas size to match the bounding box
  canvas.width = image.width;
  canvas.height = image.height;
  ctx.scale(1, 1);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  const croppedCanvas = document.createElement('canvas');

  const croppedCtx = croppedCanvas.getContext('2d');

  if (!croppedCtx) {
    return null;
  }

  // Set the size of the cropped canvas
  croppedCanvas.width = pixelCrop.width;
  croppedCanvas.height = pixelCrop.height;

  // Draw the cropped image onto the new canvas
  croppedCtx.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height,
  );

  // As Base64 string
  return croppedCanvas.toDataURL('image/jpeg');
}

export const compressImage = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      maxWidth: 2400,
      maxHeight: 2400,
      quality: 1,
      async success(result) {
        const buffer = await result.arrayBuffer();
        const bytes = new Uint8Array(buffer);
        let binary = '';
        bytes.forEach((byte) => (binary += String.fromCharCode(byte)));

        const base64 = btoa(binary);
        resolve(`data:${result.type};base64,${base64}`);
      },
      error(err) {
        reject(err);
      },
    });
  });
};

export const getImageUrl = (src: string, size?: ImageVariant) => {
  if (!src) return '';
  if (src === DEFAULT_COVER_IMG_SRC) return src;
  if (src === DEFAULT_IMG_SRC) return src;
  if (src.startsWith('data:')) return src;
  return `${IMG_BASE_URL}/${src}/${size || ImageVariant.MEDIUM}`;
};
