import {
  AccountBalance,
  ContactSupport,
  DashboardOutlined,
  Edit,
  Forum,
  NoteAdd,
  Person2,
  PublishedWithChanges,
  ThumbUpAlt,
} from '@mui/icons-material';
import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { HorizontalBlock, VerticalBlock } from '@zipptrip/zipptrip-canvas';

import NavBarToolbar from '../components/navbar/NavBarToolbar';

const guidelines = [
  {
    title: 'Dashboard',
    icon: <DashboardOutlined />,
    content:
      "Once you're logged in, you'll be taken to your dashboard. This is where you can view analytics, and navigate to other features.",
  },
  {
    title: 'Register Your Bank Account',
    icon: <AccountBalance />,
    content:
      "To receive payments, you need to register your bank account through Stripe. Go to 'Payout' section and follow the instructions to connect your bank account.",
  },
  {
    title: 'Create a Trip Plan',
    icon: <NoteAdd />,
    content:
      "To create a new trip, click on the 'Create New Itinerary' button. Fill in the details such as trip title, duration, locations, and other relevant information.",
  },
  {
    title: 'Edit a Trip Plan',
    icon: <Edit />,
    content:
      "To edit an existing trip, go to 'Plans' and select the trip you want to edit. You can add, delete, or modify events in the trip.",
  },
  {
    title: 'Publish a Trip',
    icon: <PublishedWithChanges />,
    content:
      "Once you're satisfied with your trip, you can publish it so that it's visible to users. Go to 'My Trips', select the trip, and click on 'Publish'.",
  },
  {
    title: 'Profile Settings',
    icon: <Person2 />,
    content:
      "In the 'Profile' section, you can update your personal information, change your password, and manage other account settings.",
  },
  {
    title: 'Support',
    icon: <ContactSupport />,
    content: 'If you need help or have any questions, contact us at hello@zipptrip.com.',
  },
  {
    title: 'Community Guidelines',
    icon: <Forum />,
    content:
      'Familiarize yourself with our community guidelines to ensure your content is in line with our policies and standards.',
  },
  {
    title: 'Feedback and Reviews',
    icon: <ThumbUpAlt />,
    content:
      'Pay attention to the feedback and reviews you receive from users. This can help you improve your future trips and provide better experiences.',
  },
  // {
  //   title: "Stay Updated",
  //   icon: <CheckCircle />,
  //   content:
  //     "Keep an eye on our 'Updates' section for any new features or changes to the platform. Staying updated will help you make the most of our platform.",
  // },
];

const GuidelinePage = () => {
  return (
    <>
      <NavBarToolbar>
        <HorizontalBlock height="100%">
          <Typography variant="T20M">Guidelines</Typography>
        </HorizontalBlock>
      </NavBarToolbar>

      <VerticalBlock height="100%" margin={2}>
        <Grid container spacing={4} marginTop={2}>
          {guidelines.map((guideline) => (
            <Grid item xs={12} sm={6} md={4} key={guideline.title}>
              <Card sx={{ height: { xs: 300, md: 220 }, borderRadius: 10 }}>
                <CardHeader title={guideline.title} avatar={guideline.icon} />
                <CardContent>{guideline.content}</CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Typography variant="body1" sx={{ mt: 4, mb: 4 }}>
          Remember, the key to creating a great trip is to provide as much detail as possible and to
          keep your trip information up-to-date. Happy trip planning!
        </Typography>
      </VerticalBlock>
    </>
  );
};

export default GuidelinePage;
