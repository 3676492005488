import { useEffect, useState } from 'react';

import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { LoaderCircle, VerticalBlock, useNotifications } from '@zipptrip/zipptrip-canvas';
import { sendPasswordResetEmail } from 'firebase/auth';
import { ArrowLeft } from 'iconsax-react';
import { useNavigate } from 'react-router-dom';
import { StepWizardChildProps } from 'react-step-wizard';

import { auth } from '../../config/firebase';
import { HOME_ROUTE, LOGIN_ROUTE } from '../../constants/routes';
import Logo from '../logo/Logo';

type Props = Partial<StepWizardChildProps>;

const ResetPasswordContainer = ({ goToStep }: Props) => {
  const { showError, showSuccess } = useNotifications();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [email, setEmail] = useState('');

  // if logged in, return to home
  useEffect(() => {
    if (auth.currentUser) navigate(HOME_ROUTE);
  }, []);

  const handleReset = () => {
    // check email with regex
    if (!email) {
      showError('Please enter your email');
      return;
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      showError('Please enter a valid email');
      return;
    }
    setDisabled(true);
    sendPasswordResetEmail(auth, email)
      .then(() => {
        showSuccess(
          'Password reset email sent successfully. Please check your email for further instructions.',
        );
        navigate(LOGIN_ROUTE);
      })
      .catch((err) => {
        console.error(err);
        setDisabled(false);
        showError('Error sending password reset email. Please try again later.');
      });
  };

  return (
    <VerticalBlock sx={{ gap: '24px', '& > form': { width: { xs: '100%', sm: '77%' } } }}>
      <Logo size="small" />

      <Typography variant="T28B" sx={{ letterSpacing: '1px' }}>
        Forgot password?
      </Typography>

      <Typography variant="T16R" sx={{ textAlign: 'center' }}>
        No worries, we'll send you reset instructions. Please enter your email.
      </Typography>

      <form>
        <TextField
          autoFocus
          fullWidth
          id="email"
          label="Email"
          name="email"
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          variant="outlined"
        />

        <VerticalBlock sx={{ gap: '16px', marginTop: '24px' }}>
          <Button
            color="primary"
            disabled={disabled}
            fullWidth
            onClick={handleReset}
            size="large"
            startIcon={disabled && <LoaderCircle />}
            variant="contained"
          >
            Reset Password
          </Button>

          <Button
            disabled={disabled}
            onClick={() => (goToStep ? goToStep(1) : navigate(LOGIN_ROUTE))}
            startIcon={<ArrowLeft />}
            sx={{ fontSize: '16px' }}
          >
            Back to login
          </Button>
        </VerticalBlock>
      </form>
    </VerticalBlock>
  );
};

export default ResetPasswordContainer;
