import { Theme } from '@mui/material';

const styles = (theme: Theme) => ({
  error: { color: theme.palette.error.main, fontSize: 12 },
  socialMediaIcon: { fontSize: '24px', marginTop: '-1px' },
  youtubeIcon: { color: '#FF0000' },
  facebookIcon: { color: '#3b5998' },
  instagramIcon: { color: '#d62976' },
  twitterIcon: { color: '#00acee' },
  pinterestIcon: { color: '#E60022' },
  mediaKitIcon: { color: 'var(--blue-10)' },
});

export default styles;
