import { Button, Typography } from '@mui/material';
import { Center } from '@zipptrip/zipptrip-canvas';
import { StepWizardChildProps } from 'react-step-wizard';

type StepProps = { onSubmit: any } & Partial<StepWizardChildProps>;

const SignupSuccess = ({ onSubmit }: StepProps) => {
  return (
    <Center height="fit-content">
      <img src="love.png" />
      <Typography variant="T28B" sx={{ textAlign: 'center', margin: '24px 0' }}>
        We will meet again soon!
      </Typography>
      <Typography variant="T16R" sx={{ textAlign: 'center' }}>
        Thank you for your cooperation. We will review your application and will try to respond as
        soon as possible. We value your application and will review it carefully, so this process
        can take up to 24 hours. Please check your email to not miss the latest information from us.
        We appreciate your patience and understanding!
      </Typography>
      <Button variant="contained" size="large" sx={{ margin: '24px 0' }} onClick={onSubmit}>
        I got it!
      </Button>
    </Center>
  );
};

export default SignupSuccess;
