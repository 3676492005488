import { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { Center, LoaderCircle, ZippTripProvider } from '@zipptrip/zipptrip-canvas';
import { UserType } from '@zipptrip/zipptrip-commons';
import { signInWithCustomToken } from 'firebase/auth';
import { Route, Routes, useLocation } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';

import AuthChecker from './components/auth/AuthChecker';
import NavBar from './components/navbar/drawer';
import { Analytics, auth } from './config/firebase';
import routes from './config/routes';
import theme from './modules/theme';
import { setReferrer, updateLastActiveAt } from './utils/planner';

function App() {
  const location = useLocation();
  const [token, setToken] = useQueryParam('token', StringParam);
  const [loading, setLoading] = useState(true);
  const [userType, setUserType] = useState<UserType | undefined>();

  useEffect(() => {
    // User loading
    auth.onAuthStateChanged((user) => {
      setLoading(false);
      if (!user && !!token) {
        signInWithCustomToken(auth, token);
      }
      setToken(null);
    });

    // Set the referrer to the localStorage
    setReferrer();
  }, [token]);

  useEffect(() => {
    // Get user type if its creator or agency
    auth.onAuthStateChanged((user) => {
      user?.getIdTokenResult().then((result) => {
        setUserType(result.claims.userType || UserType.Creator);
      });
    });
  }, [auth.currentUser]);

  useEffect(() => {
    // Google Analytics
    const title = routes.find((route) => route.path === location.pathname)?.name || 'Page';
    Analytics.send({ hitType: 'pageview', page: location.pathname, title });
  }, [location.pathname]);

  useEffect(() => {
    // Update lastActiveAt when route changes:
    if (!!auth.currentUser?.uid) {
      updateLastActiveAt(auth.currentUser.uid);
    }
  }, [auth.currentUser, location.pathname]);

  if (loading || (auth.currentUser && !userType)) {
    return (
      <Center>
        <LoaderCircle />
      </Center>
    );
  }

  return (
    <ZippTripProvider theme={theme}>
      <Routes>
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              route.protected ? (
                <AuthChecker>
                  <NavBar userType={userType} />
                  {/* left margin for nav and other margins as common*/}
                  <Box
                    sx={(theme) => ({
                      marginTop: '60px',
                      [theme.breakpoints.up('sm')]: { marginLeft: '293px' },
                    })}
                  >
                    <route.component />
                  </Box>
                </AuthChecker>
              ) : (
                <route.component />
              )
            }
          />
        ))}
      </Routes>
    </ZippTripProvider>
  );
}

export default App;
