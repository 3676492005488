import { Box } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import {
  ConfirmModal,
  ConfirmModalProps,
  FormTextField,
  LoaderCircle,
  useNotifications,
} from '@zipptrip/zipptrip-canvas';
import { IInternalAccount } from '@zipptrip/zipptrip-commons';
import { Form, Formik } from 'formik';

import { getInternalAccountValidationSchema } from './utils';
import { updateInternalAccount } from '../../utils/planner';

type Props = Pick<ConfirmModalProps, 'onCancel' | 'open'> & {
  accountDetails: IInternalAccount;
  refetchDetails: () => Promise<unknown>;
};

export default function InternalAccountModal({
  accountDetails,
  onCancel,
  open,
  refetchDetails,
}: Props) {
  const initialValues: Partial<IInternalAccount> = {
    bankAccountHolderName: accountDetails?.bankAccountHolderName || '',
    bankAccountNumber: accountDetails?.bankAccountNumber || '',
    bankAddress: accountDetails?.bankAddress || '',
    bankName: accountDetails?.bankName || '',
    bankSwiftCode: accountDetails?.bankSwiftCode || '',
  };
  const { showError, showSuccess } = useNotifications();

  const { mutate: updateInternalAccountMutation, isLoading: isUpdatingInternalAccount } =
    useMutation(
      async (values: Partial<IInternalAccount>) => {
        await updateInternalAccount(values);
        await refetchDetails();
      },
      {
        onSuccess: () => {
          showSuccess('Bank account updated successfully');
          onCancel();
        },
        onError: () => showError('Unexpected Error!! Please try again'),
      },
    );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => updateInternalAccountMutation(values)}
      validationSchema={getInternalAccountValidationSchema()}
    >
      {({ submitForm }) => (
        <ConfirmModal
          cancelText="Cancel"
          confirmProps={{
            disabled: isUpdatingInternalAccount,
            startIcon: isUpdatingInternalAccount && <LoaderCircle />,
          }}
          confirmText="Submit"
          onCancel={onCancel}
          onConfirm={submitForm}
          open={open}
          title="Required Information"
        >
          <Box
            sx={{
              minWidth: 'min(500px, calc(100vw - 48px))',
              '& form': { display: 'flex', flexFlow: 'column', gap: '16px', margin: '8px 0' },
            }}
          >
            <Form>
              <FormTextField field="bankName" label="Bank Name" />
              <FormTextField field="bankAddress" label="Bank Address" />
              <FormTextField field="bankSwiftCode" label="Bank Swift Code" />
              <FormTextField field="bankAccountHolderName" label="Bank Account Holder Name" />
              <FormTextField field="bankAccountNumber" label="Bank Account Number" />
            </Form>
          </Box>
        </ConfirmModal>
      )}
    </Formik>
  );
}
