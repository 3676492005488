import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import Analytics from 'react-ga4';

import { firebaseConfig } from './firebase.config';
import { getEnv } from '../utils/env';

export const Firebase = initializeApp(firebaseConfig);
export const auth = getAuth();
export const Providers = { google: new GoogleAuthProvider() };
export const Firestore = getFirestore();

// Google Analytics
Analytics.initialize(getEnv('FirebaseMeasurementId'));
export { Analytics };
export const sendGAEvent = (name: string) => {
  const { displayName, uid } = auth.currentUser || {};
  const payload = { ...(!!displayName ? { displayName } : {}), ...(!!uid ? { uid } : {}) };
  Analytics.event(name, payload);
};
