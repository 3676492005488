import { Card, Grid, Typography } from '@mui/material';
import { Center } from '@zipptrip/zipptrip-canvas';

interface Props {
  label: string;
  number: number;
  isAmount?: boolean;
}

const DashboardCard = ({ label, number, isAmount }: Props) => {
  return (
    <Card sx={{ height: { xs: 300, md: 220 }, borderRadius: 10 }}>
      <Grid container alignItems="center" justifyContent="center">
        <Center height={30}>
          <Grid item>
            <Center height={6}>
              <Typography variant="h3">
                {isAmount ? '$' : ''}
                {Number(number.toFixed(2))}
              </Typography>
            </Center>
            <Center height={6}>
              <Typography sx={{ color: 'text.secondary' }} variant="body1">
                {label}
              </Typography>
            </Center>
          </Grid>
        </Center>
      </Grid>
    </Card>
  );
};

export default DashboardCard;
