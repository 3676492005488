import { Center } from '@zipptrip/zipptrip-canvas';
import { Link } from 'react-router-dom';

import { HOME_ROUTE } from '../constants/routes';

const UnknownRoute = () => {
  // 404 page
  return (
    <Center>
      <h1>404</h1>
      <p>Page not found</p>
      <Link to={HOME_ROUTE}>Go to home</Link>
    </Center>
  );
};

export default UnknownRoute;
