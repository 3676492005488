import * as Yup from 'yup';

export const getInternalAccountValidationSchema = () => {
  return Yup.object().shape({
    bankAccountHolderName: Yup.string().required('Bank account holder name is required'),
    bankAccountNumber: Yup.string().required('Bank account number is required'),
    bankAddress: Yup.string().required('Bank address is required'),
    bankName: Yup.string().required('Bank name is required'),
    bankSwiftCode: Yup.string().required('Bank swift code is required'),
  });
};
