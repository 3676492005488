import LandingContainer from '../components/landing/LandingContainer';
import { auth } from '../config/firebase';
import { HOME_ROUTE } from '../constants/routes';

const Landing = () => {
  if (auth.currentUser) {
    window.location.href = HOME_ROUTE;
  }
  return <LandingContainer />;
};

export default Landing;
