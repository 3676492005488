import { Prop } from './LoadingSpinner';

const styles = () => ({
  root: (props: Prop) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingTop: props.spaceTop,
    paddingBottom: props.spaceBottom,
    height: '100%',
  }),
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50%',
  },
});

export default styles;
