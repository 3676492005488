import { useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { HorizontalBlock } from '@zipptrip/zipptrip-canvas';

import LoadingSpinner from '../components/loader/LoadingSpinner';
import NavBarToolbar from '../components/navbar/NavBarToolbar';
import ReferralLink from '../components/referral/ReferralLink';
import ReferralUser from '../components/referral/ReferralUser';
import { ReferralSummary } from '../types/planner';
import { getReferrerSummary } from '../utils/planner';

const ReferralPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [summaryData, setSummaryData] = useState<ReferralSummary[]>([]);

  useEffect(() => {
    setIsLoading(true);
    getReferrerSummary()
      .then((data) => {
        setIsLoading(false);
        setSummaryData(data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
      });
  }, []);

  if (isLoading) return <LoadingSpinner />;

  return (
    <>
      <NavBarToolbar>
        <HorizontalBlock height="100%">
          <Typography variant="T20M">Referrals</Typography>
        </HorizontalBlock>
      </NavBarToolbar>

      <Box
        sx={{
          display: 'flex',
          flexFlow: 'column',
          gap: '32px',
          margin: 'auto',
          padding: '32px 0',
          width: 'max(calc(100% - 160px), 85%)',
        }}
      >
        <ReferralLink summaryData={summaryData} />

        <ReferralUser summaryData={summaryData} />
      </Box>
    </>
  );
};

export default ReferralPage;
