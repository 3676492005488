import { useEffect, useState } from 'react';

import { Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { HorizontalBlock } from '@zipptrip/zipptrip-canvas';
import { IPlannerPayoutAccount } from '@zipptrip/zipptrip-commons';
import { useNavigate } from 'react-router-dom';
import StepWizard from 'react-step-wizard';

import UserBlockedDialog from '../components/auth/UnapprovedAccount';
import LoadingSpinner from '../components/loader/LoadingSpinner';
import NavBarToolbar from '../components/navbar/NavBarToolbar';
import ConnectPanel from '../components/payout/ConnectPanel';
import RecordPanel from '../components/payout/RecordPanel';
import { auth } from '../config/firebase';
import { getPayoutAccountDetails, getPlannerById } from '../utils/planner';

const PayoutPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isProfileApproved, setIsProfileApproved] = useState<boolean>(true);
  const [payoutAccount, setPayoutAccount] = useState<IPlannerPayoutAccount | undefined>();
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    getPlannerById(auth.currentUser?.uid || '')
      .then((planner) => {
        if (!planner) return;

        setPayoutAccount(planner.payoutAccount);
        setIsProfileApproved(!planner.isBlocked);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
      });
  }, []);

  const { data, isFetching, refetch } = useQuery(
    ['/creator/payout/account-details'],
    async () => getPayoutAccountDetails(),
    { enabled: !!payoutAccount },
  );

  if (isLoading || isFetching) return <LoadingSpinner />;
  if (!isProfileApproved) return <UserBlockedDialog handleClose={() => navigate(-1)} />;

  return (
    <>
      <NavBarToolbar>
        <HorizontalBlock height="100%">
          <Typography variant="T20M">Payout</Typography>
        </HorizontalBlock>
      </NavBarToolbar>

      <StepWizard isLazyMount initialStep={data?.isCompleted ? 2 : 1}>
        <ConnectPanel
          payoutAccount={payoutAccount}
          payoutAccountDetails={data}
          refetchDetails={refetch}
          setPayoutAccount={setPayoutAccount}
        />
        <RecordPanel />
      </StepWizard>
    </>
  );
};

export default PayoutPage;
