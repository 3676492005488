import { TextHierarchy, ZippTripProviderProps } from '@zipptrip/zipptrip-canvas';

declare module '@mui/material/Fab' {
  interface FabClasses {
    outlined: true;
  }

  interface FabPropsVariantOverrides {
    outlined: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyClasses extends TextHierarchy {}

  interface TypographyPropsVariantOverrides extends TextHierarchy {}
}

declare module '@mui/material/styles' {
  interface SimplePaletteColorOptions {
    selectedBackground?: string;
  }

  interface PaletteColor {
    selectedBackground?: string;
  }

  interface TypeText {
    border?: string;
    divider?: string;
    background?: string;
  }
}

const theme: ZippTripProviderProps['theme'] = {
  components: {
    MuiAutocomplete: { styleOverrides: { popper: { zIndex: 5000 } } },
  },
};

export default theme;
