import { useEffect, useState } from 'react';

import { IPlanner } from '@zipptrip/zipptrip-commons';

import LoadingSpinner from '../components/loader/LoadingSpinner';
import ProfileContainer from '../components/profile/ProfileContainer';
import { auth } from '../config/firebase';
import { getPlannerById } from '../utils/planner';

const ProfilePage = () => {
  const [planner, setPlanner] = useState<IPlanner>();

  useEffect(() => {
    if (!auth.currentUser?.uid) return;
    getPlannerById(auth.currentUser.uid).then((planner) => {
      if (planner) setPlanner(planner);
    });
  }, []);

  if (!planner) return <LoadingSpinner />;

  return <ProfileContainer planner={planner} />;
};

export default ProfilePage;
