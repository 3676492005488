import { Box, Button, Grid, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  Center,
  HorizontalBlock,
  LoaderCircle,
  VerticalBlock,
  useNotifications,
} from '@zipptrip/zipptrip-canvas';
import { AchievementStatus, AchievementType } from '@zipptrip/zipptrip-commons';
import { Add, Flash, MagicStar, TickCircle } from 'iconsax-react';
import { useNavigate } from 'react-router-dom';

import { ACHIEVEMENT_RELATED_PATH } from './constants';
import { collectAchievement, getAchievementList } from '../../utils/planner';

interface Props {
  refetchPlanner: () => void;
}

export default function AchievementList({ refetchPlanner }: Props) {
  const {
    data: achievementList = [],
    isFetching,
    refetch: refetchAchievements,
  } = useQuery(['/creator/achievements'], async () => {
    const results = await getAchievementList();
    const collected = results.filter(({ status }) => status === AchievementStatus.Collected);
    const locked = results.filter(({ status }) => status === AchievementStatus.Locked);
    const unlocked = results.filter(({ status }) => status === AchievementStatus.Unlocked);
    return [...unlocked, ...locked, ...collected];
  });

  return (
    <Box sx={{ background: '#F6F6F6', borderRadius: '40px', padding: '24px' }}>
      <HorizontalBlock sx={{ gap: '8px', marginBottom: '16px', width: 'fit-content' }}>
        <Flash size={28} />
        <Typography variant="T16S">Unlock missions to increase leveling speed</Typography>
      </HorizontalBlock>

      {isFetching ? (
        <Center height="350px">
          <LoaderCircle />
        </Center>
      ) : (
        <Grid container spacing={2}>
          {achievementList.map(({ type, title, points, status }) => (
            <Grid key={type} item xs={12} lg={6}>
              <Box sx={{ bgcolor: 'text.background', borderRadius: '24px', padding: '24px' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={7}>
                    <VerticalBlock gap="8px" sx={{ alignItems: 'flex-start' }}>
                      <Typography variant="T16M">{title}</Typography>

                      <HorizontalBlock
                        gap="8px"
                        sx={{ '& > svg': { color: 'warning.main', flexShrink: '0' } }}
                      >
                        <MagicStar size={20} variant="Bold" />
                        <Typography color="textSecondary" variant="T14R">
                          Get {points} points
                        </Typography>
                      </HorizontalBlock>
                    </VerticalBlock>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={5}
                    sx={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <AchievementButton
                      refetchAchievements={refetchAchievements}
                      refetchPlanner={refetchPlanner}
                      status={status}
                      type={type}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
}

interface AchievementButtonProps {
  refetchAchievements: () => void;
  refetchPlanner: () => void;
  status: AchievementStatus;
  type: AchievementType;
}

const AchievementButton = ({
  refetchAchievements,
  refetchPlanner,
  status,
  type,
}: AchievementButtonProps) => {
  const navigate = useNavigate();
  const { showError, showSuccess } = useNotifications();

  const { isLoading, mutate: collectAchievementMutation } = useMutation(
    async () => collectAchievement(type),
    {
      onSuccess: () => {
        refetchAchievements();
        refetchPlanner();
        showSuccess('Achievement collected successfully');
      },
      onError: () => showError('Unexpected Error!! Please try again'),
    },
  );

  if (status === AchievementStatus.Collected) {
    return (
      <HorizontalBlock
        sx={{
          gap: '4px',
          opacity: 0.35,
          padding: '0 13px',
          width: 'fit-content',
          '& > svg > path:first-of-type': { display: 'none' },
        }}
      >
        <TickCircle />
        <Typography variant="T14M">Collected</Typography>
      </HorizontalBlock>
    );
  }

  if (status === AchievementStatus.Locked) {
    return (
      <Button
        onClick={() => navigate(ACHIEVEMENT_RELATED_PATH[type])}
        size="small"
        startIcon={<Add />}
        sx={{ minWidth: '118px' }}
        variant="outlined"
      >
        Unlock
      </Button>
    );
  }

  if (status === AchievementStatus.Unlocked) {
    return (
      <Button
        disabled={isLoading}
        onClick={() => collectAchievementMutation()}
        size="small"
        startIcon={isLoading && <LoaderCircle />}
        sx={{ minWidth: '118px' }}
        variant="contained"
      >
        Collect
      </Button>
    );
  }

  return <></>;
};
