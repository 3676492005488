import { useState } from 'react';

import { Box, Button, Tooltip, Typography } from '@mui/material';
import { HorizontalBlock, LoaderCircle, useNotifications } from '@zipptrip/zipptrip-canvas';
import { IPlanner, Language } from '@zipptrip/zipptrip-commons';
import { updateProfile } from 'firebase/auth';
import { Formik, Form } from 'formik';

import ProfileBasicInfo from './ProfileBasicInfo';
import ProfilePictureInfo from './ProfilePictureInfo';
import ProfileSocialInfo from './ProfileSocialInfo';
import ProfileVisitInfo from './ProfileVisitInfo';
import { getValidationSchema } from './utils';
import { auth, sendGAEvent } from '../../config/firebase';
import { DEFAULT_COVER_IMG_SRC, DEFAULT_IMG_SRC } from '../../constants/commons';
import { ZIPPTRIP_URL_ROOT } from '../../constants/commons';
import { createOrUpdatePlanner, uploadPlannerImage } from '../../utils/planner';
import NavBarToolbar from '../navbar/NavBarToolbar';
import PlanSuggest from '../plan/PlanSuggest';

interface Props {
  planner: IPlanner;
}

const ProfileContainer = ({ planner }: Props) => {
  const { showError, showSuccess } = useNotifications();

  const InitialValues: IPlanner = {
    ...planner,
    languages: !planner?.languages?.length ? [Language.English] : planner?.languages,
  };
  const [imageSrc, setImageSrc] = useState(planner.imageSrc || DEFAULT_IMG_SRC);
  const [coverImageSrc, setCoverImageSrc] = useState(
    planner.coverImageSrc || DEFAULT_COVER_IMG_SRC,
  );

  if (!planner.uid) return <></>;

  const publicUrl = `${ZIPPTRIP_URL_ROOT}/planner/${(planner.name || 'untitled')
    ?.toLocaleLowerCase()
    ?.replace(/ /g, '-')}-${planner.uid}`;

  return (
    <Formik
      initialValues={InitialValues}
      validationSchema={getValidationSchema()}
      validate={() => ({})}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        // check if image in newly updated
        let dpImageId = '';
        let coverImageId = '';
        const updatedValues = { ...values };
        if (imageSrc?.startsWith('data:image/')) {
          dpImageId = await uploadPlannerImage(imageSrc, planner.uid, planner.imageSrc);
          setImageSrc(dpImageId);
          updatedValues.imageSrc = dpImageId;
        } else {
          delete updatedValues.imageSrc;
        }
        if (coverImageSrc?.startsWith('data:image/')) {
          coverImageId = await uploadPlannerImage(coverImageSrc);
          setCoverImageSrc(coverImageId);
          updatedValues.coverImageSrc = coverImageId;
        } else {
          delete updatedValues.coverImageSrc;
        }

        await createOrUpdatePlanner(planner.uid as string, updatedValues)
          .then(async () => {
            if (auth.currentUser) {
              await updateProfile(auth.currentUser, { displayName: values.name });
              sendGAEvent('event:update-profile');
              if (!!updatedValues.imageSrc || !!updatedValues.coverImageSrc) {
                sendGAEvent('event:upload-profile-image');
              }
            }
            showSuccess('Profile updated successfully');
            setSubmitting(false);
          })
          .catch((error) => {
            console.error(error);
            showError('Error updating profile');
            setSubmitting(false);
          });
      }}
    >
      {({ errors, handleSubmit, isSubmitting, isValid, submitForm }) => (
        <Form onSubmit={handleSubmit}>
          <NavBarToolbar>
            <HorizontalBlock height="100%">
              <Typography sx={{ display: { xs: 'none', sm: 'block' } }} variant="T20M">
                Profile information
              </Typography>
              <Typography sx={{ display: { sm: 'none' } }} variant="T20M">
                Profile
              </Typography>

              <HorizontalBlock gap="16px">
                <Tooltip title="Preview profile in public mode. Please save changes and then preview to see updated information there..">
                  <Button
                    onClick={() => {
                      window.open(publicUrl, '_blank');
                    }}
                    size="small"
                    sx={{ display: { xs: 'none', sm: 'block' } }}
                    variant="outlined"
                  >
                    Preview
                  </Button>
                </Tooltip>

                <Tooltip title={Object.values(errors)?.[0]}>
                  <span>
                    <Button
                      disabled={isSubmitting || !isValid}
                      onClick={submitForm}
                      size="small"
                      startIcon={isSubmitting && <LoaderCircle />}
                      variant="contained"
                    >
                      Save
                    </Button>
                  </span>
                </Tooltip>
              </HorizontalBlock>
            </HorizontalBlock>
          </NavBarToolbar>

          <ProfilePictureInfo
            coverImageSrc={coverImageSrc}
            imageSrc={imageSrc}
            setCoverImageSrc={setCoverImageSrc}
            setImageSrc={setImageSrc}
          />

          <Box
            sx={(theme) => ({
              display: 'flex',
              flexFlow: 'column',
              gap: '48px',
              margin: '80px auto',
              width: 'max(60%, 300px)',
              [theme.breakpoints.down('sm')]: { width: '90%' },
            })}
          >
            <ProfileBasicInfo />
            <ProfileSocialInfo />
            <ProfileVisitInfo />
            <PlanSuggest alreadyVisited={planner.alreadyVisited || []} breakpoint="xl" />
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default ProfileContainer;
