import { Dispatch, Fragment, SetStateAction, useEffect, useRef, useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  Center,
  ConfirmModal,
  FormAutocomplete,
  FormSelect,
  FormTextField,
  LoaderCircle,
  useNotifications,
} from '@zipptrip/zipptrip-canvas';
import { FoodPreference, IEvent, IPlan, ItineraryEventType } from '@zipptrip/zipptrip-commons';
import { Form, Formik } from 'formik';
import { Add, Trash } from 'iconsax-react';
import moment from 'moment';

import AutoGenButton from './AutoGenButton';
import styles from './PlanForm.styles';
import { checkConflict, getEventValidationSchema } from './utils';
import { sendGAEvent } from '../../config/firebase';
import { getTimeString, getTimeValue } from '../../utils/commons';
import { getImageUrl } from '../../utils/images';
import { createOrUpdateEvent, getDayofAPlanEvent, getEventsOfAPlanDay } from '../../utils/plan';
import ImageUploader from '../imageUploader/ImageUploader';
import PlaceAutoComplete from '../placeAutoComplete/PlaceAutoComplete';

interface Props {
  event: IEvent;
  handleClose: () => void;
  newEventDay: number;
  open: boolean;
  plan: IPlan;
  setPlan: Dispatch<SetStateAction<IPlan | undefined>>;
}

const useStyles = makeStyles(styles);

const EventForm = ({ event, handleClose, newEventDay, open, plan, setPlan }: Props) => {
  const isNewEvent = !event?._id;

  const day = isNewEvent ? newEventDay : getDayofAPlanEvent(plan, event);
  const allEvents = getEventsOfAPlanDay(plan, day);

  const { showError, showSuccess } = useNotifications();
  const [images, setImages] = useState<string[]>([]);
  const [autoGenError, setAutoGenError] = useState<string>();
  const formikRef = useRef<any>();

  useEffect(() => {
    if (open) {
      if (isNewEvent) sendGAEvent('open:create-event-modal');
      else sendGAEvent('open:edit-event-modal');
    }
  }, [isNewEvent, open]);

  useEffect(() => {
    const tempPhotos = [...(event?.photos || [])];
    while (tempPhotos.length < 2) tempPhotos.push('');
    setImages(tempPhotos);
  }, [event?.photos?.length, open]);

  const classes = useStyles();

  const initialStartTime = event?.startTime ?? allEvents[allEvents.length - 1]?.endTime ?? 0;
  const initialValues: IEvent = {
    _id: event?._id,
    title: event?.title || '',
    description: event?.description || '',
    startTime: initialStartTime,
    endTime: event?.endTime ?? initialStartTime + 60,
    location: event?.location || allEvents[allEvents.length - 1]?.toLocation || undefined,
    fromLocation: event?.fromLocation || allEvents[allEvents.length - 1]?.toLocation || undefined,
    toLocation: event?.toLocation,
    timezone: event?.timezone || '',
    costFor1: event?.costFor1,
    costFor2: event?.costFor2,
    costFor3: event?.costFor3,
    costFor4: event?.costFor4,
    foodTypes: event?.foodTypes || [],
    photos: event?.photos || [],
    videos: event?.videos || [],
    donts: event?.donts || '',
    mustDos: event?.mustDos || '',
    webLinks: event?.webLinks || [],
    eventType: event?.eventType || ('' as ItineraryEventType),
    preferredTransport: event?.preferredTransport || '',
    alternativeTransport: event?.alternativeTransport || '',
    needsPreBooking: event?.needsPreBooking || false,
    preBookingInfo: event?.preBookingInfo || '',
    isKeyEvent: event?.isKeyEvent ?? false,
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={getEventValidationSchema()}
      validate={() => ({})}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        // check conflict
        const conflict = { field: '', message: '' };
        allEvents.forEach((event) => {
          if (event._id === values._id) return;
          const conflictField = checkConflict(
            { startTime: values.startTime, endTime: values.endTime },
            { startTime: event.startTime, endTime: event.endTime },
          );
          if (conflictField) {
            conflict.field = conflictField;
            conflict.message = `Event time conflicts with ${event.title}`;
          }
        });
        if (conflict.field) {
          setFieldError(conflict.field, conflict.message);
          setSubmitting(false);
          showError(conflict.message);
          return;
        }
        createOrUpdateEvent(values, plan._id || '', day)
          .then((plan) => {
            setPlan(plan);
            showSuccess('Event saved successfully');
            handleClose();
          })
          .catch((error) => {
            console.error(error);
            showError('Error in saving event');
            setSubmitting(false);
          });
      }}
    >
      {({ errors, isSubmitting, resetForm, setFieldValue, submitForm, touched, values }) => {
        useEffect(() => resetForm({ values: initialValues }), [JSON.stringify(initialValues)]);
        return (
          <ConfirmModal
            confirmProps={{
              color: 'inherit',
              disabled: isSubmitting,
              fullWidth: true,
              startIcon: isSubmitting && <LoaderCircle />,
            }}
            confirmText={isNewEvent ? 'Add event' : 'Save event'}
            onCancel={() => {
              setImages([]);
              handleClose();
            }}
            onConfirm={() => {
              submitForm();
              const error = (Object.values(errors)[0] as string) || '';
              if (!!error) showError(error);
            }}
            open={open}
            title={isNewEvent ? 'Add event' : 'Edit event'}
          >
            <Form>
              <Box sx={{ textAlign: 'left' }}>
                <Typography variant="T16S">Basic information</Typography>

                <Grid container columnSpacing={1}>
                  <Grid item xs={12} sm={6} className={classes.formField}>
                    <FormControl fullWidth>
                      <FormTextField
                        field="title"
                        inputProps={{ sx: { '&::placeholder': { color: 'secondary' } } }}
                        label="Event Title"
                        placeholder=""
                        required
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.formField}>
                    <FormAutocomplete
                      defaultValue={null}
                      field="eventType"
                      options={Object.values(ItineraryEventType)}
                      textfieldProps={{
                        label: 'Event Type',
                        placeholder: 'Type of event',
                        required: true,
                      }}
                    />
                  </Grid>
                </Grid>

                {values.eventType === ItineraryEventType.Commute ? (
                  <>
                    <Grid container columnSpacing={1}>
                      <Grid item xs={12} sm={6} className={classes.formField}>
                        <PlaceAutoComplete
                          country={plan.countries[0]}
                          field="fromLocation"
                          label="From Location *"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} className={classes.formField}>
                        <PlaceAutoComplete
                          country={plan.countries[0]}
                          field="toLocation"
                          label="To Location *"
                        />
                      </Grid>
                    </Grid>

                    <Grid container columnSpacing={1}>
                      <Grid item xs={12} sm={6} className={classes.formField}>
                        <FormTextField
                          error={!!(errors.preferredTransport && touched.preBookingInfo)}
                          field="preferredTransport"
                          label="Preferred Transport"
                          placeholder="Preferred transport"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} className={classes.formField}>
                        <FormTextField
                          field="alternativeTransport"
                          label="Alternative Transport"
                          placeholder="Alternative transport"
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <Grid className={classes.formField}>
                    <PlaceAutoComplete
                      country={plan.countries[0]}
                      field="location"
                      label="Location *"
                    />
                  </Grid>
                )}

                <Grid container columnSpacing={1}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Grid item xs={12} sm={6} className={classes.formField}>
                      <TimePicker
                        label="Start Time"
                        value={moment(getTimeString(values.startTime), 'HH:mm A').toDate()}
                        onChange={(event) => {
                          const time = moment(new Date(event?.toString() as string)).format(
                            'HH:mm',
                          );
                          const startTimeValue = getTimeValue(time);
                          setFieldValue('startTime', getTimeValue(time));
                          if (!values.endTime || values.endTime <= startTimeValue) {
                            let endTimeValue = startTimeValue + 60;
                            if (endTimeValue > 24 * 60) {
                              endTimeValue -= 24 * 60;
                            }
                            setFieldValue('endTime', endTimeValue);
                          }
                        }}
                        renderInput={(params) => <TextField fullWidth {...params} />}
                      />
                      {errors.startTime && touched.startTime && (
                        <div className={classes.error}>{errors.startTime} </div>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.formField}>
                      <TimePicker
                        label="End Time"
                        value={moment(getTimeString(values.endTime), 'HH:mm A').toDate()}
                        onChange={(event) => {
                          const time = moment(new Date(event?.toString() as string)).format(
                            'HH:mm',
                          );
                          let endTimeValue = getTimeValue(time);
                          if (endTimeValue < values.startTime) {
                            endTimeValue += 24 * 60;
                          }
                          setFieldValue('endTime', getTimeValue(time));
                        }}
                        renderInput={(params) => <TextField fullWidth {...params} />}
                      />
                      {errors.endTime && touched.endTime && (
                        <div className={classes.error}>{errors.endTime} </div>
                      )}
                    </Grid>
                  </LocalizationProvider>
                </Grid>

                {[
                  ItineraryEventType.Breakfast,
                  ItineraryEventType.Lunch,
                  ItineraryEventType.Dinner,
                ].includes(values.eventType) && (
                  <Grid className={classes.formField}>
                    <FormSelect
                      field="foodTypes"
                      label="Food Types"
                      labelId="food-type-label"
                      multiple
                      required
                      sx={{ height: 56 }}
                    >
                      {Object.values(FoodPreference).map((type) => (
                        <MenuItem key={type} value={type}>
                          <Checkbox checked={(values.foodTypes?.indexOf(type) || -1) > -1} />
                          <ListItemText primary={type} />
                        </MenuItem>
                      ))}
                    </FormSelect>
                  </Grid>
                )}

                <Grid className={classes.formField} sx={{ overflow: 'visible !important' }}>
                  <FormTextField
                    inputProps={{ sx: { '&::placeholder': { color: 'secondary' } } }}
                    multiline
                    maxRows={6}
                    minRows={6}
                    required
                    label="Description"
                    placeholder="Write a short description about the event."
                    field="description"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{
                            color: 'primary.main',
                            position: 'absolute',
                            bottom: '36px',
                            right: '16px',
                          }}
                        >
                          {/* <Button
                            disabled={isLoading}
                            variant="outlined"
                            onClick={() => handleAutoGenerate(setFieldValue)}
                          >
                            <Magicpen />
                            <Typography variant="T14M" sx={{ color: 'var(--primary-6, #009F9A)' }}>
                              Generate description with AI
                            </Typography>
                          </Button> */}
                          <AutoGenButton
                            formikRef={formikRef}
                            setAutoGenError={setAutoGenError}
                            setFieldValue={setFieldValue}
                            isValid={
                              !!values.title &&
                              !!values.eventType &&
                              (!!values.location ||
                                (!!values.fromLocation && !!values.toLocation)) &&
                              values.startTime !== undefined &&
                              values.endTime !== undefined
                            }
                          />
                        </InputAdornment>
                      ),
                      sx: { alignItems: 'baseline', position: 'relative' },
                    }}
                  />
                  <Typography variant="T14R" sx={{ color: 'var(--red-6, #F5222D)' }}>
                    {autoGenError}
                  </Typography>
                </Grid>

                <Grid className={classes.formField}>
                  <FormTextField
                    inputProps={{ sx: { '&::placeholder': { color: 'secondary' } } }}
                    multiline
                    maxRows={4}
                    minRows={4}
                    label="Must Do's"
                    placeholder="Things that are highly recommended to do in this event."
                    field="mustDos"
                  />
                </Grid>

                <Grid className={classes.formField}>
                  <FormTextField
                    inputProps={{ sx: { '&::placeholder': { color: 'secondary' } } }}
                    multiline
                    maxRows={4}
                    minRows={4}
                    label="Dont's"
                    placeholder="What not to do in this event."
                    field="donts"
                  />
                </Grid>

                <Typography variant="T16S" sx={{ marginTop: '24px' }}>
                  Approximate cost of this event (USD)
                </Typography>
                <Grid container columnSpacing={1}>
                  <Grid item xs={12} sm={3} className={classes.formField}>
                    <FormTextField field="costFor1" label="1 person" type="number" />
                  </Grid>
                  <Grid item xs={12} sm={3} className={classes.formField}>
                    <FormTextField field="costFor2" label="2 people" type="number" />
                  </Grid>
                  <Grid item xs={12} sm={3} className={classes.formField}>
                    <FormTextField field="costFor3" label="3 people" type="number" />
                  </Grid>
                  <Grid item xs={12} sm={3} className={classes.formField}>
                    <FormTextField field="costFor4" label="4 people" type="number" />
                  </Grid>
                </Grid>

                <Typography variant="T16S" sx={{ marginTop: '24px' }}>
                  Important links (if any)
                </Typography>

                {values.webLinks?.map((link, index) => (
                  <Fragment key={index}>
                    <Grid container columnSpacing={1}>
                      <Grid item xs={2} sm={1} className={classes.formField}>
                        {/* delete icon button */}
                        <Center height="58px">
                          <IconButton
                            aria-label="delete"
                            color="error"
                            onClick={() => {
                              const webLinks = values.webLinks?.filter((_, i) => i !== index);
                              setFieldValue('webLinks', webLinks);
                            }}
                          >
                            <Trash />
                          </IconButton>
                        </Center>
                      </Grid>
                      <Grid item xs={10} sm={5} className={classes.formField}>
                        <FormTextField
                          error={!!(errors.webLinks as any)?.[index]?.title}
                          field={`webLinks.${index}.title`}
                          placeholder="Title of the web link"
                          label="Title"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} className={classes.formField}>
                        <FormTextField
                          error={!!(errors.webLinks as any)?.[index]?.url}
                          field={`webLinks.${index}.url`}
                          label="Url"
                          placeholder="URL"
                        />
                      </Grid>
                    </Grid>
                  </Fragment>
                ))}

                <Box sx={{ marginTop: '16px' }}>
                  <Button
                    disabled={
                      (!values.webLinks?.[values.webLinks.length - 1]?.title ||
                        !values.webLinks[values.webLinks.length - 1]?.url) &&
                      !!values.webLinks?.length
                    }
                    onClick={() =>
                      setFieldValue('webLinks', [
                        ...(values.webLinks || []),
                        { title: '', url: '' },
                      ])
                    }
                    startIcon={<Add />}
                    sx={{ padding: 0 }}
                  >
                    Add link
                  </Button>
                </Box>

                <Grid className={classes.formField}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!values.isKeyEvent}
                        onChange={() => setFieldValue('isKeyEvent', !values.isKeyEvent)}
                        name="isKeyEvent"
                        value={!!values.isKeyEvent}
                      />
                    }
                    label="Mark as a key event"
                    sx={{ marginLeft: 0 }}
                  />
                </Grid>

                <Grid className={classes.formField}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.needsPreBooking}
                        onChange={() => setFieldValue('needsPreBooking', !values.needsPreBooking)}
                        name="isPrivate"
                        value={values.needsPreBooking}
                      />
                    }
                    label="Check the box if this event needs pre-booking"
                    sx={{ marginLeft: 0 }}
                  />
                </Grid>
                {values.needsPreBooking && (
                  <Grid className={classes.formField}>
                    <FormTextField
                      inputProps={{ sx: { '&::placeholder': { color: 'secondary' } } }}
                      multiline
                      maxRows={2}
                      minRows={2}
                      label="Pre-Booking Information"
                      placeholder="Write pre-booking details here."
                      field="preBookingInfo"
                      errorMessage={errors.startTime}
                    />
                  </Grid>
                )}
                <Grid container columnSpacing={1} sx={{ margin: '8px auto 0', maxWidth: '470px' }}>
                  {images.map((item, index) => {
                    const setImageSrc = (path: string, index: number) => {
                      const newPhotos = [...values.photos];
                      newPhotos[index] = path;
                      setFieldValue('photos', newPhotos);
                      const newImages = [...images];
                      newImages[index] = path;
                      setImages(newImages);
                    };
                    return (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        sm={6}
                        className={classes.formField}
                        sx={{ '& img': { borderRadius: '40px' } }}
                      >
                        <ImageUploader
                          imageSrc={getImageUrl(item)}
                          index={index}
                          showButton={false}
                          ratio={1.33}
                          setImageSrc={setImageSrc}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Form>
          </ConfirmModal>
        );
      }}
    </Formik>
  );
};

export default EventForm;
