import { useState } from 'react';

import { AddOutlined, CheckCircleOutlineOutlined, DescriptionOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Center, ConfirmModal, LoaderCircle, useNotifications } from '@zipptrip/zipptrip-canvas';
import { IWithdraw, WithdrawStatusType } from '@zipptrip/zipptrip-commons';
import moment from 'moment';
import { StepWizardChildProps } from 'react-step-wizard';

import { auth } from '../../config/firebase';
import { getPayouts, submitPayout } from '../../utils/planner';
import LoadingSpinner from '../loader/LoadingSpinner';

export default function RecordPanel({ goToStep }: Partial<StepWizardChildProps>) {
  const [showPayoutDialog, setShowPayoutDialog] = useState<boolean>(false);
  const [payout, setPayout] = useState<{ amount: number; note?: string }>({ amount: 0, note: '' });
  const { showError, showSuccess } = useNotifications();

  const {
    data: payoutRequests = [],
    isLoading,
    refetch,
  } = useQuery(
    ['/withdraw'],
    async () =>
      new Promise<IWithdraw[]>((resolve, reject) => {
        getPayouts()
          .then((payouts) => resolve(payouts))
          .catch((err) => {
            console.error(err);
            reject(err);
          });
      }),
  );

  const { mutate: onPayoutSubmit, isLoading: isPayingOut } = useMutation(async () => {
    if (payoutRequests.some((payout) => payout.status === WithdrawStatusType.PENDING)) {
      showError('You already have a pending payout request. Please wait for approval.');
      return;
    }
    if (!payout?.amount || payout?.amount < 100) return;

    await submitPayout({
      note: payout.note || '',
      status: WithdrawStatusType.PENDING,
      plannerId: auth.currentUser?.uid || '',
      amount: payout.amount,
      plannerName: auth.currentUser?.displayName || '',
    })
      .then(async () => {
        setPayout({ amount: 0, note: '' });
        showSuccess('Payout request submitted successfully. Please wait for approval.');
        await refetch();
        setShowPayoutDialog(false);
      })
      .catch((err: any) => {
        console.error(err);
        showError(err?.response?.data?.message || 'Something went wrong. Please try again later.');
      });
  });

  if (isLoading) return <LoadingSpinner />;

  return (
    <Box sx={{ margin: '0 32px' }}>
      <Center height={30}>
        <Button
          onClick={() => setShowPayoutDialog(true)}
          size="large"
          startIcon={<AddOutlined />}
          variant="outlined"
        >
          Request payout
        </Button>
      </Center>

      {payoutRequests.length > 0 ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Requested At</TableCell>
                <TableCell>Approved At</TableCell>
                <TableCell>Note</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {payoutRequests.map((row) => (
                <TableRow
                  key={row._id as string}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row._id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <b>${row.amount}</b>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Chip
                      label={row.status}
                      color={row.status === WithdrawStatusType.SUCCEEDED ? 'success' : 'warning'}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {moment(row.createdAt).format('DD/MM/YYYY hh:mm A')}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.approvedAt ? moment(row.approvedAt).format('DD/MM/YYYY hh:mm A') : '-'}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Tooltip title={row.note}>
                      <DescriptionOutlined />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Center height={30}>
          {/* add bullet points */}
          <List
            sx={{
              display: 'flex',
              flexFlow: 'column',
              gap: '12px',
              '& > li': { alignItems: 'flex-start', display: 'flex', gap: '8px' },
            }}
          >
            <ListItem>
              <ListItemIcon>
                <CheckCircleOutlineOutlined />
              </ListItemIcon>
              <ListItemText primary="You don't have any payout records yet." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleOutlineOutlined />
              </ListItemIcon>
              <ListItemText primary="Minimum payout amount must be $100." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleOutlineOutlined />
              </ListItemIcon>
              <ListItemText primary="Payout amount must be lesser than or equal to your balance amount." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleOutlineOutlined />
              </ListItemIcon>
              <ListItemText primary="Payout can take 3-7 working days." />
            </ListItem>
          </List>
        </Center>
      )}

      <Button
        color="primary"
        onClick={() => goToStep?.(1)}
        sx={{ display: 'block', margin: 'auto' }}
        variant="contained"
      >
        Back to connect bank account
      </Button>

      {/* show confirmation dialog for status update */}
      <ConfirmModal
        cancelText="Close"
        confirmProps={{ disabled: isPayingOut, startIcon: isPayingOut && <LoaderCircle /> }}
        confirmText="Confirm"
        onCancel={() => setShowPayoutDialog(false)}
        onConfirm={onPayoutSubmit}
        open={showPayoutDialog}
        title="Confirmation!!"
      >
        <TextField
          autoFocus
          fullWidth
          id="amount"
          label="Amount"
          margin="dense"
          onChange={(e) => setPayout((p) => ({ ...p, amount: Number(e.target.value) }))}
          onFocus={(e) =>
            e.target.addEventListener(
              'wheel',
              function (e) {
                e.preventDefault();
              },
              { passive: false },
            )
          }
          type="number"
          value={payout?.amount}
        />
        <TextField
          fullWidth
          id="note"
          label="Note"
          margin="dense"
          onChange={(e) => setPayout((p) => ({ ...p, note: e.target.value || '' }))}
          value={payout?.note}
        />
      </ConfirmModal>
    </Box>
  );
}
