import { ReactNode, useCallback, useState } from 'react';

import { Upload } from '@mui/icons-material';
import { Box, Button, Dialog, Grid, SxProps, Theme } from '@mui/material';
import { Center, mergeSx, useNotifications } from '@zipptrip/zipptrip-canvas';
import { Camera, GalleryAdd } from 'iconsax-react';
import Cropper from 'react-easy-crop';
import ReactImageUploading, { ImageListType } from 'react-images-uploading';

import getCroppedImg, { compressImage } from '../../utils/images';

interface Props {
  imageSrc: string | undefined;
  index: number;
  placeholder?: ReactNode;
  setImageSrc: (imageSrc: string, index: number) => void;
  showButton?: boolean;
  ratio?: number;
  sx?: SxProps<Theme>;
}

const ImageUploader = ({
  imageSrc,
  index,
  placeholder,
  setImageSrc,
  showButton = true,
  ratio,
  sx = {},
}: Props) => {
  const { showError } = useNotifications();
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const onImageCrop = async (image: string) => {
    setSelectedImage(null);
    setImageSrc(image, index);
  };

  if (selectedImage) {
    return (
      <CropperContainer
        ratio={ratio}
        image={selectedImage}
        onImageCrop={onImageCrop}
        open={!!selectedImage}
      />
    );
  }

  return (
    <ReactImageUploading
      multiple={false}
      acceptType={['jpg', 'jpeg', 'png']}
      value={[{ dataURL: imageSrc }]}
      onError={(errors) => {
        if (errors?.maxFileSize) {
          showError('Maximum size of an image is 5MB');
        }
      }}
      onChange={async (value: ImageListType) => {
        if (!value[0].file) return;
        const compressed = await compressImage(value[0].file).catch((err) => {
          console.error(err);
          return;
        });

        if (!compressed) return;
        setSelectedImage(compressed);
      }}
      maxFileSize={1024 * 1024 * 5}
      maxNumber={1}
      dataURLKey="data_url"
    >
      {({ onImageUpdate }) => (
        <Box
          sx={mergeSx(
            {
              alignItems: 'center',
              aspectRatio: `${ratio}`,
              display: 'flex',
              overflow: 'hidden',
              position: 'relative',
              '& > img': { cursor: 'pointer', width: '100%' },
            },
            sx,
          )}
        >
          <ImageInner imageSrc={imageSrc} onImageUpdate={onImageUpdate} placeholder={placeholder} />

          {showButton && (
            <Button
              onClick={() => onImageUpdate(0)}
              startIcon={<Camera />}
              size="small"
              sx={{ bottom: '5%', position: 'absolute', right: '5%' }}
              variant="outlined"
            >
              Edit
            </Button>
          )}
        </Box>
      )}
    </ReactImageUploading>
  );
};

const ImageInner = ({
  imageSrc,
  onImageUpdate,
  placeholder,
}: {
  imageSrc: string | undefined;
  onImageUpdate: (index: number) => void;
  placeholder: ReactNode;
}) => {
  if (!!imageSrc) return <img src={imageSrc} onClick={() => onImageUpdate(0)} />;
  if (!!placeholder) return <>{placeholder}</>;
  return (
    <Box
      onClick={() => onImageUpdate(0)}
      sx={{
        alignItems: 'center',
        background: '#F9F9F9',
        border: '1px dashed var(--neutral-3)',
        borderRadius: '40px',
        cursor: 'pointer',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <GalleryAdd size={48} />
    </Box>
  );
};

const CropperContainer = ({
  image,
  onImageCrop,
  open,
  ratio,
}: {
  open: boolean;
  image: string;
  ratio?: number;
  onImageCrop: (image: string) => void;
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels);
      if (!croppedImage) return;
      onImageCrop(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, image, onImageCrop]);

  return (
    <Dialog fullWidth open={open} fullScreen>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: '100%' }}
        direction="column"
      >
        <Grid item>
          <Cropper
            image={image}
            crop={crop}
            aspect={ratio || 1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
          />
        </Grid>
        <Grid item>
          <Center height={10}>
            <Button
              onClick={showCroppedImage}
              variant="contained"
              color="primary"
              sx={{ color: 'white', borderColor: 'white', border: 2 }}
              startIcon={<Upload />}
            >
              Upload Image
            </Button>
          </Center>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ImageUploader;
