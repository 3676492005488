import { AchievementLevelId, AchievementType } from '@zipptrip/zipptrip-commons';

import { PLANS_ROUTE, PROFILE_ROUTE, REFERRAL_ROUTE } from '../../constants/routes';

export const ACHIEVEMENT_LEVEL_BADGE: Record<AchievementLevelId, string> = {
  [AchievementLevelId.Explorer]: '/badges/Explorer.svg',
  [AchievementLevelId.Adventurer]: '/badges/Adventurer.svg',
  [AchievementLevelId.Nomad]: '/badges/Nomad.svg',
  [AchievementLevelId.Globetrotter]: '/badges/Globetrotter.svg',
  [AchievementLevelId.Voyager]: '/badges/Voyager.svg',
};

export const ACHIEVEMENT_RELATED_PATH: Record<AchievementType, string> = {
  [AchievementType.CompleteProfile]: PROFILE_ROUTE,
  [AchievementType.ReferCreatorLv1]: REFERRAL_ROUTE,
  [AchievementType.ReferCreatorLv2]: REFERRAL_ROUTE,
  [AchievementType.ReferCreatorLv3]: REFERRAL_ROUTE,
  [AchievementType.ReferCreatorLv4]: REFERRAL_ROUTE,
  [AchievementType.ReferCreatorLv5]: REFERRAL_ROUTE,
  [AchievementType.PublishPlanLv1]: PLANS_ROUTE,
  [AchievementType.PublishPlanLv2]: PLANS_ROUTE,
  [AchievementType.PublishPlanLv3]: PLANS_ROUTE,
  [AchievementType.PublishPlanLv4]: PLANS_ROUTE,
  [AchievementType.PublishPlanLv5]: PLANS_ROUTE,
};
