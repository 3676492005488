import { useEffect, useState } from 'react';

import { Box, Button, GlobalStyles, Grid, Tab, Tabs, Typography } from '@mui/material';
import { HorizontalBlock } from '@zipptrip/zipptrip-canvas';
import { IPlan, IPlannerVisit, PlanStatusType } from '@zipptrip/zipptrip-commons';
import { Add } from 'iconsax-react';
import { StringParam, useQueryParam } from 'use-query-params';

import LoadingSpinner from '../components/loader/LoadingSpinner';
import NavBarToolbar from '../components/navbar/NavBarToolbar';
import PlanCard from '../components/plan/PlanCard';
import PlanLanding from '../components/plan/PlanLanding';
import PlanModal from '../components/plan/PlanModal';
import PlanSuggest from '../components/plan/PlanSuggest';
import PlanWarning from '../components/plan/PlanWarning';
import { auth, sendGAEvent } from '../config/firebase';
import { getPlansByPlannerId } from '../utils/plan';
import { getPlannerById } from '../utils/planner';

export type IPlans = Partial<
  Record<PlanStatusType, { bgcolor: string; items: IPlan[]; label: string; title: string }>
>;

const initPlans: IPlans = {
  [PlanStatusType.Draft]: {
    bgcolor: 'warning.main',
    items: [],
    label: 'Draft',
    title: 'Draft Plans',
  },
  [PlanStatusType.InReview]: {
    bgcolor: 'secondary.light',
    items: [],
    label: 'In review',
    title: 'In Review Plans',
  },
  [PlanStatusType.Published]: {
    bgcolor: 'primary.light',
    items: [],
    label: 'Published',
    title: 'Published Plans',
  },
};

const generatePlans = (planArray: IPlan[]) => {
  if (planArray?.length > 0) {
    return planArray.reduce(
      (acc, item) => {
        const status = item.status as PlanStatusType;
        acc[status]?.items?.push?.(item);
        return acc;
      },
      JSON.parse(JSON.stringify(initPlans)),
    );
  }
  return [];
};

const PlansPage = () => {
  const [plans, setPlans] = useState<IPlans>(initPlans);
  const [, setPrefilledCountry] = useQueryParam('country', StringParam);
  const [, setPrefilledCity] = useQueryParam('city', StringParam);
  const [selectedPlanId, setSelectedPlanId] = useQueryParam('create-edit-plan', StringParam);
  const [selectedPlanStatus, setSelectedPlanStatus] = useState<PlanStatusType>(
    PlanStatusType.Draft,
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [alreadyVisited, setAlreadyVisited] = useState<IPlannerVisit[]>([]);
  // const [isProfileBlocked, setIsProfileBlocked] = useState<boolean>(true);
  // const [showBlockedMessage, setShowBlockedMessage] = useState<boolean>(false);

  const listedPlans = plans[selectedPlanStatus]?.items || [];

  useEffect(() => {
    if (!auth.currentUser) return;
    setIsLoading(true);
    getPlannerById(auth.currentUser.uid).then((planner) => {
      if (planner) {
        setAlreadyVisited(planner.alreadyVisited || []);
        // setIsProfileBlocked(!!planner.isBlocked);
      }
    });
    getPlansByPlannerId()
      .then((planArray) => {
        setPlans(generatePlans(planArray));
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) return <LoadingSpinner />;

  // if (showBlockedMessage)
  //   return <UserBlockedDialog handleClose={() => setShowBlockedMessage(false)} />;

  const refreshPlans = () => {
    if (!auth.currentUser) return;
    setIsLoading(true);
    getPlansByPlannerId()
      .then((planArray) => {
        setPlans(generatePlans(planArray));
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };

  return (
    <Box sx={{ display: 'flex', flexFlow: 'column', gap: '32px', padding: '32px' }}>
      <NavBarToolbar>
        <HorizontalBlock height="100%">
          <Typography variant="T20M">Plans</Typography>
        </HorizontalBlock>
      </NavBarToolbar>

      <PlanWarning />

      <HorizontalBlock>
        <Typography variant="T28S">Your plans</Typography>
        <Button
          onClick={() => {
            setSelectedPlanId('1');
            sendGAEvent('open:create-plan-modal');
          }}
          startIcon={<Add />}
          variant="contained"
        >
          Create plan
        </Button>
      </HorizontalBlock>

      <Tabs
        allowScrollButtonsMobile
        scrollButtons="auto"
        sx={{ overflowX: 'auto' }}
        TabIndicatorProps={{
          children: <span />,
          sx: {
            backgroundColor: 'transparent',
            display: 'flex',
            justifyContent: 'center',
            '& > span': { bgcolor: 'text.primary', maxWidth: '16px', width: '100%' },
          },
        }}
        value={selectedPlanStatus}
        variant="scrollable"
      >
        {Object.entries(plans).map(([key, { label, items }]) => (
          <Tab
            key={key}
            label={`${label} (${items.length})`}
            onClick={() => setSelectedPlanStatus(key as PlanStatusType)}
            sx={({ palette: { text } }) => ({
              color: `${selectedPlanStatus === key ? 'inherit' : text.secondary} !important`,
              padding: '12px 24px',
            })}
            value={key}
          />
        ))}
      </Tabs>

      <Grid container spacing={4} sx={{ marginTop: '-40px' }}>
        {listedPlans.length === 0 && <PlanLanding selectedPlanStatus={selectedPlanStatus} />}
        {listedPlans.map((item) => (
          <Grid item key={item._id as string} xs={12} sm={6} md={4} lg={3}>
            <PlanCard plan={item} refreshPlans={refreshPlans} />
          </Grid>
        ))}
      </Grid>

      <PlanSuggest alreadyVisited={alreadyVisited} />

      {!!selectedPlanId ? (
        <>
          <GlobalStyles styles={{ body: { overflow: 'hidden !important' } }} />

          <PlanModal
            handleClose={() => {
              setSelectedPlanId(undefined);
              setPrefilledCountry(undefined);
              setPrefilledCity(undefined);
              refreshPlans();
            }}
            open
            refreshPlans={refreshPlans}
          />
        </>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default PlansPage;
