import { ReactNode } from 'react';

import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';

type FAQBoxProps = { question: string; details: ReactNode };
const FAQBox = ({ question, details }: FAQBoxProps) => {
  return (
    <Accordion sx={{ boxShadow: 0, width: '100%', margin: 0 }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ padding: 0, margin: 0 }}>
        <Typography
          sx={{
            fontSize: { xs: '18px', sm: '20px', md: '24px' },
            fontWeight: 500,
            lineHeight: '32px',
            margin: 0,
          }}
        >
          {question}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ margin: 0 }}>{details}</AccordionDetails>
    </Accordion>
  );
};

export default FAQBox;
