import { ReactNode, SyntheticEvent, useEffect, useRef, useState } from 'react';

import { faSuitcaseRolling, faTag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  ListItemText,
  MenuItem,
  SxProps,
  TextField,
  TextFieldProps,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Portal from '@mui/material/Portal';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import {
  Center,
  FormAutocomplete,
  FormSelect,
  FormTextField,
  HorizontalBlock,
  LoaderCircle,
  useInputShrink,
} from '@zipptrip/zipptrip-canvas';
import {
  COUNTRIES,
  City,
  FoodPreference,
  IPlan,
  Season,
  TravellersType,
  TripType,
} from '@zipptrip/zipptrip-commons';
import { Form, useFormikContext } from 'formik';
import {
  Airplane,
  Building,
  Calendar,
  CloudNotif,
  DollarSquare,
  Edit2,
  Flag2,
  People,
  Reserve,
} from 'iconsax-react';
import StepWizard, { StepWizardChildProps } from 'react-step-wizard';

import AutoGenButton from './AutoGenButton';
import styles from './PlanForm.styles';
import { getImageUrl } from '../../utils/images';
import { getCountryInfo } from '../../utils/planner';
import ImageUploader from '../imageUploader/ImageUploader';

type CountryOption = { name: string; code: string };
type Props = { goToPage: (page: number) => void };

const useStyles = makeStyles(styles);

const PublicInfoForm = ({ goToPage }: Props) => {
  const classes = useStyles();
  const { errors, setFieldValue, validateField, values } = useFormikContext<IPlan>();
  const { inputProps, inputLabelProps, textFieldProps } = useInputShrink(values);
  const [autoGenError, setAutoGenError] = useState<string>();
  const [cities, setCities] = useState<City[]>([]);
  const wizardRef = useRef<any>();

  useEffect(() => {
    if (cities.length > 0) setFieldValue('cities', []);
    if (values.countries?.length) {
      // Update city list to show in the dropdown:
      getCountryInfo(values.countries[0] || '').then((res) => setCities(res?.cities || []));
    }
  }, [JSON.stringify(values.countries || [])]);

  return (
    <Box sx={{ margin: '32px auto', width: 'min(90%, 720px)' }}>
      <Form>
        <StepWizard
          instance={(wizard) => {
            wizardRef.current = wizard;
          }}
          isLazyMount
          nav={<PublicInfoNavBar goToPage={goToPage} />}
        >
          {/* Step 1 */}
          <PublicInfoStepWrapper>
            <Grid container>
              <Grid className={classes.formField} item xs={12}>
                <FormTextField
                  shrinkProps={textFieldProps}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{
                          '& > svg': {
                            color: 'var(--secondary-6)',
                            fontSize: '20px',
                            transform: 'rotate(7.241deg)',
                            width: '22px',
                          },
                        }}
                      >
                        <FontAwesomeIcon icon={faSuitcaseRolling} />
                      </InputAdornment>
                    ),
                  }}
                  autoFocus
                  required
                  label="Trip Title"
                  placeholder="Write an attractive title..."
                  field="title"
                />
              </Grid>

              <Grid container columnSpacing={1} item xs={12}>
                <Grid item xs={12} sm={6} className={classes.formField}>
                  <FormTextField
                    field="price"
                    label="Plan Price ($6 - $50)"
                    required
                    shrinkProps={textFieldProps}
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" sx={{ color: 'var(--secondary-6)' }}>
                          <DollarSquare variant="Bold" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography color="textSecondary" variant="T16R">
                            $
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} className={classes.formField}>
                  <FormTextField
                    shrinkProps={textFieldProps}
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" sx={{ color: 'var(--secondary-6)' }}>
                          <Calendar variant="Bold" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography color="textSecondary" variant="T16R">
                            day
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    required
                    label="Trip Duration"
                    field="duration"
                  />
                </Grid>
              </Grid>

              <Grid className={classes.formField} item xs={12}>
                <FormAutocomplete
                  field="countries"
                  getOptionLabel={(option: CountryOption) => option?.name || ''}
                  isOptionEqualToValue={(option: CountryOption, value: CountryOption) =>
                    option?.code === value?.code
                  }
                  onChange={(event: SyntheticEvent, value: CountryOption) => {
                    setFieldValue('countries', value?.code ? [value.code] : []);
                    setTimeout(() => validateField('countries'), 100);
                  }}
                  options={COUNTRIES}
                  renderInput={(params: TextFieldProps) => (
                    <TextField
                      {...params}
                      {...inputProps('countries')}
                      InputLabelProps={{
                        ...params.InputLabelProps,
                        ...inputLabelProps('countries'),
                      }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <Box sx={{ color: 'var(--secondary-6)', display: 'flex' }}>
                              <Flag2 variant="Bold" />
                            </Box>
                            {params.InputProps?.startAdornment}
                          </>
                        ),
                      }}
                      label="Country"
                      placeholder={values.countries?.[0] ? '' : 'Select your country'}
                    />
                  )}
                  value={COUNTRIES.find((country) => country.code === values.countries[0]) || ''}
                />
              </Grid>

              <Grid className={classes.formField} item xs={12}>
                <FormAutocomplete
                  disabled={!cities.length}
                  field="cities"
                  filterOptions={(options: City[], state: any) =>
                    options.filter((option) =>
                      option.name.toLowerCase().includes(state.inputValue.toLowerCase()),
                    )
                  }
                  filterSelectedOptions
                  getOptionLabel={(option: City) =>
                    `${option.name} ${option.state ? ` (${option.state})` : ''}`
                  }
                  isOptionEqualToValue={(option: City, value: City) => option?.name === value?.name}
                  multiple
                  onChange={(event: SyntheticEvent, value: City[]) => {
                    setFieldValue(
                      'cities',
                      value.map((city: City) => city.name),
                    );
                  }}
                  options={cities}
                  renderInput={(params: TextFieldProps) => (
                    <TextField
                      {...params}
                      {...inputProps('cities')}
                      InputLabelProps={{
                        ...params.InputLabelProps,
                        ...inputLabelProps('cities'),
                      }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <Box sx={{ color: 'var(--secondary-6)', display: 'flex' }}>
                              <Building variant="Bold" />
                            </Box>
                            {params.InputProps?.startAdornment}
                          </>
                        ),
                      }}
                      label="Cities"
                      placeholder={
                        values.cities?.length ? '' : 'Select the cities of the trip plan'
                      }
                    />
                  )}
                  value={values.cities.map((city) => {
                    const cityInfo = cities.find((c) => c.name === city);
                    return cityInfo
                      ? { name: cityInfo.name, state: cityInfo.state }
                      : { name: city, state: '' };
                  })}
                />
              </Grid>

              <Grid
                className={classes.formField}
                item
                xs={12}
                sx={{ overflow: 'visible !important' }}
              >
                <FormTextField
                  field="description"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ color: 'var(--secondary-6)', position: 'relative', top: '8px' }}
                      >
                        <Edit2 variant="Bold" />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{ position: 'absolute', bottom: '36px', right: '16px' }}
                      >
                        <AutoGenButton
                          setAutoGenError={setAutoGenError}
                          setFieldValue={setFieldValue}
                          isValid={
                            !!values.title &&
                            !!values.duration &&
                            !!values.countries &&
                            !!values.cities &&
                            !!values.price
                          }
                        />
                      </InputAdornment>
                    ),
                    sx: { alignItems: 'baseline', position: 'relative' },
                  }}
                  label="Description (300-600 characters)"
                  maxRows={6}
                  minRows={6}
                  multiline
                  onFocus={() => setAutoGenError('')}
                  placeholder="Write a short description (300-600 characters) about the trip plan. Mention the highlights, activities, type of places and any cool thing to attract the travelers."
                  shrinkProps={textFieldProps}
                />

                <Typography variant="T14R" sx={{ color: 'var(--red-6, #F5222D)' }}>
                  {autoGenError}
                </Typography>
              </Grid>
            </Grid>
          </PublicInfoStepWrapper>

          {/* Step 2 */}
          <PublicInfoStepWrapper>
            <Grid container>
              <Grid className={classes.formField} item xs={12}>
                <FormSelect
                  field="travellersTypes"
                  inputLabelShrinkProps={inputLabelProps}
                  inputShrinkProps={inputProps}
                  label="Companion Types"
                  labelId="traveler-type-label"
                  multiple
                  startAdornment={
                    <InputAdornment position="start" sx={{ color: 'var(--secondary-6)' }}>
                      <People variant="Bold" />
                    </InputAdornment>
                  }
                >
                  {[
                    TravellersType.Solo,
                    TravellersType.Couple,
                    TravellersType.Friends,
                    TravellersType.Family,
                  ].map((type) => (
                    <MenuItem key={type} value={type}>
                      <Checkbox checked={values.travellersTypes?.indexOf(type) > -1} />
                      <ListItemText primary={type} />
                    </MenuItem>
                  ))}
                </FormSelect>
              </Grid>

              <Grid className={classes.formField} item xs={12}>
                <FormSelect
                  field="tripTypes"
                  inputLabelShrinkProps={inputLabelProps}
                  inputShrinkProps={inputProps}
                  label="Trip Types"
                  labelId="trip-type-label"
                  multiple
                  startAdornment={
                    <InputAdornment position="start" sx={{ color: 'var(--secondary-6)' }}>
                      <Airplane variant="Bold" />
                    </InputAdornment>
                  }
                >
                  {Object.values(TripType).map((type) => (
                    <MenuItem key={type} value={type}>
                      <Checkbox checked={values.tripTypes?.indexOf(type) > -1} />
                      <ListItemText primary={type} />
                    </MenuItem>
                  ))}
                </FormSelect>
              </Grid>

              <Grid className={classes.formField} item xs={12}>
                <FormSelect
                  field="bestTimeToVisit"
                  inputLabelShrinkProps={inputLabelProps}
                  inputShrinkProps={inputProps}
                  label="Best season to visit"
                  labelId="season-label"
                  multiple
                  startAdornment={
                    <InputAdornment position="start" sx={{ color: 'var(--secondary-6)' }}>
                      <CloudNotif variant="Bold" />
                    </InputAdornment>
                  }
                >
                  {Object.values(Season).map((type) => (
                    <MenuItem key={type} value={type}>
                      <Checkbox checked={values.bestTimeToVisit?.indexOf(type) > -1} />
                      <ListItemText primary={type} />
                    </MenuItem>
                  ))}
                </FormSelect>
              </Grid>

              <Grid className={classes.formField} item xs={12}>
                <FormSelect
                  field="foodPreferences"
                  inputLabelShrinkProps={inputLabelProps}
                  inputShrinkProps={inputProps}
                  label="Food Types"
                  labelId="food-type-label"
                  multiple
                  startAdornment={
                    <InputAdornment position="start" sx={{ color: 'var(--secondary-6)' }}>
                      <Reserve variant="Bold" />
                    </InputAdornment>
                  }
                >
                  {Object.values(FoodPreference).map((type) => (
                    <MenuItem key={type} value={type}>
                      <Checkbox checked={values.foodPreferences?.indexOf(type) > -1} />
                      <ListItemText primary={type} />
                    </MenuItem>
                  ))}
                </FormSelect>
              </Grid>

              <Grid className={classes.formField} item xs={12}>
                <FormAutocomplete
                  clearOnBlur
                  defaultValue={[]}
                  field="tags"
                  freeSolo
                  multiple
                  options={[]}
                  renderInput={(params: TextFieldProps) => (
                    <TextField
                      {...params}
                      {...inputProps('tags')}
                      InputLabelProps={{
                        ...params.InputLabelProps,
                        ...inputLabelProps('tags'),
                      }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <Box
                              sx={{
                                '& > svg': {
                                  color: 'var(--secondary-6)',
                                  display: 'flex',
                                  fontSize: '20px',
                                  width: '24px',
                                },
                              }}
                            >
                              <FontAwesomeIcon icon={faTag} />
                            </Box>
                            {params.InputProps?.startAdornment}
                          </>
                        ),
                      }}
                      label="Tags"
                      placeholder="Write tags relevant to the trip plan and click enter."
                    />
                  )}
                  sx={{ marginTop: '-1px' }}
                />
              </Grid>

              <Grid className={classes.formField} container spacing={2}>
                {values.photos.map((item: string, index) => {
                  const setImageSrc = (path: string, index: number) => {
                    const newPhotos = [...values.photos];
                    newPhotos[index] = path;
                    setFieldValue('photos', newPhotos);
                  };
                  return (
                    <Grid key={index} item xs={12} sm={6} lg={3}>
                      <ImageUploader
                        imageSrc={getImageUrl(item)}
                        ratio={1.33}
                        index={index}
                        setImageSrc={setImageSrc}
                        showButton={false}
                      />
                    </Grid>
                  );
                })}
                {errors.photos && (
                  <Grid item xs={12}>
                    <Center height={2}>
                      <div className={classes.error}>{errors.photos} </div>
                    </Center>
                  </Grid>
                )}
              </Grid>

              <Grid item xs={12} className={classes.formField}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.inappropriateForChildren}
                      onChange={() =>
                        setFieldValue('inappropriateForChildren', !values.inappropriateForChildren)
                      }
                      name="inappropriateForChildren"
                      value={values.inappropriateForChildren}
                      color="primary"
                    />
                  }
                  label="Itinerary includes events/activities that are not suitable for individuals under 18."
                  sx={{ margin: 0 }}
                />
              </Grid>
            </Grid>
          </PublicInfoStepWrapper>
        </StepWizard>
      </Form>
    </Box>
  );
};

export default PublicInfoForm;

const PublicInfoNavBar = ({
  currentStep,
  goToPage,
  nextStep,
  previousStep,
}: { goToPage: (page: number) => void } & Partial<StepWizardChildProps>) => {
  const { errors, initialValues, isSubmitting, isValid, submitForm, values } =
    useFormikContext<IPlan>();
  const largerThanSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const portalId = largerThanSm ? 'plan-modal-toolbar' : 'plan-modal-bottom';

  const isFirstStepValid = Object.keys(errors || {}).every(
    (key) => !['title', 'duration', 'description', 'countries', 'cities'].includes(key),
  );

  const goNextStep = async () => {
    if (
      JSON.stringify(initialValues) === JSON.stringify(values) &&
      !!values?._id &&
      isFirstStepValid
    ) {
      return nextStep?.();
    }
    await submitForm();
    if (isFirstStepValid) nextStep?.();
  };

  return (
    <Portal container={document.getElementById(portalId)}>
      <Tooltip title={Object.values(errors)?.[0]}>
        <span>
          <Button
            disabled={isSubmitting || !isValid}
            onClick={submitForm}
            translate="no"
            size="small"
            startIcon={isSubmitting && <LoaderCircle />}
            variant="text"
          >
            <span translate="yes">Save changes</span>
          </Button>
        </span>
      </Tooltip>

      {currentStep === 2 && (
        <Button onClick={previousStep} size="small" variant="outlined">
          Back
        </Button>
      )}

      <Tooltip title={Object.values(errors)?.[0]}>
        <span>
          <Button
            disabled={isSubmitting || (currentStep === 1 ? !isFirstStepValid : !isValid)}
            onClick={() => {
              if (currentStep === 1) goNextStep();
              else goToPage(1);
            }}
            size="small"
            variant="contained"
          >
            {currentStep === 1 ? 'Next' : 'Go to Itinerary'}
          </Button>
        </span>
      </Tooltip>
    </Portal>
  );
};

const activePageSx: SxProps = {
  bgcolor: 'text.primary',
  width: '32px',
};

const nonActivePageSx: SxProps = {
  bgcolor: 'text.border',
  width: '16px',
};

const PublicInfoStepWrapper = ({
  children,
  currentStep,
}: {
  children: ReactNode;
} & Partial<StepWizardChildProps>) => {
  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexFlow: 'column',
          gap: '16px',
          marginBottom: '32px',
        }}
      >
        <Typography variant="T20B">{currentStep === 1 ? 'Basic' : 'Detail'} information</Typography>

        <HorizontalBlock columnGap="8px" sx={{ '& > div': { borderRadius: '8px', height: '8px' } }}>
          <Box sx={currentStep === 1 ? activePageSx : nonActivePageSx} />
          <Box sx={currentStep === 2 ? activePageSx : nonActivePageSx} />
        </HorizontalBlock>

        <Typography variant="T16R">
          {currentStep === 1
            ? 'Let’s start creating a plan! Fill basic information of your trip.'
            : 'Let’s fill detailed information and add some attractive picture.'}
        </Typography>
      </Box>

      {children}
    </>
  );
};
