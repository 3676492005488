import * as Yup from 'yup';

export const getPlannerBaseStep1ValidationSchema = () => {
  return Yup.object().shape({
    name: Yup.string().required('Name is required').min(5, 'Name must be at least 5 characters'),
    email: Yup.string().email().required('Email is required'),
  });
};

export const getPlannerBaseStep2ValidationSchema = (isAgency?: boolean) => {
  if (isAgency) {
    return Yup.object().shape({
      websiteLink: Yup.string().required('website is required'),
    });
  }
  return Yup.object()
    .shape({
      youtubeLink: Yup.string().trim(),
      facebookLink: Yup.string().trim(),
      instagramLink: Yup.string().trim(),
      twitterLink: Yup.string().trim(),
      pinterestLink: Yup.string().trim(),
      mediaKitLink: Yup.string().trim(),
    })
    .test(
      'at-least-one-social-link',
      'You must provide at least one social media link',
      (value) => {
        const {
          youtubeLink,
          facebookLink,
          twitterLink,
          instagramLink,
          pinterestLink,
          mediaKitLink,
        } = value;

        return (
          !!youtubeLink ||
          !!facebookLink ||
          !!twitterLink ||
          !!instagramLink ||
          !!pinterestLink ||
          !!mediaKitLink
        );
      },
    );
};
