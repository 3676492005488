import { Box } from '@mui/material';
import { HorizontalBlock } from '@zipptrip/zipptrip-canvas';

const Stepper = ({ total, currentId }: { total: number; currentId: number }) => {
  const current = <img src="/pagination/current.svg" />;
  const unselected = <img src="/pagination/unselected.svg" />;
  return (
    <HorizontalBlock sx={{ gap: '8px' }}>
      {Array(total)
        .fill(0)
        .map((_, idx) => {
          return <Box key={idx}>{idx == currentId - 1 ? current : unselected}</Box>;
        })}
    </HorizontalBlock>
  );
};

export default Stepper;
