import { useState } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { DotPulse } from '@uiball/loaders';
import { HorizontalBlock, LoaderCircle, VerticalBlock } from '@zipptrip/zipptrip-canvas';
import { IPlan } from '@zipptrip/zipptrip-commons';
import { useFormikContext } from 'formik';
import { Magicpen } from 'iconsax-react';

import { AutoGenType, autoGenerateDescription } from '../../utils/autoGenerate';

const AutoGenButton = ({ setAutoGenError, setFieldValue, isValid }: any) => {
  const { values } = useFormikContext<IPlan>();
  const [text, setText] = useState<string>();
  const { mutate: handleAutoGenerate, isLoading } = useMutation(
    async (/* setFieldValue: any */) => {
      // check is field filled
      if (isValid) {
        setAutoGenError(undefined);

        const data = await autoGenerateDescription(values, { type: AutoGenType.PLAN });
        // setFieldValue('description', data);
        setText(data);
      } else {
        setAutoGenError(
          'Please provide the information fields above, or write a short description first',
        );
      }
    },
    {
      //   onSuccess: () => null,
      onError: () => setText(undefined),
    },
  );

  return (
    <>
      {(isLoading || !!text) && (
        <Box
          sx={{
            position: 'absolute',
            bottom: '36px',
            right: 0,
            borderRadius: '24px',
            padding: '16px',
            background: 'var(--neutral-1, #FFF)',
            boxShadow: '0px 8px 24px 0px rgba(0, 0, 0, 0.15)',
            width: '400px',
            transitionY: '-400px',
            zIndex: 9999,
            overflow: 'visible',
          }}
        >
          {!!text ? (
            <VerticalBlock>
              <Typography
                variant="T14R"
                sx={{ color: 'var(--neutral-10, #031616)', textWrap: 'wrap' }}
              >
                {text}
              </Typography>
              <HorizontalBlock sx={{ gap: '8px', justifyContent: 'flex-end', width: '100%' }}>
                <Button
                  onClick={() => {
                    setFieldValue('description', text);
                    setText(undefined);
                  }}
                  startIcon={
                    <Box sx={{ width: '20px' }}>
                      <img
                        src="/icons/tick.svg"
                        style={{ objectFit: 'contain', verticalAlign: 'middle' }}
                      />
                    </Box>
                  }
                  sx={{ padding: '5px' }}
                >
                  Accept
                </Button>

                <Button
                  onClick={() => {
                    setFieldValue('description', '');
                    setText(undefined);
                  }}
                  startIcon={
                    <Box sx={{ width: '20px' }}>
                      <img
                        src="/icons/cross.svg"
                        style={{ objectFit: 'contain', verticalAlign: 'middle' }}
                      />
                    </Box>
                  }
                  sx={{ padding: '5px' }}
                >
                  Discard
                </Button>
              </HorizontalBlock>
            </VerticalBlock>
          ) : (
            <HorizontalBlock>
              <HorizontalBlock>
                <Magicpen size={20} />
                <Typography variant="T14M" sx={{ margin: '0 5px' }}>
                  AI is writing
                </Typography>
                <DotPulse size={30} speed={1.3} color="var(--primary-6, #009F9A)" />
              </HorizontalBlock>
              {/* <HorizontalBlock>
                <Button sx={{ padding: '2px' }}>
                  <ArrowRotateLeft />
                  <Typography variant="T14M" sx={{ color: 'var(--primary-6, #009F9A)', ml: '5px' }}>
                    Try again
                  </Typography>
                </Button>
                <Button sx={{ padding: '2px', ml: '5px' }}>
                  <PauseCircle />
                  <Typography variant="T14M" sx={{ color: 'var(--primary-6, #009F9A)', ml: '5px' }}>
                    Stop
                  </Typography>
                </Button>
              </HorizontalBlock> */}
            </HorizontalBlock>
          )}
        </Box>
      )}
      <Button
        disabled={isLoading || !!text}
        onClick={() => handleAutoGenerate(setFieldValue)}
        size="small"
        startIcon={isLoading ? <LoaderCircle /> : <Magicpen />}
        variant="outlined"
      >
        {!!values?.description ? 'Improve writing with AI' : 'Generate description with AI'}
      </Button>
    </>
  );
};
export default AutoGenButton;
