import { Box } from '@mui/material';
import { ConfirmModal } from '@zipptrip/zipptrip-canvas';
import { useNavigate } from 'react-router-dom';

import { PROFILE_ROUTE } from '../../constants/routes';

interface Prop {
  onCancel?: () => void;
  onConfirm?: () => void;
  open: boolean;
}

export default function IncompleteProfileDialog({ onCancel, onConfirm, open }: Prop) {
  const navigate = useNavigate();
  return (
    <ConfirmModal
      confirmProps={{ color: 'inherit' }}
      confirmText="Update profile"
      onCancel={() => (onCancel ? onCancel() : null)}
      onConfirm={() => {
        onConfirm ? onConfirm() : null;
        navigate(PROFILE_ROUTE);
      }}
      open={open}
      title={
        <>
          <Box
            sx={{ display: 'flex', justifyContent: 'center', '& > img': { padding: '20px 25px' } }}
          >
            <img src="/keyword.png" />
          </Box>
          <Box sx={{ marginTop: '24px' }}>Update profile information</Box>
        </>
      }
    >
      Your profile will look outstanding and attract more tourists when you fully update your
      personal information. It doesn't take much time! Start creating your own style now.
    </ConfirmModal>
  );
}
