import { Typography } from '@mui/material';
import { VerticalBlock } from '@zipptrip/zipptrip-canvas';
import { PlanStatusType } from '@zipptrip/zipptrip-commons';

const info: Partial<Record<PlanStatusType, string>> = {
  [PlanStatusType.Draft]:
    "You haven't created any plans yet. Create a new one today and share with your followers.",
  [PlanStatusType.InReview]: "You don't have any plans in review now.",
  [PlanStatusType.Published]: "You don't have any published plans now.",
};

interface Props {
  selectedPlanStatus: PlanStatusType;
}

export default function PlanLanding({ selectedPlanStatus }: Props) {
  return (
    <VerticalBlock sx={{ gap: '32px', padding: '32px', width: '100%' }}>
      <img src="/itinerary-icon.png" alt="empty" width="200px" />

      <Typography textAlign="center" variant="h6">
        {info[selectedPlanStatus]}
      </Typography>
    </VerticalBlock>
  );
}
