import React, { useEffect, useState } from 'react';

import { Box, Button, Fade, Slider, SxProps, Typography } from '@mui/material';
import { Center, CentralModal, HorizontalBlock, VerticalBlock } from '@zipptrip/zipptrip-canvas';

import FAQBox from './FAQ';
import { faqContent } from './faqContent';
import Footer from './Footer';
import Section from './Section';
import TopBar from './TopBar';
import TourStep from './TourStep';
import { numberWithCommas } from '../../utils/commons';
import LoginModal from '../auth/LoginModal';
import SignupModal from '../signup/SignupModal';

const LandingTitle = ({ children, sx }: { children: React.ReactNode; sx?: SxProps }) => (
  <Typography
    sx={{
      fontFamily: 'Playfair Display',
      fontSize: { xs: '30px', sm: '40px', md: '48px' },
      textAlign: 'center',
      textTransform: 'capitalize',
      ...(sx ? sx : {}),
    }}
  >
    {children}
  </Typography>
);

const LandingContainer = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const [peopleCount, setPeopleCount] = useState(5000);
  const [isOpenEarningModal, setIsOpenEarningModal] = useState(false);
  const [isOpenSignupModal, setIsOpenSignupModal] = useState(false);
  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const join = searchParams.get('join') || '';
    if (!!join) setIsOpenSignupModal(true);

    function handleResize() {
      setIsMobile(window.innerWidth < 600);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') setPeopleCount(newValue);
  };
  let isDev = true;
  useEffect(() => {
    isDev = window.location.host === 'creator-stage.zipptrip.com';
  }, []);

  return (
    <>
      <TopBar
        setIsOpenSignupModal={setIsOpenSignupModal}
        setIsOpenLoginModal={setIsOpenLoginModal}
      />

      <LoginModal open={isOpenLoginModal} onClose={() => setIsOpenLoginModal(false)} />

      <SignupModal
        open={isOpenSignupModal}
        closeButton={true}
        onClose={() => setIsOpenSignupModal(false)}
      />

      <CentralModal
        open={isOpenEarningModal}
        closeButton={true}
        onClose={() => setIsOpenEarningModal(false)}
        sx={{ padding: '32px !important', width: 'min(90vw, 700px)' }}
      >
        <Center height="auto" sx={{ gap: '24px' }}>
          <Typography sx={{ marginTop: '24px', textAlign: 'center' }} variant="T28B">
            How we estimate your earning potential
          </Typography>
          <Typography component="div" sx={{ fontSize: '16px', textAlign: 'center' }}>
            You will <p style={{ color: 'var(--primary-8)', display: 'inline' }}>get 85%</p> of
            total revenue. <br />
            <b>Step 1:</b> Total revenue generated from the sale of 20 USD itinerary to 5000 people
            = 20 USD/itinerary * 5000 people = 100,000 USD. <br />
            <b>Step 2:</b> Total revenue share given to influencers = 85% of total revenue Total
            revenue share = 0.85 * 100,000 USD = 85,000 USD.
          </Typography>

          <Button onClick={() => setIsOpenEarningModal(false)} size="large" variant="contained">
            I got it!
          </Button>
        </Center>
      </CentralModal>

      <Box sx={{ display: 'flex', flexDirection: 'column', paddingBottom: isMobile ? '164px' : 0 }}>
        <Section sx={{ justifyContent: 'space-around', gap: { xs: 0, sm: '40px', md: '100px' } }}>
          <Box sx={{ flexGrow: 1, flexBasis: 520, maxWidth: '530px', alignItems: 'center' }}>
            <Fade in={true} timeout={3000}>
              <Typography
                component="div"
                sx={{ fontFamily: 'Playfair Display', fontSize: { xs: 50, sm: 60, md: 72 } }}
              >
                <Typography
                  component="span"
                  sx={{
                    color: 'inherit',
                    fontSize: 'inherit',
                    fontWeight: 'inherit',
                    fontFamily: 'inherit',
                    textDecoration: 'underline',
                    textDecorationColor: 'var(--secondary-6)',
                  }}
                >
                  Inspire
                </Typography>{' '}
                the next generation of travellers.
              </Typography>
            </Fade>
            <Fade in={true} timeout={3000}>
              <Typography sx={{ fontSize: { xs: 18, md: 24 }, marginTop: '32px' }}>
                We are a marketplace where you can sell your own trip plans utilizing your
                experiences.
              </Typography>
            </Fade>
          </Box>
          <Box
            sx={(theme) => ({
              flexGrow: 1,
              flexBasis: 624,
              maxWidth: '624px',

              '& > img': {
                maxWidth: '100%',
                marginTop: theme.spacing(2),
                width: '100%',
                objectFit: 'contain',
              },
            })}
          >
            <img src="/inspire_image.png" alt="1" />
          </Box>
        </Section>

        <Section containerSx={{ bgcolor: 'secondary.selectedBackground' }}>
          <Box sx={{ width: '100%' }}>
            <Center height="100%">
              <LandingTitle>Benefits for Travelers</LandingTitle>
              <HorizontalBlock sx={{ width: '100%', flexWrap: 'wrap', justifyContent: 'center' }}>
                <Box sx={{ maxWidth: '577px', flexBasis: { xs: '380px', md: '350px' } }}>
                  <img src="/traveller.png" alt="traveller" style={{ width: '100%' }} />
                </Box>
                <Center height="100%" sx={{ flexBasis: { xs: '420px', md: '390px' } }}>
                  <VerticalBlock sx={{ alignItems: 'flex-start' }}>
                    <Typography sx={{ fontSize: { xs: '20px', sm: '24px' }, fontWeight: '600' }}>
                      Travel Like a Pro!
                    </Typography>
                    <Typography sx={{ fontSize: { xs: '14px', sm: '16px' }, maxWidth: '508px' }}>
                      Elevate your travel game with expertly crafted plans! We empower you to
                      journey like a pro, even if it's your first time.
                    </Typography>
                  </VerticalBlock>
                </Center>
              </HorizontalBlock>

              <HorizontalBlock sx={{ flexWrap: 'wrap', justifyContent: 'center', width: '100%' }}>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexBasis: { xs: '420px', md: '390px' },
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                  order={{ xs: 1, md: 0 }}
                >
                  <VerticalBlock sx={{ alignItems: 'flex-start' }}>
                    <Typography sx={{ fontSize: { xs: '20px', sm: '24px' }, fontWeight: '600' }}>
                      Virtual guide
                    </Typography>
                    <Typography sx={{ fontSize: { xs: '14px', sm: '16px' }, maxWidth: '508px' }}>
                      Experience the future of guided travel with the ZippTrip app. Seamlessly
                      access your itinerary from professionals, receive comprehensive guidance, and
                      timely notifications on where and when to begin your journey – all packed in a
                      virtual guide right at your fingertips!
                    </Typography>
                  </VerticalBlock>
                </Box>
                <Box
                  sx={{
                    flexBasis: { xs: '380px', md: '350px' },
                    maxWidth: '577px',
                    position: 'relative',
                  }}
                  order={{ xs: 0, md: 1 }}
                >
                  <img src="/smartphone.png" alt="traveller" style={{ width: '100%' }} />
                </Box>
              </HorizontalBlock>
            </Center>
          </Box>
        </Section>

        <Section>
          <Box sx={{ width: '100%' }}>
            <Center height="100%">
              <LandingTitle>Only 4 steps to sell your plan</LandingTitle>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '32px',
                  justifyContent: { sm: 'center', md: 'space-between' },
                  marginTop: { xs: '36px', sm: '48px', md: '96px' },
                  width: '100%',
                }}
              >
                <TourStep id="1">Complete your profile</TourStep>
                <TourStep id="2">Connect bank account</TourStep>
                <TourStep id="3">Create your travel plan</TourStep>
                <TourStep id="4">Promote your listing</TourStep>
              </Box>
            </Center>
          </Box>
        </Section>

        <Section
          containerSx={{
            background: 'radial-gradient(50% 50% at 50% 50%, #D3FFE2 30.75%, #78E6FF 100%)',
          }}
          sx={{ posotion: 'relative' }}
        >
          <Box sx={{ width: '100%' }}>
            <Center height="100%" sx={{ position: 'relative' }}>
              <Box sx={{ position: 'absolute', top: { xs: 0, ms: '-50px', md: '-70px' } }}>
                <img src="/wave.png" style={{ width: '100%' }} />
              </Box>
              <Fade in={true} timeout={3000}>
                <Box sx={{ marginTop: '100px' }}>
                  <Typography sx={{ letterSpacing: '2.4px' }} variant="T20S">
                    WITH ZIPPTRIP
                  </Typography>
                </Box>
              </Fade>
              <LandingTitle>Turn Travel Experiences into Earnings</LandingTitle>
              <Fade in={true} timeout={3000}>
                <Typography
                  sx={{
                    fontSize: { xs: '60px', sm: '70px', md: '84px' },
                    fontWeight: 500,
                    margin: '48px 0',
                    textAlign: 'center',
                  }}
                >
                  ${(peopleCount * 20 * 0.85).toLocaleString()}
                </Typography>
              </Fade>
              <Fade in={true} timeout={3000}>
                <Typography sx={{ textAlign: 'center' }} variant="T24R">
                  <u>$20</u> itinerary * {numberWithCommas(peopleCount)} people
                </Typography>
              </Fade>
              <Box sx={{ width: { xs: '250px', sm: '400px', md: '600px' } }}>
                <Slider
                  aria-label="Default"
                  defaultValue={peopleCount}
                  max={10000}
                  min={0}
                  onChange={handleSliderChange}
                  sx={{ color: 'text.primary', margin: '48px 0', width: '100%' }}
                  value={peopleCount}
                  valueLabelDisplay="auto"
                />
              </Box>
              <Button onClick={() => setIsOpenEarningModal(true)}>
                <Typography
                  sx={{
                    color: 'text.secondary',
                    fontSize: { xs: '16px', sm: '20px', md: '24px' },
                    textAlign: 'center',
                    textDecoration: 'underline',
                  }}
                >
                  Learn how we estimate your earnings
                </Typography>
              </Button>
            </Center>
          </Box>
        </Section>

        <Section
          containerSx={{
            background: '#F5F5F5',
          }}
          sx={{ posotion: 'relative' }}
        >
          <HorizontalBlock
            sx={{
              width: '100%',
              alignItems: 'flex-start',
              columnGap: { xs: '20px', md: '100px' },
              rowGap: { xs: '20px', md: 0 },
              flexDirection: isMobile ? 'column' : 'row',
            }}
          >
            <Box sx={{ flexGrow: 1, flexBasis: isMobile ? 'unset' : '200px' }}>
              <LandingTitle sx={{ flexGrow: 1, textAlign: 'left' }}>
                What's more, you can earn passive income with our Referral Program
              </LandingTitle>
            </Box>
            <VerticalBlock
              sx={{
                flexGrow: 1,
                flexBasis: isMobile ? 'unset' : '200px',
                gap: '32px',
                alignItems: 'flex-start',
              }}
            >
              <Typography variant="T16R">
                Spread the word about our travel platform and score perks for each new user you
                bring in. With Zipptrip's referral program, you can kick up your feet, enjoy your
                travels, and watch your referrals earn you passive income while you dive into your
                own adventures.
              </Typography>

              <a
                href={
                  isDev ? 'https://stage.zipptrip.com/referrals' : 'https://zipptrip.com/referrals'
                }
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none', color: 'var(--neutral-3, #D2ECDA)' }}
              >
                <Button variant="contained">
                  <Typography variant="T16M" sx={{ color: 'inherit' }}>
                    Learn about Referral Program
                  </Typography>
                </Button>
              </a>
            </VerticalBlock>
          </HorizontalBlock>
        </Section>

        <Section containerSx={{ minHeight: '500px' }}>
          <HorizontalBlock
            sx={{
              width: '100%',
              alignItems: 'flex-start',
              gap: { xs: '20px', md: '100px' },
              flexWrap: 'wrap',
            }}
          >
            <VerticalBlock
              sx={{
                flexGrow: 1,
                flexBasis: { xs: 'unset', md: '200px' },
                gap: '32px',
                alignItems: 'flex-start',
              }}
            >
              <LandingTitle sx={{ textAlign: 'left' }}>
                Your questions, <br />
                answered!
              </LandingTitle>
              <a
                href={isDev ? 'https://stage.zipptrip.com/faq' : 'https://zipptrip.com/faq'}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none', color: 'var(--neutral-3, #D2ECDA)' }}
              >
                <Button variant="contained">
                  <Typography variant="T16M" sx={{ color: 'inherit' }}>
                    Visit the Help Center
                  </Typography>
                </Button>
              </a>
            </VerticalBlock>

            <VerticalBlock
              sx={{ flexBasis: '350px', flexGrow: 2, alignItems: 'flex-start', gap: '32px' }}
            >
              {faqContent.map((item) => {
                return <FAQBox key={item.title} question={item.title} details={item.answers} />;
              })}
            </VerticalBlock>
          </HorizontalBlock>
        </Section>

        <Section containerSx={{ bgcolor: 'secondary.dark', height: '100%' }}>
          <Box sx={{ width: '100%', margin: '20px 0' }}>
            <Center height="100%">
              <Typography
                sx={{ letterSpacing: '2.4px', textAlign: 'center', textTransform: 'uppercase' }}
                variant="T20S"
              >
                Cooperate with us
              </Typography>
              <Typography sx={{ textAlign: 'center', textTransform: 'capitalize' }} variant="T48R">
                Have a travel plan you to share?
              </Typography>

              <Typography sx={{ margin: '48px 0', textAlign: 'center' }} variant="T16R">
                Small or big, everyone looks forward to sharing from you!
              </Typography>

              <HorizontalBlock gap={1}>
                <Button onClick={() => setIsOpenSignupModal(true)} size="large" variant="contained">
                  Join as Creator
                </Button>

                <a
                  href="https://zipptrip.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none', color: 'var(--neutral-3, #D2ECDA)' }}
                >
                  <Button size="large" variant="outlined">
                    Learn about ZippTrip
                  </Button>
                </a>
              </HorizontalBlock>
            </Center>
          </Box>
        </Section>
        <Footer />
      </Box>

      <Box
        id="landing-bottom-bar" // for Portal usage
        sx={(theme) => ({
          bgcolor: 'text.background',
          bottom: 0,
          display: { xs: 'flex', sm: 'none' },
          padding: '16px 24px',
          position: 'fixed',
          width: '100vw',
          zIndex: theme.zIndex.drawer + 1,
        })}
      />
    </>
  );
};

export default LandingContainer;
