import {
  Dispatch,
  ReactElement,
  Ref,
  SetStateAction,
  forwardRef,
  useEffect,
  useState,
} from 'react';

import { Box, IconButton, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { ConfirmModal } from '@zipptrip/zipptrip-canvas';
import { IPlan } from '@zipptrip/zipptrip-commons';
import { useFormikContext } from 'formik';
import { Add } from 'iconsax-react';
import { StringParam, useQueryParam } from 'use-query-params';

import { navItems } from './navItems';
import PlanEditFormik from './PlanEditFormik';
import PlanSteps from './PlanSteps';
import { sendGAEvent } from '../../config/firebase';
import { getPlan } from '../../utils/plan';
import ItineraryForm from '../planForm/ItineraryForm';
import OthersForm from '../planForm/OthersForm';
import PublicInfoForm from '../planForm/PublicInfoForm';
import ResponsiveDrawer from '../utils/ResponsiveDrawer';

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement },
  ref: Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface InnerProps {
  currentPage: number;
  plan: IPlan | undefined;
  refreshPlans: () => void;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  setPlan: Dispatch<SetStateAction<IPlan | undefined>>;
  setShouldGoTo: Dispatch<SetStateAction<number>>;
  setShowCloseDialog: Dispatch<SetStateAction<boolean>>;
}

const PlanModalInner = ({
  currentPage,
  plan,
  refreshPlans,
  setCurrentPage,
  setPlan,
  setShouldGoTo,
  setShowCloseDialog,
}: InnerProps) => {
  const { initialValues, isValid, submitForm, values } = useFormikContext<IPlan>();
  const [planId] = useQueryParam('create-edit-plan', StringParam);
  const isEditing = planId === plan?._id;

  const goToPage = (page: number) => {
    if (currentPage === page) return;
    if (currentPage === 1) setCurrentPage(page);

    if (JSON.stringify(initialValues) === JSON.stringify(values) && !!values?._id && isValid) {
      return setCurrentPage(page);
    }
    setShouldGoTo(page);
    submitForm();
  };

  return (
    <>
      <ResponsiveDrawer
        drawerBreakpoint="md"
        drawerInner={() => (
          <PlanSteps currentPage={currentPage} goToPage={goToPage} isEditing={isEditing} />
        )}
        drawerSx={{ zIndex: 3000 }}
        toolbarChidren={
          <>
            <Typography sx={{ display: { xs: 'none', sm: 'block' }, flex: 1 }} variant="T20M">
              {navItems[currentPage].name}
            </Typography>
            <Typography sx={{ display: { sm: 'none' }, flex: 1 }} variant="T20M">
              {isEditing ? 'Edit plan' : 'Create plan'}
            </Typography>

            <Box sx={{ display: 'flex', gap: '16px' }}>
              <Box
                id="plan-modal-toolbar" // for Portal usage
                sx={{ display: 'contents' }}
              />

              <IconButton
                onClick={() => setShowCloseDialog(true)}
                sx={{ color: 'text.primary', transform: 'rotate(45deg)' }}
              >
                <Add />
              </IconButton>
            </Box>
          </>
        }
      />

      <Box sx={{ padding: { xs: '70px 0 0 0', md: '70px 0 0 293px' } }}>
        {currentPage === 0 && <PublicInfoForm goToPage={goToPage} />}

        {currentPage === 1 && plan?._id && (
          <ItineraryForm goToPage={goToPage} plan={plan} setPlan={setPlan} />
        )}

        {currentPage === 2 && plan?._id && (
          <OthersForm goToPage={goToPage} plan={plan} refreshPlans={refreshPlans} />
        )}

        <Box
          id="plan-modal-bottom" // for another Portal usage
          sx={{
            bgcolor: 'var(--neutral-1)',
            borderTop: '1px solid var(--neutral-3)',
            bottom: 0,
            display: { xs: 'flex', sm: 'none' },
            flexFlow: 'column-reverse',
            gap: '8px',
            padding: '16px 24px',
            position: 'sticky',
            zIndex: 10,
            '& button': { width: '100%' },
          }}
        />
      </Box>
    </>
  );
};

interface Props {
  handleClose: () => void;
  open: boolean;
  refreshPlans: () => void;
}

export default function PlanModal({ handleClose, open, refreshPlans }: Props) {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [plan, setPlan] = useState<IPlan | undefined>(undefined);
  const [shouldGoTo, setShouldGoTo] = useState(-1);
  const [showCloseDialog, setShowCloseDialog] = useState<boolean>(false);
  const [planId] = useQueryParam('create-edit-plan', StringParam);

  useEffect(() => {
    if (!!planId) {
      if (planId === '1') {
        setIsLoading(false);
        return;
      }
      getPlan(planId)
        .then((plan) => {
          setPlan(plan);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setIsLoading(false);
        });
    }
  }, [planId]);

  useEffect(() => {
    if (open) {
      if (currentPage === 0) {
        sendGAEvent('visit:plan-public-info-form');
      } else if (currentPage === 1 && plan?._id) {
        sendGAEvent('visit:plan-itinerary-info-form');
      }
    }
  }, [currentPage, open, plan?._id]);

  if (isLoading) return <></>;

  return (
    <PlanEditFormik
      plan={plan}
      setCurrentPage={setCurrentPage}
      setPlan={setPlan}
      setShouldGoTo={setShouldGoTo}
      shouldGoTo={shouldGoTo}
    >
      <Dialog
        fullScreen
        onClose={() => setShowCloseDialog(true)}
        open={open}
        sx={{ overflowY: 'hidden' }}
        TransitionComponent={Transition}
      >
        <PlanModalInner
          currentPage={currentPage}
          plan={plan}
          refreshPlans={refreshPlans}
          setCurrentPage={setCurrentPage}
          setPlan={setPlan}
          setShouldGoTo={setShouldGoTo}
          setShowCloseDialog={setShowCloseDialog}
        />
      </Dialog>

      {/* show confirmation dialog to close modal without saving */}
      <ConfirmModal
        cancelText="Do not close"
        confirmProps={{ color: 'error' }}
        confirmText="Close"
        onCancel={() => setShowCloseDialog(false)}
        onConfirm={handleClose}
        open={showCloseDialog}
        title="Close without saving?"
      >
        Are you sure you want to close it? Unsaved changes will be discarded.
        <br />
        If you have unsaved changes, please click "Do not close" and save your changes.
      </ConfirmModal>
    </PlanEditFormik>
  );
}
