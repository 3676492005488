import { Typography } from '@mui/material';
import { VerticalBlock } from '@zipptrip/zipptrip-canvas';

export default function EventLanding() {
  return (
    <VerticalBlock gap="8px">
      <img src="/empty_event.svg" />
      <Typography variant="T16M">No schedule yet</Typography>
      <Typography color="textSecondary" sx={{ textAlign: 'center' }} variant="T14R">
        To experience a fun trip and not miss any activities, start planning it!
      </Typography>
    </VerticalBlock>
  );
}
