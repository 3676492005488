import { Box, SxProps } from '@mui/material';

type SectionProps = { children: React.ReactNode; containerSx?: SxProps; sx?: SxProps };

const Section = ({ children, containerSx, sx }: SectionProps) => (
  <Box
    sx={{ padding: { xs: '30px', sm: '50px', md: '80px' }, ...(containerSx ? containerSx : {}) }}
  >
    <Box display="flex" sx={{ alignItems: 'center', flexWrap: 'wrap', ...(sx ? sx : {}) }}>
      {children}
    </Box>
  </Box>
);

export default Section;
