import { useEffect } from 'react';

import { Box, Divider, Theme, Typography, useMediaQuery } from '@mui/material';
import { HorizontalBlock, VerticalBlock } from '@zipptrip/zipptrip-canvas';
import { Instagram, Location, Sms } from 'iconsax-react';

import {
  ABOUT_US_URL,
  PRIVACY_POLICY_ROUTE,
  TERMS_AND_CONDITIONS_ROUTE,
} from '../../constants/routes';
// import Logo from '../logo/Logo';

function Footer() {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  let CREATOR_URL_ROOT = 'https://creator.zipptrip.com';
  let ZIPPTRIP_URL_ROOT = 'https://zipptrip.com';

  useEffect(() => {
    CREATOR_URL_ROOT =
      window.location.host === 'zipptrip.com'
        ? 'https://creator.zipptrip.com'
        : 'https://creator-stage.zipptrip.com';
    ZIPPTRIP_URL_ROOT =
      window.location.host === 'zipptrip.com'
        ? 'https://zipptrip.com'
        : 'https://stage.zipptrip.com';
  }, []);

  const LogoSection = () => {
    return (
      <Box
        sx={{
          '& > p': {
            color: 'var(--text-gray-300, #D9DBE1)',
            fontFeatureSettings: "'salt' on, 'liga' off",
          },
        }}
      >
        <Box
          sx={{
            width: '100px',
            mb: '32px',
          }}
        >
          <img src="/logo.png" style={{ width: '100%', objectFit: 'contain' }} />
        </Box>
        <Typography variant="body2" sx={{ mb: '8px' }}>
          Copyright © 2023 ZippTrip
        </Typography>
        <Typography variant="body2">All rights reserved</Typography>
      </Box>
    );
  };

  const CompanySection = () => {
    return (
      <VerticalBlock
        sx={{
          gap: '16px',
          alignItems: 'flex-start',
          '& > p': { color: 'var(--neutral-1, #FFF)' },
        }}
      >
        <Typography variant="T14S">Company</Typography>

        {[
          { text: 'About Us', route: ABOUT_US_URL },
          // { text: 'terms_and_condition', route: TERMS_AND_CONDITIONS_ROUTE },
          { text: 'Terms and condition', route: TERMS_AND_CONDITIONS_ROUTE },
          { text: 'Privacy policy', route: PRIVACY_POLICY_ROUTE },
        ].map(({ text, route }) => {
          return (
            <a
              href={route}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: 'var(--neutral-3, #D2ECDA)' }}
            >
              <Typography variant="T14R" sx={{ color: 'var(--neutral-3, #D2ECDA)' }}>
                {text}
              </Typography>
            </a>
          );
        })}
      </VerticalBlock>
    );
  };

  const CooperateSection = () => {
    return (
      <VerticalBlock
        sx={{
          gap: '16px',
          alignItems: 'flex-start',
          '& > p': { color: 'var(--neutral-1, #FFF)' },
        }}
      >
        <Typography variant="T14S">Cooperate with us</Typography>

        {[
          // { text: 'Trip Treats', route: '' },
          { text: 'Register as a creator', route: CREATOR_URL_ROOT },
          { text: 'Referral', route: `${ZIPPTRIP_URL_ROOT}/referrals` },
        ].map(({ text, route }) => {
          return (
            <a
              href={route}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: 'var(--neutral-3, #D2ECDA)' }}
            >
              <Typography variant="T14R" sx={{ color: 'var(--neutral-3, #D2ECDA)' }}>
                {text}
              </Typography>
            </a>
          );
        })}
      </VerticalBlock>
    );
  };

  const ConnectionSection = () => {
    return (
      <VerticalBlock
        sx={{
          gap: '16px',
          alignItems: 'flex-start',
          '& > p': { color: 'var(--neutral-1, #FFF)' },
          svg: {
            color: 'var(--neutral-5, #9EAFB0)',
            marginRight: '8px',
            verticalAlign: 'middle',
          },
          '& p': { color: 'var(--neutral-5, #9EAFB0)' },
        }}
      >
        <Typography variant="T14S" sx={{ color: 'var(--neutral-1, #FFF) !important' }}>
          Connect with Us
        </Typography>

        <a href="mailto:hello@zipptrip.com" style={{ textDecoration: 'none' }}>
          <Box>
            <Typography variant="T14R">
              <Sms />
              hello@zipptrip.com
            </Typography>
          </Box>
        </a>
        <Box>
          <a
            href="https://www.instagram.com/zipptripexplorers"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
          >
            <Typography variant="T14R">
              <Instagram />
              zipptripexplorers
            </Typography>
          </a>
        </Box>
        <Box>
          <Typography variant="T14R">
            <Location />
            1-19-18, Shibuya, Shibuya-ku, Tokyo
          </Typography>
        </Box>
      </VerticalBlock>
    );
  };

  return (
    <Box
      sx={{
        background: 'var(--neutral-10, #041B0D)',
        padding: { xs: '16px 0px 24px', md: '16px 0px' },
      }}
    >
      <HorizontalBlock
        sx={{
          flexDirection: isMobile ? 'column' : 'row',
          flexWrap: 'wrap',
          alignItems: 'flex-start',
          rowGap: '16px',
          padding: { xs: '32px 30px', md: '32px 80px' },
        }}
      >
        <LogoSection />
        {isMobile && (
          <Divider
            sx={{ width: '100%', strokeWidth: '1px', stroke: 'var(--neutral-2, #D0DADA)' }}
          />
        )}
        <CompanySection />
        {isMobile && (
          <Divider
            sx={{ width: '100%', strokeWidth: '1px', stroke: 'var(--neutral-2, #D0DADA)' }}
          />
        )}
        <CooperateSection />
        {isMobile && (
          <Divider
            sx={{ width: '100%', strokeWidth: '1px', stroke: 'var(--neutral-2, #D0DADA)' }}
          />
        )}
        <ConnectionSection />
      </HorizontalBlock>
    </Box>
  );
}

export default Footer;

// import {
//   EmailOutlined as EmailOutlinedIcon,
//   Instagram as InstagramIcon,
//   LocationOnOutlined as LocationOnOutlinedIcon,
// } from '@mui/icons-material';
// import { Box, Button, Divider, Typography } from '@mui/material';
// import { HorizontalBlock } from '@zipptrip/zipptrip-canvas';

// import Section from './Section';
// import {
//   ABOUT_US_URL,
//   PRIVACY_POLICY_ROUTE,
//   TERMS_AND_CONDITIONS_ROUTE,
// } from '../../constants/routes';

// const Footer = () => {
//   return (
//     <>
//       <Section
//         containerSx={{
//           bgcolor: '#000000',
//           paddingTop: '16px !important',
//           paddingBottom: '16px !important',
//         }}
//       >
//         <HorizontalBlock
//           sx={{
//             width: '100%',
//             alignItems: 'center',
//             justifyContent: { xs: 'center', md: 'space-between' },
//             flexWrap: 'wrap',
//             columnGap: '24px',
//             rowGap: '10px',
//           }}
//         >
//           <Box sx={{ textAlign: 'center' }}>
//             <img src="/footer-logo.png" />
//           </Box>
//           {/* <Box sx={{ flexShrink: 1, flexGrow: 1, width: { xs: '1000px', md: 0 } }} /> */}
//           <Box sx={{ display: 'flex', gap: '10px', flexDirection: { xs: 'column', sm: 'row' } }}>
//             {[
//               { href: ABOUT_US_URL, text: 'About Us' },
//               { href: TERMS_AND_CONDITIONS_ROUTE, text: 'Terms & Conditions' },
//               { href: PRIVACY_POLICY_ROUTE, text: 'Privacy Policy' },
//             ].map(({ href, text }) => (
//               <Box key={text} margin={1}>
//                 <a
//                   href={href}
//                   target="_blank"
//                   rel="noopener noreferrer"
//                   style={{ textDecoration: 'none' }}
//                 >
//                   <Typography
//                     variant="T14R"
//                     sx={{ fontSize: '14px', color: '#FFFFFF', textAlign: 'center' }}
//                   >
//                     <u>{text}</u>
//                   </Typography>
//                 </a>
//               </Box>
//             ))}
//           </Box>
//         </HorizontalBlock>
//       </Section>
//       <Divider sx={{ width: '100%', bgcolor: '#9EAFB0', height: '1px' }} />
//       <Section
//         containerSx={{
//           bgcolor: '#000000',
//           paddingTop: '16px !important',
//           paddingBottom: '16px !important',
//         }}
//       >
//         <HorizontalBlock
//           sx={{
//             width: '100%',
//             justifyContent: { xs: 'center', md: 'space-between' },
//             flexWrap: 'wrap',
//             columnGap: '24px',
//             rowGap: '5px',
//           }}
//         >
//           <Typography
//             sx={{
//               fontSize: '14px',
//               color: '#9EAFB0',
//               textAlign: { xs: 'center', md: 'left' },
//               minWidth: { xs: '100%', md: '200px' },
//             }}
//           >
//             © 2023 ZippTrip
//           </Typography>

//           <Box sx={{ flexShrink: 1, flexGrow: 1, display: { xs: 'none', md: 'unset' } }} />
//           <HorizontalBlock
//             sx={{
//               textAlign: 'center',
//               alignItems: 'flex-start',
//               justifyContent: 'space-around',
//               display: { xs: 'none', md: 'unset' },
//             }}
//           >
//             <LocationOnOutlinedIcon
//               fontSize={'small'}
//               sx={{ color: '#9EAFB0', verticalAlign: 'top' }}
//             />
//             <Typography
//               sx={{
//                 fontSize: '14px',
//                 color: '#9EAFB0',
//                 marginLeft: '7px',
//                 display: 'inline',
//               }}
//             >
//               1-19-18, Shibuya, Shibuya-ku, Tokyo
//             </Typography>
//           </HorizontalBlock>

//           <Button>
//             <HorizontalBlock
//               sx={{
//                 textAlign: 'center',
//                 alignItems: 'flex-start',
//                 justifyContent: 'space-around',
//                 minWidth: { xs: '300px', sm: 0 },
//               }}
//             >
//               <a href="mailto:hello@zipptrip.com" style={{ textDecoration: 'none' }}>
//                 <EmailOutlinedIcon
//                   fontSize={'small'}
//                   sx={{ color: '#9EAFB0', verticalAlign: 'top' }}
//                 />
//                 <Typography
//                   sx={{
//                     fontSize: '14px',
//                     color: '#9EAFB0',
//                     marginLeft: '7px',
//                     display: { xs: 'none', sm: 'inline' },
//                   }}
//                 >
//                   hello@zipptrip.com
//                 </Typography>
//               </a>
//             </HorizontalBlock>
//           </Button>

//           <Button>
//             <HorizontalBlock
//               sx={{
//                 textAlign: 'center',
//                 alignItems: 'flex-start',
//                 justifyContent: 'space-around',
//                 minWidth: { xs: '300px', sm: 0 },
//               }}
//             >
//               <a
//                 href="https://www.instagram.com/zipptripexplorers"
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 style={{ textDecoration: 'none' }}
//               >
//                 <InstagramIcon fontSize="small" sx={{ color: '#9EAFB0', verticalAlign: 'top' }} />
//                 <Typography
//                   sx={{
//                     fontSize: '14px',
//                     color: '#9EAFB0',
//                     marginLeft: '7px',
//                     display: { xs: 'none', sm: 'inline' },
//                   }}
//                 >
//                   zipptripexplorers
//                 </Typography>
//               </a>
//             </HorizontalBlock>
//           </Button>
//         </HorizontalBlock>
//       </Section>
//     </>
//   );
// };

// export default Footer;
