import { useState } from 'react';

import { IconButton, TextField, TextFieldProps } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import {
  ConfirmModal,
  ConfirmModalProps,
  LoaderCircle,
  useNotifications,
} from '@zipptrip/zipptrip-canvas';
import { Eye, EyeSlash } from 'iconsax-react';

import { updatePassword } from '../../utils/planner';

type Props = Pick<ConfirmModalProps, 'onCancel' | 'open'>;

export default function ChangePasswordModal({ onCancel, open }: Props) {
  const { showError, showSuccess } = useNotifications();
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const { mutate: updatePasswordMutation, isLoading } = useMutation(
    async () => updatePassword(newPassword),
    {
      onSuccess: () => {
        showSuccess('Password changed successfully.');
        onCancel();
      },
      onError: () => showError('Unexpected Error!! Please try again'),
    },
  );

  const unmatch = newPassword !== confirmNewPassword;
  const disableSubmit = !newPassword || unmatch;

  return (
    <ConfirmModal
      cancelText="Cancel"
      confirmProps={{
        disabled: isLoading || disableSubmit,
        startIcon: isLoading && <LoaderCircle />,
      }}
      confirmText="Submit"
      onCancel={onCancel}
      onConfirm={updatePasswordMutation}
      open={open}
      title="Change Password"
    >
      <PasswordField
        label="New password"
        onChange={(e) => setNewPassword(e.target.value)}
        value={newPassword}
      />
      <PasswordField
        error={unmatch}
        helperText={unmatch ? 'Passwords do not match' : ''}
        label="Confirm new password"
        onChange={(e) => setConfirmNewPassword(e.target.value)}
        value={confirmNewPassword}
      />
    </ConfirmModal>
  );
}

const PasswordField = (props: Partial<TextFieldProps>) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <TextField
      fullWidth
      InputProps={{
        endAdornment: (
          <IconButton sx={{ padding: 0 }} onClick={() => setIsVisible((v) => !v)}>
            {isVisible ? <EyeSlash /> : <Eye />}
          </IconButton>
        ),
      }}
      sx={{ margin: '16px 0' }}
      type={isVisible ? 'text' : 'password'}
      {...props}
    />
  );
};
