import * as Yup from 'yup';
export const getValidationSchema = () => {
  return Yup.object().shape({
    name: Yup.string().required('Name is required').min(3, 'Name must be at least 3 characters'),
    languages: Yup.array().required('Languages is required').min(1, 'Language is required'),
    country: Yup.string().required('Country is required'),
    tagLine: Yup.string().required('Tag Line is required'),
    bio: Yup.string().required('Bio is required').min(200, 'Bio must be at least 200 characters'),
    contentCategories: Yup.array()
      .required('Content Categories is required')
      .min(1, 'Content Category is required'),
    travelInterests: Yup.array()
      .required('Travel Interests is required')
      .min(1, 'Travel Interest is required'),
  });
};
