import { useState } from 'react';

import AuthBackground from '../components/auth/AuthBackground';
import AuthContainer from '../components/auth/AuthContainer';
import LoadingSpinner from '../components/loader/LoadingSpinner';

const LoginPage = () => {
  const [isLoading, setIsLoading] = useState(false);

  if (isLoading) return <LoadingSpinner />;

  return (
    <AuthBackground>
      <AuthContainer setIsLoading={setIsLoading} />
    </AuthBackground>
  );
};

export default LoginPage;
