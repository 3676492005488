import { useMemo } from 'react';

import { Button, Grid, Typography } from '@mui/material';
import { IPlannerVisit } from '@zipptrip/zipptrip-commons';
import { SearchNormal1 } from 'iconsax-react';
import { useNavigate } from 'react-router-dom';

import { sendGAEvent } from '../../config/firebase';
import { PLANS_ROUTE } from '../../constants/routes';

export default function PlanSuggest({
  alreadyVisited = [],
  breakpoint = 'lg',
}: {
  alreadyVisited: IPlannerVisit[];
  breakpoint?: 'lg' | 'xl';
}) {
  const navigate = useNavigate();

  const visitedPlace = useMemo(
    // Randomly pick a city:
    () =>
      alreadyVisited?.filter(({ planCreated }) => !planCreated)?.sort(() => Math.random() - 0.5)[0],
    [JSON.stringify(alreadyVisited || [])],
  );
  const { city, country } = visitedPlace || {};

  if (!city) return <></>;

  return (
    <Grid
      container
      columnSpacing={5}
      rowSpacing={3}
      sx={{
        alignItems: 'center',
        bgcolor: '#F6F6F6',
        borderRadius: '40px',
        marginLeft: 0,
        marginTop: 0,
        paddingRight: '24px',
        paddingBottom: '24px',
        width: '100%',
      }}
    >
      <Grid
        item
        xs={12}
        lg={breakpoint === 'lg' ? 5 : undefined}
        xl={breakpoint === 'xl' ? 5 : undefined}
      >
        <Typography variant="T24S">Interested to share your plan about {city}?</Typography>
        <Typography variant="T16R" sx={{ margin: '8px 0 24px' }}>
          Turn your travels into treasures! Share your itinerary, earn and inspire the global
          explorer community!
        </Typography>

        <Button
          onClick={() => {
            navigate({
              pathname: PLANS_ROUTE,
              search: `?create-edit-plan=1&country=${country}&city=${city}`,
            });
            sendGAEvent('open:create-plan-modal');
          }}
          size="large"
          startIcon={<SearchNormal1 />}
          variant="contained"
        >
          Create plan now
        </Button>
      </Grid>

      <Grid
        container
        item
        spacing={3}
        xs={12}
        lg={breakpoint === 'lg' ? 7 : undefined}
        xl={breakpoint === 'xl' ? 7 : undefined}
        sx={{ '& img': { borderRadius: '40px', width: '100%' } }}
      >
        <Grid item xs={4}>
          <img src="/world/world-1.png" />
        </Grid>

        <Grid item xs={4}>
          <img src="/world/world-2.png" />
        </Grid>

        <Grid item xs={4}>
          <img src="/world/world-3.png" />
        </Grid>
      </Grid>
    </Grid>
  );
}
