import { ReactNode, useState } from 'react';

import { Menu } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Breakpoint,
  Drawer,
  IconButton,
  SxProps,
  Theme,
  Toolbar,
} from '@mui/material';
import { mergeSx } from '@zipptrip/zipptrip-canvas';

interface Props {
  appBarSx?: SxProps<Theme>;
  drawerBreakpoint?: Breakpoint;
  drawerInner: (toggleDrawer: () => void) => ReactNode;
  drawerSx?: SxProps<Theme>;
  drawerWidth?: number;
  toolbarChidren?: ReactNode;
}

export default function ResponsiveDrawer({
  appBarSx = {},
  drawerBreakpoint: bp = 'sm',
  drawerInner,
  drawerSx = {},
  drawerWidth = 293,
  toolbarChidren = <></>,
}: Props) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const toggleDrawer = () => setMobileOpen((op) => !op);

  const drawer = (
    <>
      <Box
        sx={(theme) => ({ marginTop: '70px', [theme.breakpoints.up(bp)]: { display: 'none' } })}
      />

      {drawerInner(toggleDrawer)}
    </>
  );

  return (
    <>
      <AppBar
        position="fixed"
        sx={mergeSx(
          (theme) => ({
            backgroundColor: theme.palette.common.white,
            borderBottom: '1px solid var(--neutral-3)',
            boxShadow: 'none',
            [theme.breakpoints.down(bp)]: { zIndex: theme.zIndex.drawer + 1 },
            [theme.breakpoints.up(bp)]: { paddingLeft: `${drawerWidth}px` },
          }),
          appBarSx,
        )}
      >
        <Toolbar sx={{ minHeight: '60px !important' }}>
          <IconButton
            aria-label="open drawer"
            color="inherit"
            edge="start"
            onClick={toggleDrawer}
            sx={(theme) => ({
              color: theme.palette.grey[700],
              display: { [bp]: 'none' },
              marginRight: theme.spacing(2),
            })}
          >
            <Menu />
          </IconButton>

          {toolbarChidren}
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="left"
        ModalProps={{ keepMounted: true }}
        onClose={toggleDrawer}
        open={mobileOpen}
        sx={mergeSx({ display: { xs: 'block', [bp]: 'none' }, width: drawerWidth }, drawerSx)}
        variant="temporary"
      >
        {drawer}
      </Drawer>
      <Drawer
        anchor="left"
        open
        sx={mergeSx({ display: { xs: 'none', [bp]: 'block' }, width: drawerWidth }, drawerSx)}
        variant="permanent"
      >
        {drawer}
      </Drawer>
    </>
  );
}
