import { Button, Link, Typography } from '@mui/material';
import { HorizontalBlock } from '@zipptrip/zipptrip-canvas';

import { LOGIN_ROUTE } from '../../constants/routes';

const AlreadyRegistered = () => (
  <HorizontalBlock gap="4px">
    <Typography variant="T16R" sx={{ color: 'text.secondary' }}>
      Already registered?
    </Typography>

    <Link href={LOGIN_ROUTE} sx={{ display: 'flex', textDecoration: 'none' }}>
      <Button disableRipple sx={{ fontSize: '16px', padding: 0 }}>
        Login now
      </Button>
    </Link>
  </HorizontalBlock>
);

export default AlreadyRegistered;
