import { ItineraryEventType } from '@zipptrip/zipptrip-commons';
import * as Yup from 'yup';

export const getPlanValidationSchema = () => {
  return Yup.object().shape({
    title: Yup.string().required('Name is required').min(10, 'Name must be at least 10 characters'),
    duration: Yup.number()
      .required('Duration is required')
      .min(1, 'Duration must be at least 1 day'),
    countries: Yup.array().required('Countries is required').min(1, 'Country is required'),
    cities: Yup.array(),
    travellersTypes: Yup.array(),
    tripTypes: Yup.array(),
    foodPreferences: Yup.array(),
    bestTimeToVisit: Yup.array(),
    description: Yup.string()
      .min(100, 'Description must be at least 100 characters')
      .max(600, 'Description must be at most 600 characters'),
    price: Yup.number()
      .required()
      .min(6, 'Price must be at least $6')
      .max(50, 'Price can be at most $50'),
  });
};

export const getEventValidationSchema = () => {
  return Yup.object().shape({
    title: Yup.string()
      .required('Event title is required')
      .min(5, 'Event title must be at least 5 characters'),
    description: Yup.string()
      .required('Event description is required')
      .min(10, 'Event description must be at least 10 characters')
      .max(300, 'Event description must be at most 300 characters'),
    startTime: Yup.number().required('Event start time is required'),
    endTime: Yup.number()
      .required('Event end time is required')
      .min(10, 'Event end time should be later'),
    eventType: Yup.string().required('Event type is required'),
    fromLocation: Yup.object().when('eventType', {
      is: (eventType: string) => eventType === ItineraryEventType.Commute,
      then: () => Yup.object().required('From location is required'),
    }),
    toLocation: Yup.object().when('eventType', {
      is: (eventType: string) => eventType === ItineraryEventType.Commute,
      then: () => Yup.object().required('To location is required'),
    }),
    location: Yup.object().when('eventType', {
      is: (eventType: string) => eventType !== ItineraryEventType.Commute,
      then: () => Yup.object().required('Location is required'),
    }),
    costFor1: Yup.number(),
    costFor2: Yup.number(),
    costFor3: Yup.number(),
    costFor4: Yup.number(),
    webLinks: Yup.array().of(
      Yup.object().shape({
        url: Yup.string().required('URL is required').url('URL is invalid'),
        title: Yup.string().required('Title is required'),
      }),
    ),
  });
};

// when photos are less than 4, fill the rest with empty string
export const fillPhotoList = (photos: string[]) => {
  const updatedPhotos = [...(photos || [])];
  while (updatedPhotos.length < 4) {
    updatedPhotos.push('');
  }
  return updatedPhotos;
};

interface IRange {
  startTime: number;
  endTime: number;
}

export function checkConflict(range1: IRange, range2: IRange): string | null {
  if (range1.startTime < range2.endTime && range1.endTime > range2.startTime) {
    return 'startTime';
  }

  if (range2.startTime < range1.endTime && range2.endTime > range1.startTime) {
    return 'endTime';
  }

  // If there is no overlap, return null
  return null;
}
