import { useEffect, useState } from 'react';

import { CentralModal, CentralModalProps } from '@zipptrip/zipptrip-canvas';
import { useNavigate } from 'react-router-dom';

import AuthContainer from './AuthContainer';
import { auth, sendGAEvent } from '../../config/firebase';
import { HOME_ROUTE } from '../../constants/routes';
import LoadingSpinner from '../loader/LoadingSpinner';

export type LoginModalProps = CentralModalProps;

const LoginModal = (props: LoginModalProps) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { open } = props;

  useEffect(() => {
    if (auth.currentUser) navigate(HOME_ROUTE);
  }, []);

  useEffect(() => {
    if (open) sendGAEvent('open:login-modal');
  }, [open]);

  return (
    <CentralModal
      {...props}
      sx={{ padding: { xs: '48px 16px', sm: '48px 32px' }, width: 'min(85vw, 624px)' }}
    >
      {isLoading ? <LoadingSpinner /> : <></>}
      <AuthContainer setIsLoading={setIsLoading} />
    </CentralModal>
  );
};

export default LoginModal;
