import { useMemo } from 'react';

import { Box, Typography } from '@mui/material';
import { VerticalBlock } from '@zipptrip/zipptrip-canvas';
import { IEvent } from '@zipptrip/zipptrip-commons';

type Props = { events: IEvent[] };

const ItinerarySummary = ({ events }: Props) => {
  const totalHour = useMemo(() => {
    let totalMinutes = 0;
    events.forEach((event) => {
      let endTime = event.endTime;
      if (event.endTime < event.startTime) endTime += 24 * 60;
      totalMinutes += endTime - event.startTime;
    });
    return `${Math.floor(totalMinutes / 60)}h ${totalMinutes % 60}m`;
  }, [JSON.stringify(events)]);

  const totalBudget = useMemo(() => {
    let budget = 0;
    events.forEach((event) => {
      const { costFor1 = 0, costFor2 = 0, costFor3 = 0, costFor4 = 0 } = event;
      budget += costFor1 || costFor2 / 2 || costFor3 / 3 || costFor4 / 4;
    });
    return `${Number(budget.toFixed(2))} USD`;
  }, [JSON.stringify(events)]);

  return (
    <>
      <Typography variant="T18S">Summary</Typography>

      <VerticalBlock
        sx={{
          gap: '8px',
          margin: '16px 0',
          '& > div': {
            alignItems: 'center',
            bgcolor: 'var(--neutral-1)',
            border: '1px solid var(--neutral-2)',
            borderRadius: '24px',
            display: 'flex',
            flexFlow: 'column',
            gap: '4px',
            padding: '16px 0',
            width: '100%',
          },
        }}
      >
        <Box>
          <Typography variant="T16B">{events.length}</Typography>
          <Typography color="textSecondary" variant="T12R">
            Events
          </Typography>
        </Box>

        <Box>
          <Typography variant="T16B">{totalHour}</Typography>
          <Typography color="textSecondary" variant="T12R">
            Planned time
          </Typography>
        </Box>

        <Box>
          <Typography variant="T16B">{totalBudget}</Typography>
          <Typography color="textSecondary" variant="T12R">
            Budget/person
          </Typography>
        </Box>
      </VerticalBlock>
    </>
  );
};

export default ItinerarySummary;
