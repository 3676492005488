import { useState } from 'react';

import {
  Box,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { HorizontalBlock, VerticalBlock } from '@zipptrip/zipptrip-canvas';
import { SearchNormal1 } from 'iconsax-react';
import moment from 'moment';

import { ReferralSummary } from '../../types/planner';
import { numberWithCommas } from '../../utils/commons';

interface Props {
  summaryData: ReferralSummary[];
}

export default function ReferralUser({ summaryData }: Props) {
  const [searchedValue, setSearchedValue] = useState('');
  const tableData = summaryData.filter(
    ({ name }) =>
      !searchedValue || name.toLocaleLowerCase().includes(searchedValue.toLocaleLowerCase()),
  );

  return (
    <Box sx={{ background: '#F6F6F6', borderRadius: '24px', padding: '24px' }}>
      <HorizontalBlock sx={{ marginBottom: '16px' }}>
        <Typography variant="T16S">Referred Users</Typography>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ color: 'text.primary' }}>
                <SearchNormal1 size={20} />
              </InputAdornment>
            ),
            sx: { background: '#FFFFFF', padding: '8px 16px !important', minHeight: '40px' },
          }}
          onChange={(e) => setSearchedValue(e.target.value)}
          placeholder="Search"
          value={searchedValue}
        />
      </HorizontalBlock>

      <TableContainer>
        <Table
          sx={{
            minHeight: '107px',
            '& th': { padding: '0 0 12px 0' },
            '& td': { border: 'none', padding: '8px 0' },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography color="textSecondary" variant="T14R">
                  User
                </Typography>
              </TableCell>
              <TableCell sx={{ textAlign: 'center' }}>
                <Typography color="textSecondary" variant="T14R">
                  Signed up at
                </Typography>
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <Typography color="textSecondary" variant="T14R">
                  Commission earned
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {tableData.map((row) => (
              <TableRow
                key={row.uid}
                sx={{
                  '&:first-of-type > td': { paddingTop: '12px' },
                  '&:last-of-type > td': { paddingBottom: 0 },
                }}
              >
                <TableCell>
                  <Typography variant="T14M">{row.name}</Typography>
                </TableCell>
                <TableCell sx={{ textAlign: 'center' }}>
                  <Typography variant="T14M">
                    {moment(row.signedUpAt).format('DD MMM, YYYY')}
                  </Typography>
                </TableCell>
                <TableCell sx={{ textAlign: 'right' }}>
                  <Typography variant="T14R">${numberWithCommas(row.totalCommission)}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {tableData.length === 0 && (
          <VerticalBlock
            sx={{ gap: '8px', margin: '-50px 0 25px', textAlign: 'center', width: '100%' }}
          >
            <Box sx={{ margin: '27px 0 31px', '& > img': { width: '133px' } }}>
              <img src="empty_referral.png" />
            </Box>
            <Typography variant="T16S">Your referral rewards coming up</Typography>
            <Typography variant="T14R">
              Once your first referral signs up, you can track your rewards here
            </Typography>
          </VerticalBlock>
        )}
      </TableContainer>
    </Box>
  );
}
