import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';

import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import {
  HorizontalBlock,
  LoaderCircle,
  VerticalBlock,
  useNotifications,
} from '@zipptrip/zipptrip-canvas';
import {
  COUNTRIES,
  IInternalAccount,
  IPlannerPayoutAccount,
  PayoutAccountType,
} from '@zipptrip/zipptrip-commons';
import { Bank, Global, Icon, TickCircle } from 'iconsax-react';
import { StepWizardChildProps } from 'react-step-wizard';

import InternalAccountModal from './InternalAccountModal';
import { sendGAEvent } from '../../config/firebase';
import {
  IPayoutAccountDetails,
  createPayoutAccount,
  getStripeAccountLink,
} from '../../utils/planner';

type CountryOption = { name: string; code: string };

type Props = {
  payoutAccount: IPlannerPayoutAccount | undefined;
  payoutAccountDetails: IPayoutAccountDetails | undefined;
  refetchDetails: () => Promise<unknown>;
  setPayoutAccount: Dispatch<SetStateAction<IPlannerPayoutAccount | undefined>>;
} & Partial<StepWizardChildProps>;

export default function ConnectPanel({
  goToStep,
  payoutAccount,
  payoutAccountDetails,
  refetchDetails,
  setPayoutAccount,
}: Props) {
  const [country, setCountry] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const { showError, showSuccess } = useNotifications();

  const hasPayoutAccount = !!payoutAccount;
  const { details, isCompleted } = payoutAccountDetails || {};

  useEffect(() => {
    setCountry(details?.country || '');
  }, [details?.country]);

  const { mutate: createPayoutAccountMutation, isLoading: isCreatingPayoutAccount } = useMutation(
    async () => createPayoutAccount(country),
    {
      onSuccess: (data) => {
        showSuccess('Bank country registered successfully');
        setPayoutAccount(data);
      },
      onError: () => showError('Unexpected Error!! Please try again'),
    },
  );

  const { mutate: getStripeAccountLinkMutation, isLoading: isGettingStripeAccountLink } =
    useMutation(
      async () => {
        sendGAEvent('click:connect-bank-button');
        return getStripeAccountLink();
      },
      {
        onSuccess: (data) => window.open(data.url, '_blank', 'noopener,noreferrer'),
        onError: () => showError('Unexpected Error!! Please try again'),
      },
    );

  return (
    <VerticalBlock
      sx={{ gap: '24px', margin: '0 auto', maxWidth: '815px', padding: '32px 32px 96px' }}
    >
      <img src="/bank.svg" />

      <VerticalBlock sx={{ gap: '16px', maxWidth: '630px', textAlign: 'center' }}>
        <Typography variant="T28S">Connect your bank account</Typography>

        <Typography variant="T14R">
          Connecting bank account allows you to receive your revenue. You can request for payouts
          once the bank is connected. Please provide the required information below to complete the
          connection process.
        </Typography>
      </VerticalBlock>

      <InfoBlock inactive={hasPayoutAccount} infoColor="secondary.main" InfoIcon={Global}>
        <Typography sx={{ marginBottom: '16px' }} variant="T16S">
          Bank country
        </Typography>

        <HorizontalBlock sx={{ alignItems: 'flex-start', gap: '16px' }}>
          <Box>
            <Autocomplete<CountryOption>
              fullWidth
              getOptionLabel={(option) => option?.name || ''}
              isOptionEqualToValue={(option, value) => option?.code === value?.code}
              onChange={(event, value) => setCountry(value?.code || '')}
              options={COUNTRIES}
              renderInput={(params) => <TextField {...params} label="Select your bank country" />}
              value={COUNTRIES.find(({ code }) => code === country) || null}
            />
            <Typography sx={{ color: 'text.secondary', marginTop: '12px' }} variant="T12R">
              We'll register your country with the payment gateway and show you suitable payment
              methods.
            </Typography>
          </Box>

          <Button
            disabled={!country || isCreatingPayoutAccount}
            onClick={() => createPayoutAccountMutation()}
            startIcon={isCreatingPayoutAccount && <LoaderCircle />}
            sx={{ flexShrink: 0, margin: '5px 0' }}
            variant="contained"
          >
            Send request
          </Button>
        </HorizontalBlock>
      </InfoBlock>

      {hasPayoutAccount && !isCompleted && (
        <HorizontalBlock
          sx={{
            bgcolor: 'success.selectedBackground',
            borderRadius: '24px',
            gap: '12px',
            marginLeft: { xs: 0, md: '84px' },
            padding: '16px',
            width: { xs: '100%', md: 'calc(100% - 84px)' },
            '& > svg': { color: 'var(--green-6)', flexShrink: 0 },
          }}
        >
          <TickCircle />
          <Typography sx={{ flexGrow: '1' }} variant="T14R">
            Register your country bank successfully. Please connect your bank account.
          </Typography>
        </HorizontalBlock>
      )}

      <InfoBlock inactive={!hasPayoutAccount} infoColor="primary.main" InfoIcon={Bank}>
        <HorizontalBlock>
          <Box>
            <Typography sx={{ marginBottom: '8px' }} variant="T16S">
              Bank Account
            </Typography>

            <Typography variant="T14R">Connect your bank account</Typography>
          </Box>

          <Button
            disabled={isGettingStripeAccountLink}
            onClick={() => {
              if (payoutAccount?.type === PayoutAccountType.Internal) setModalOpen(true);
              else if (payoutAccount?.type === PayoutAccountType.Stripe) {
                getStripeAccountLinkMutation();
              }
            }}
            startIcon={isGettingStripeAccountLink && <LoaderCircle />}
            sx={{ flexShrink: 0 }}
            variant="contained"
          >
            {isCompleted ? 'Edit' : 'Connect'}
          </Button>
        </HorizontalBlock>
      </InfoBlock>

      <Button color="primary" onClick={() => goToStep?.(2)} variant="contained">
        Go to payout records
      </Button>

      {payoutAccount?.type === PayoutAccountType.Internal && (
        <InternalAccountModal
          accountDetails={details as IInternalAccount}
          onCancel={() => setModalOpen(false)}
          open={modalOpen}
          refetchDetails={refetchDetails}
        />
      )}
    </VerticalBlock>
  );
}

const InfoBlock = ({
  children,
  inactive,
  infoColor,
  InfoIcon,
}: {
  children: ReactNode;
  inactive: boolean;
  infoColor: string;
  InfoIcon: Icon;
}) => {
  return (
    <HorizontalBlock
      sx={{
        alignItems: 'flex-start',
        gap: '24px',
        opacity: inactive ? 0.35 : 1,
        pointerEvents: inactive ? 'none' : undefined,
        width: '100%',
      }}
    >
      <Box
        sx={{
          bgcolor: infoColor,
          borderRadius: '24px',
          display: { xs: 'none', md: 'flex' },
          padding: '16px',
        }}
      >
        <InfoIcon size={28} />
      </Box>
      <Box
        sx={{
          border: '1px solid var(--neutral-3)',
          borderRadius: '24px',
          padding: '24px',
          width: '100%',
        }}
      >
        {children}
      </Box>
    </HorizontalBlock>
  );
};
