import { Fragment } from 'react';

import { Box, Typography } from '@mui/material';
import { HorizontalBlock, VerticalBlock } from '@zipptrip/zipptrip-canvas';
import { ACHIEVEMENT_LEVEL_SETTING } from '@zipptrip/zipptrip-commons';

import { ACHIEVEMENT_LEVEL_BADGE } from './constants';

const achievementLevels = Object.values(ACHIEVEMENT_LEVEL_SETTING);

const getNextLevel = (points: number) =>
  achievementLevels.find(({ minPoints }) => minPoints > points);

interface Props {
  isLoading: boolean;
  points: number;
}

export default function LevelIndicator({ isLoading, points }: Props) {
  const nextLevel = getNextLevel(points);

  return (
    <Box sx={{ display: 'flex', flexFlow: 'column', gap: '16px' }}>
      <HorizontalBlock gap="16px" width="fit-content">
        <Typography variant="T28S">Level</Typography>
        {!isLoading && !!nextLevel && (
          <Typography color="textSecondary" variant="T14R">
            Only {nextLevel.minPoints - points} points left to reach {nextLevel.title} level
          </Typography>
        )}
      </HorizontalBlock>

      <Box sx={{ overflowX: 'auto' }}>
        <HorizontalBlock
          sx={{ gap: '8px', marginBottom: '30px', minWidth: '900px', width: 'calc(100% - 4px)' }}
        >
          {achievementLevels.map(({ id, minPoints, title }, idx) => (
            <Fragment key={id}>
              {idx !== 0 && (
                <LevelProgress
                  endPoints={minPoints}
                  points={points}
                  startPoints={achievementLevels[idx - 1].minPoints}
                />
              )}

              <VerticalBlock gap="8px" sx={{ height: '56px', minWidth: '85px' }}>
                <img alt={title} src={ACHIEVEMENT_LEVEL_BADGE[id]} />
                <Typography variant="T14S">{title}</Typography>
              </VerticalBlock>
            </Fragment>
          ))}
        </HorizontalBlock>
      </Box>
    </Box>
  );
}

interface LevelProgressProps {
  endPoints: number;
  points: number;
  startPoints: number;
}

const getProgressPercentage = ({ endPoints, points, startPoints }: LevelProgressProps) => {
  const range = endPoints - startPoints;
  const percentage = (points - startPoints) / range;

  if (percentage <= 0) return '0%';
  if (percentage >= 1) return '100%';
  return `${Math.round(percentage * 100)}%`;
};

const LevelProgressUnit = (props: LevelProgressProps) => {
  const width = getProgressPercentage(props);

  return (
    <Box
      sx={{
        bgcolor: 'secondary.selectedBackground',
        borderRadius: '40px',
        flexGrow: 1,
        height: '8px',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          bgcolor: 'secondary.main',
          borderRadius: '40px',
          height: '100%',
          position: 'absolute',
          width,
        }}
      ></Box>
    </Box>
  );
};

const LevelProgress = ({ endPoints, points, startPoints }: LevelProgressProps) => {
  const pointGap = (endPoints - startPoints) / 3;

  return (
    <>
      {Array.from({ length: 3 }).map((_, i) => (
        <LevelProgressUnit
          key={i}
          endPoints={startPoints + pointGap * (i + 1)}
          points={points}
          startPoints={startPoints + pointGap * i}
        />
      ))}
    </>
  );
};
