import { Dispatch, SetStateAction, useState } from 'react';

import { Button, Card, CardMedia, Divider, Typography } from '@mui/material';
import {
  ConfirmModal,
  HorizontalBlock,
  LoaderCircle,
  VerticalBlock,
  useNotifications,
} from '@zipptrip/zipptrip-canvas';
import { IEvent, IPlan, ItineraryEventType } from '@zipptrip/zipptrip-commons';
import { Clock, DollarCircle, Edit2, Location, Trash } from 'iconsax-react';

import { DEFAULT_IMG_SRC } from '../../constants/commons';
import { ImageVariant } from '../../types/image';
import { getTimeString } from '../../utils/commons';
import { getImageUrl } from '../../utils/images';
import { deleteEvent, getDayofAPlanEvent } from '../../utils/plan';

interface Props {
  event: IEvent;
  onEventEdit: () => void;
  plan: IPlan;
  setPlan: Dispatch<SetStateAction<IPlan | undefined>>;
}

const EventCard = ({ event, onEventEdit, plan, setPlan }: Props) => {
  const day = getDayofAPlanEvent(plan, event);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const { showSuccess } = useNotifications();

  const costStr = [event.costFor1, event.costFor2, event.costFor3, event.costFor4]
    .map((cost = 0, idx) =>
      cost > 0 ? `${cost} USD/${idx + 1} ${idx === 0 ? 'person' : 'people'}` : '',
    )
    .filter((str) => !!str)
    .join(', ');

  const onEventDelete = async () => {
    setIsLoading(true);
    await deleteEvent(event?._id || '', plan._id || '', day);
    const updatedPlan = { ...plan };
    if (!updatedPlan.days) return;
    const eventDayIndex = plan?.days?.findIndex((d) => d.number === day) ?? -1;
    let updatedEvents = [...(updatedPlan?.days?.[eventDayIndex]?.events || [])];
    updatedEvents = updatedEvents.filter((e) => e._id !== event?._id);
    updatedPlan.days[eventDayIndex].events = updatedEvents;
    setPlan(updatedPlan);
    showSuccess('Event deleted successfully');
    setShowDeleteDialog(false);
    setIsLoading(false);
  };

  return (
    <Card
      sx={{
        borderRadius: '24px',
        boxShadow: '0px 6px 20px 0px rgba(0, 0, 0, 0.12)',
        display: 'flex',
        flexFlow: { xs: 'column-reverse', md: 'row' },
        gap: { xs: 0, md: '24px' },
      }}
    >
      <VerticalBlock
        sx={{
          alignItems: 'flex-start',
          boxSizing: 'border-box',
          flexGrow: '1',
          gap: '13px',
          padding: '16px 24px',
        }}
      >
        <Typography variant="T16M">{event.title}</Typography>

        <VerticalBlock
          sx={{
            alignItems: 'flex-start',
            gap: '8px',
            '& > p': {
              alignItems: 'center',
              display: 'flex',
              '& > svg': { marginRight: '8px', maxWidth: '20px', minWidth: '20px' },
            },
          }}
        >
          <Typography color="textSecondary" variant="T14R">
            <Clock variant="Bold" />
            {getTimeString(event.startTime)} - {getTimeString(event.endTime)}
          </Typography>

          <Typography color="textSecondary" variant="T14R">
            <DollarCircle variant="Bold" />
            {costStr}
          </Typography>

          {event.eventType !== ItineraryEventType.Commute && (
            <Typography color="textSecondary" variant="T14R">
              <Location variant="Bold" />
              {event.location?.structured_formatting.main_text}
            </Typography>
          )}
          {event.eventType === ItineraryEventType.Commute && (
            <Typography color="textSecondary" variant="T14R">
              <Location variant="Bold" />
              {event.fromLocation?.structured_formatting.main_text} to{' '}
              {event.toLocation?.structured_formatting.main_text}
            </Typography>
          )}
        </VerticalBlock>

        <Divider flexItem />

        <HorizontalBlock sx={{ gap: '8px', '& > button': { minWidth: 0 } }}>
          <Button onClick={onEventEdit} size="small" startIcon={<Edit2 />}>
            Edit
          </Button>

          <Button onClick={() => setShowDeleteDialog(true)} size="small" startIcon={<Trash />}>
            Delete
          </Button>
        </HorizontalBlock>
      </VerticalBlock>

      <CardMedia
        component="img"
        image={
          event.photos?.[0] ? getImageUrl(event.photos[0], ImageVariant.MEDIUM) : DEFAULT_IMG_SRC
        }
        sx={{ height: { xs: '150px', md: 'auto' }, width: { xs: 'auto', md: '200px' } }}
      />

      <ConfirmModal
        cancelText="Cancel"
        confirmProps={{
          color: 'error',
          disabled: isLoading,
          startIcon: isLoading && <LoaderCircle />,
        }}
        confirmText="Delete"
        onCancel={() => setShowDeleteDialog(false)}
        onConfirm={onEventDelete}
        open={showDeleteDialog}
        title="Are you sure delete this event?"
      >
        Information about this event will be deleted and cannot be recovered.
        <br />
        Do you still want to delete this event?
      </ConfirmModal>
    </Card>
  );
};

export default EventCard;
