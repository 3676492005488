import { IPlan } from '@zipptrip/zipptrip-commons';
import axios from 'axios';

import { getRequestHeader } from './commons';
import { getEnv } from './env';

export enum AutoGenType {
  PLAN = 'plan',
  EVENT = 'event',
}

export type AutoGenerateOptions = {
  type?: AutoGenType;
};

export const autoGenerateDescription = async (
  values: Partial<IPlan>,
  options?: AutoGenerateOptions,
) => {
  const { type = AutoGenType.PLAN } = options || {};

  const url = getEnv('BaseUrl') + `/openai/auto-complete/${type}`;

  const header = await getRequestHeader();
  const { data } = await axios.post(url, { ...values, type }, header);
  return data?.data;
};
