import { Box, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { HorizontalBlock } from '@zipptrip/zipptrip-canvas';
import { IPlanner } from '@zipptrip/zipptrip-commons';

import AchievementList from '../components/creatorLevel/AchievementList';
import LevelIndicator from '../components/creatorLevel/LevelIndicator';
import NavBarToolbar from '../components/navbar/NavBarToolbar';
import { auth } from '../config/firebase';
import { getPlannerById } from '../utils/planner';

const CreatorLevelPage = () => {
  const {
    data: planner,
    isLoading,
    refetch,
  } = useQuery([auth.currentUser?.uid], async (): Promise<IPlanner | undefined> => {
    if (!auth.currentUser?.uid) return undefined;
    return getPlannerById(auth.currentUser?.uid);
  });

  return (
    <>
      <NavBarToolbar>
        <HorizontalBlock height="100%">
          <Typography variant="T20M">Creator level</Typography>
        </HorizontalBlock>
      </NavBarToolbar>

      <Box sx={{ display: 'flex', flexFlow: 'column', gap: '32px', padding: '32px' }}>
        <LevelIndicator isLoading={isLoading} points={planner?.points || 0} />

        <AchievementList refetchPlanner={refetch} />
      </Box>
    </>
  );
};

export default CreatorLevelPage;
